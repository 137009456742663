<template>
    <div style="font-family: 'Rubik', sans-serif;">
        <div v-if="loading" class="freeze-ui" data-text=" " style="position: absolute;z-index:999999 !important"></div>
        <div class="kt-grid__item kt-grid__item--fluid kt-grid w-100"
            style="margin: 0px; padding: 0px; flex: 0 1 0%;">
            <div _ngcontent-lul-c1="" class="w-100 pl-0">
                <div _ngcontent-lul-c1="" class="ng-tns-c1-0"></div>
                <div class="ng-tns-c30-18 ng-star-inserted" style="">
                    <div class="kt-login__form ng-trigger ng-trigger-routerTransition"
                        style="padding-top: 0px; opacity: 1;">
                        <div class="kt-subheader kt-grid__item">
                            <div class="kt-subheader__main">
                                <h3 class="kt-subheader__title"><span class="ng-tns-c30-18" style="color:white">Agentes
                                    </span></h3><span
                                    class="kt-subheader__separator kt-subheader__separator--v"></span><span
                                    class="kt-subheader__desc"> Reporte General </span>
                            </div>
                        </div>
                    </div>
                </div><!---->
            </div>
            <!-- Reporte ---------------------------------------->
            <div class="row w-100 m-0 p-0">
                <div class="kt-content col-lg-9 col-sm-12 pr-0"
                    style="padding-right: unset !important;overflow:hidden;padding:0">
                    <div class="row resize" style="flex-direction: row;">
                        <div class="col-lg-12">
                            <div class="kt-portlet" style="opacity: 0.9;">
                                <div class="kt-portlet__head row">
                                    <div class="row align-itms-center pl-0 col-sm-12 mb-4 mt-4">
                                        <div class="col-12 mb-4"><span @click="setDateRanges('Hoy')"
                                                :class="selectedDate === 'Hoy' ? 'group-active' : ''"
                                                class="col-sm-3 d-inline btn btn-bold btn-font-sm btn-label-disabled"
                                                id="tgToday"> Hoy </span><span @click="setDateRanges('Ayer')"
                                                :class="selectedDate === 'Ayer' ? 'group-active' : ''"
                                                class="col-sm-3 d-inline  btn btn-bold btn-font-sm btn-label-disabled"
                                                id="tgYesterday"> Ayer </span><span @click="setDateRanges('Semana previa')"
                                                :class="selectedDate === 'Semana previa' ? 'group-active' : ''"
                                                class="col-sm-3 d-inline  btn btn-bold btn-font-sm btn-label-disabled"
                                                id="tgLastWeek"> Última Semana </span><span
                                                @click="setDateRanges('Mes previo')"
                                                :class="selectedDate === 'Mes previo' ? 'group-active' : ''"
                                                class="col-sm-3 d-inline  btn btn-bold btn-font-sm btn-label-disabled"
                                                id="tgLastMonth"> Mes Anterior </span><span
                                                @click="setDateRanges('Mes actual')"
                                                :class="selectedDate === 'Mes actual' ? 'group-active' : ''"
                                                class="col-sm-3 d-inline  btn btn-bold btn-font-sm btn-label-disabled"
                                                id="tgCurrMonth"> Mes Actual </span>
                                        </div>
                                        <div class="pl-3 col-md-4 col-sm-12">
                                            <div class="form-group"><label class="text-white" for="DateFrom">Fecha
                                                    Desde</label>
                                                <div class="row" style="margin-left:0">
                                                    <div class="col-4 px-1">
                                                        <DatePicker v-model="startDate" :language="es"
                                                            :format="'dd/MM/yyyy'"></DatePicker>
                                                    </div>
                                                    <input class="form-control col-4 ml-1 " v-model="startHour" required
                                                        step="1" type="time">
                                                    <div class="btn col-3 mt-n4 pt-3 " btnradiogroup=""
                                                        style="padding-left: unset !important;top:5px"><span
                                                            :class="dateType == 'local' ? 'active' : ''"
                                                            @click="dateType = 'local'"
                                                            class="btn btn-bold  btn-font-sm btn-label-disabled"
                                                            btnradio="local" id="local" aria-pressed="true"> Local
                                                        </span><span class="btn btn-bold  btn-font-sm btn-label-disabled"
                                                            :class="dateType == 'utc' ? 'active' : ''"
                                                            @click="dateType = 'utc'" btnradio="utc" id="utc"
                                                            aria-pressed="false"> UTC
                                                        </span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="pl-3 col-md-4 col-sm-12">
                                            <div class="form-group"><label class="text-white" for="DateTo">Fecha
                                                    Hasta</label>
                                                <div class="row" style="margin-left:0">
                                                    <div class="col-4 px-1">
                                                        <DatePicker v-model="endDate" :language="es" :format="'dd/MM/yyyy'">
                                                        </DatePicker>
                                                    </div>
                                                    <input class="form-control col-4 ml-1 " v-model="endHour" required
                                                        step="1" type="time">
                                                    <div class="btn col-3 mt-n4 pt-3 " btnradiogroup=""
                                                        style="padding-left: unset !important;top:5px"><span
                                                            :class="dateType == 'local' ? 'active' : ''"
                                                            @click="dateType = 'local'"
                                                            class="btn btn-bold  btn-font-sm btn-label-disabled"
                                                            btnradio="local" id="local" aria-pressed="true"> Local
                                                        </span><span class="btn btn-bold  btn-font-sm btn-label-disabled"
                                                            :class="dateType == 'utc' ? 'active' : ''"
                                                            @click="dateType = 'utc'" btnradio="utc" id="utc"
                                                            aria-pressed="false"> UTC
                                                        </span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-12">
                                            <div class="form-group">
                                                <label class="text-white ml-2" for="ngSelectDirectOptions">Mostrar</label>
                                                <div class="custom-w3-select">
                                                    <select id="ngSelectDirectOptions" class="" style="border-radius: 5px;">
                                                        <option value="0" selected="selected">Todos</option>
                                                        <option value="1">Solo directos</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div><!---->
                                        <div class="col-md-2 col-6 d-inline">
                                            <div class="form-group">
                                                <div class="btn pt-1" data-toggle="buttons"
                                                    style="padding-left: unset !important;top:10px;position:relative">
                                                    <span class="btn btn-bold  btn-font-sm btn-label-disabled"
                                                        :class="!detalles ? 'group-active' : ''" @click="detalles = false"
                                                        id="GR"> Simple </span><span :class="detalles ? 'group-active' : ''"
                                                        @click="detalles = true"
                                                        class="btn btn-bold  btn-font-sm btn-label-disabled" id="DT">
                                                        Detallado </span><span
                                                        class="btn btn-bold  btn-font-sm btn-label-disabled" id="btn_help" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                        Ayuda<i class="la la-info"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-n3 pt-1 col-12" style="padding:0 0.5rem;"><span class="ng-tns-c30-18"
                                            :class="showLess ? 'show-less' : ''"
                                            style="font-size: 11.7px !important;color: #FFC529;width:80% !important">
                                            La zona horaria local toma en consideración la fecha y hora que tiene
                                            configurada en su dispositivo. La zona horaria UTC 00:00 es un estandar
                                            de hora universal.
                                            Si selecciona la opción "Local" será tomado en cuenta el horario de su
                                            pais, y si selecciona la opción "UTC" será tomado en cuenta el horario
                                            estandar universal. </span><a class="ng-tns-c30-18" href="javascript:;"
                                            @click="showLess = !showLess"
                                            style="font-size: 11.7px !important;color: #FFC529 !important;text-decoration: underline; display:inline;float:right">
                                            {{ showLess ? 'Mostrar más' : 'Mostrar menos' }} </a><br class="ng-tns-c30-18">
                                    </div>
                                    <div class="col-md-4 col-sm-6 pl-0 mb-3"><button class="col-sm-12 btn btn-primary"
                                            @click="getReport" id="btn_search" type="button"><i class="la la-search"
                                                style="width: 20px;"></i> Buscar</button></div>
                                </div>
                                <div class="kt-portlet__body"><span class="ng-tns-c30-18" style="font-size:9px;color:black">
                                    </span><br class="ng-tns-c30-18"><!----><!----><!---->
                                    <div class="row align-items-center ng-tns-c19-4 ng-star-inserted ng-tns-c30-18"
                                        style="">
                                        <table class="ng-tns-c19-4 general-report">
                                            <tr class="ng-tns-c19-4">
                                                <th class="ng-tns-c19-4" style="text-align: center;">NetWin Total </th><!---->
                                                <th class="ng-tns-c19-4" style="text-align: center;">Comisión </th>
                                                <th class="ng-tns-c19-4" style="text-align: center;">Bonificación
                                                </th>
                                                <th class="ng-tns-c19-4" style="text-align: center;">A Pagar </th>
                                            </tr>
                                            <tr class="ng-tns-c19-4">
                                                <td class="ng-tns-c19-4" style="text-align: right;">
                                                    {{ parseNumbers(totalizar([
                                                        Number.isNaN(reportData.casino.netwin) ? 0 : reportData.casino.netwin,
                                                        Number.isNaN(reportData.deportes.netwin) ? 0 :
                                                            reportData.deportes.netwin,
                                                        Number.isNaN(reportData.poker.netwin) ? 0 : reportData.poker.netwin,
                                                        Number.isNaN(reportData.bingo.netwin) ? 0 : reportData.bingo.netwin
                                                    ])) }}</td><!---->
                                                <td class="ng-tns-c19-4" style="text-align: right;">
                                                    {{ parseNumbers(totalizar([
                                                        Number.isNaN(reportData.casino.comision) ? 0 :
                                                            reportData.casino.comision,
                                                        Number.isNaN(reportData.deportes.comision) ? 0 :
                                                            reportData.deportes.comision,
                                                        Number.isNaN(reportData.poker.comision) ? 0 : reportData.poker.comision,
                                                        Number.isNaN(reportData.bingo.comision) ? 0 : reportData.bingo.comision
                                                    ])) }}</td>
                                                <td class="ng-tns-c19-4" style="text-align: right;"><!----> 0 </td>
                                                <td class="ng-tns-c19-4" style="text-align: right;">{{
                                                    parseNumbers(totalizar([
                                                        Number.isNaN(reportData.casino.netwin - reportData.casino.comision) ? 0
                                                            : reportData.casino.netwin - reportData.casino.comision,
                                                        Number.isNaN(reportData.deportes.netwin - reportData.deportes.comision)
                                                            ? 0 : reportData.deportes.netwin - reportData.deportes.comision,
                                                        Number.isNaN(reportData.poker.netwin - reportData.poker.comision) ? 0 :
                                                            reportData.poker.netwin - reportData.poker.comision,
                                                        Number.isNaN(reportData.bingo.netwin - reportData.bingo.comision) ? 0 :
                                                            reportData.bingo.netwin - reportData.bingo.comision,
                                                    ])) }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="row align-items-center ng-tns-c30-18 ng-star-inserted"
                                        style="margin-bottom: 30px;">
                                        <div class="primeng-datatable-container">
                                            <div class="ng-tns-c19-4 general-report-table" scrollwidth="100%">
                                                <div class="ui-table ui-widget ui-table-responsive">
                                                    <!----><!----><!----><!----><!----><!---->
                                                    <div class="ui-table-scrollable-wrapper ng-star-inserted">
                                                        <!---->
                                                        <div class="ui-table-scrollable-view">
                                                            <div class="ui-table-scrollable-header ui-widget-header">
                                                                <div class="ui-table-scrollable-header-box"
                                                                    style="margin-right: 0px;">
                                                                    <table class="ui-table-scrollable-header-table">
                                                                        <!---->
                                                                        <thead class="ui-table-thead"><!---->
                                                                            <tr
                                                                                class="ng-tns-c19-4 ng-star-inserted ng-tns-c30-18">
                                                                                <!----><!----><!----><!---->
                                                                                <th class="ui-sortable md-1 ng-tns-c19-4 ng-star-inserted ng-tns-c30-18"
                                                                                    colspan="5" style="text-align: center;">
                                                                                    Casino </th>
                                                                                <!----><!----><!---->
                                                                            </tr>
                                                                            <tr
                                                                                class="ng-tns-c19-4 ng-star-inserted ng-tns-c30-18">
                                                                                <!----><!----><!---->
                                                                                <th
                                                                                    class="ui-sortable md-1 ng-tns-c19-4 ng-star-inserted ng-tns-c30-18">
                                                                                    Proveedor
                                                                                </th>
                                                                                <th
                                                                                    class="ui-sortable md-1 ng-tns-c19-4 ng-star-inserted ng-tns-c30-18">
                                                                                    Marca
                                                                                </th>
                                                                                <th class="ui-sortable md-1"
                                                                                    style="text-align: center;">
                                                                                    Apostado </th>
                                                                                <th class="ui-sortable md-1"
                                                                                    style="text-align: center;">
                                                                                    Ganado </th>
                                                                                <th class="ui-sortable"
                                                                                    style="text-align: center;">
                                                                                    Saldo </th><!----><!----><!---->
                                                                                <!-- <th class="ui-sortable ng-tns-c30-18 ng-star-inserted"
                                                                                    style="text-align: center;"> %
                                                                                    Comisión </th>
                                                                                <th class="ui-sortable ng-tns-c30-18 ng-star-inserted"
                                                                                    style="text-align: center;">
                                                                                    Comisión </th>
                                                                                <th class="ui-sortable ng-tns-c30-18 ng-star-inserted"
                                                                                    style="text-align: center;"> A
                                                                                    Pagar </th> -->
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody class="ui-table-tbody"
                                                                            style="font-size: 14px !important;"><!---->
                                                                            <template
                                                                                v-for="(provider, index) in reportData.casino.providers">
                                                                                <tr :key="index" style="display: none;">

                                                                                </tr>
                                                                                <tr v-for="(brand, bindex) in provider.brands"
                                                                                    :class="detalles ? '' : 'd-none'"
                                                                                    :key="bindex + '-' + brand.provider">
                                                                                    <td>{{ index }}</td>
                                                                                    <td>{{ bindex }}</td>
                                                                                    <td style="text-align: right;">{{
                                                                                        parseNumbers(brand.bet_amount) }}
                                                                                    </td>
                                                                                    <td style="text-align: right;">{{
                                                                                        parseNumbers(brand.win_amount) }}
                                                                                    </td>
                                                                                    <td style="text-align: right;">{{
                                                                                        parseNumbers(brand.netwin) }}
                                                                                    </td>
                                                                                    <!-- <td>{{ parseNumbers(brand.rake) }}</td>
                                                                                    <td>{{ parseNumbers(brand.comision) }}</td>
                                                                                    <td>{{ totalizar([Number.isNaN(brand.netwin-brand.comision)?0:brand.netwin-brand.comision]) }}</td> -->
                                                                                </tr>
                                                                            </template>
                                                                            <tr
                                                                                v-if="Object.keys(reportData?.casino?.providers ?? {}).length > 0">
                                                                                <td colspan="2"
                                                                                    style="font-weight: bold;text-align: center;">
                                                                                    TOTAL CASINO
                                                                                </td>
                                                                                <td
                                                                                    style="text-align: right;font-weight: bold;"><span class="ui-column-title">Apostado</span>
                                                                                    {{
                                                                                        parseNumbers(reportData?.casino?.bet_amount)
                                                                                    }}</td>
                                                                                <td
                                                                                    style="text-align: right;font-weight: bold;"><span class="ui-column-title">Ganado</span>
                                                                                    {{
                                                                                        parseNumbers(reportData?.casino?.win_amount)
                                                                                    }}</td>
                                                                                <td
                                                                                    style="text-align: right;font-weight: bold;"><span class="ui-column-title">Saldo</span>
                                                                                    {{
                                                                                        parseNumbers(reportData?.casino?.netwin)
                                                                                    }}</td>
                                                                                <!-- <td>{{ parseNumbers(reportData?.casino?.rake) }}</td>
                                                                                <td>{{ parseNumbers(0) }}</td>
                                                                                <td>{{ totalizar([Number.isNaN(reportData?.casino?.netwin-reportData?.casino?.comision)?0:reportData?.casino?.netwin-reportData?.casino?.comision]) }}</td> -->
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div class="ui-table-scrollable-body">
                                                                <table class="ui-table-scrollable-body-table">
                                                                    <!---->
                                                                    <tbody class="ui-table-tbody">
                                                                        <!----><!----><!----><!----><!----><!----><!----><!----><!---->
                                                                    </tbody>
                                                                </table><!----><!---->
                                                            </div><!---->
                                                        </div>
                                                    </div><!----><!----><!----><!----><!---->
                                                </div>
                                            </div><!---->
                                            <div v-if="Object.keys(reportData?.casino?.providers ?? {}).length < 1"
                                                class="primeng-no-data ng-tns-c30-18 ng-star-inserted"> No hay data
                                            </div>
                                        </div>
                                    </div><!----><!----><!----><!----><!---->
                                    <div class="row align-items-center ng-tns-c19-4 ng-star-inserted ng-tns-c24-5" style="">
                                        <table class="ng-tns-c19-4 general-report deportes">
                                            <th class="ui-sortable md-1" colspan="8"
                                                style="text-align: center;background: #8386c3;"> DEPORTES
                                            </th>
                                            <tr class="ng-tns-c19-4">
                                                <th class="ui-sortable md-1"
                                                    style="text-align: center;background: #8386c3;"> Proveedor </th>
                                                <th class="ui-sortable md-1"
                                                    style="text-align: center;background: #8386c3;"> Apostado </th>
                                                <th class="ui-sortable md-1"
                                                    style="text-align: center;background: #8386c3;"> Ganado </th>
                                                <th class="ui-sortable" style="text-align: center;background: #8386c3;">
                                                    Saldo </th>
                                                <!----><!---->
                                            </tr><!----><!----><!----><!---->
                                            <tr class="ng-tns-c19-4 ng-tns-c24-5 ng-star-inserted"
                                                style="font-weight: bold;">
                                                <td class="ng-tns-c19-4" style="text-align: center;"> TOTAL
                                                    DEPORTES </td>
                                                <td style="text-align: right;">{{
                                                    parseNumbers(reportData?.deportes?.bet_amount)
                                                }}</td>
                                                <td style="text-align: right;">{{
                                                    parseNumbers(reportData?.deportes?.win_amount)
                                                }}</td>
                                                <td style="text-align: right;">{{
                                                    parseNumbers(reportData?.deportes?.netwin)
                                                }}</td>
                                                <!----><!---->
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="row align-items-center ng-tns-c19-4 ng-star-inserted ng-tns-c24-5" style="">
                                        <table class="ng-tns-c19-4 general-report deportes">
                                            <th class="ui-sortable md-1" colspan="8"
                                                style="text-align: center;background: #8386c3;"> POKER
                                            </th>
                                            <tr class="ng-tns-c19-4">
                                                <th class="ui-sortable md-1"
                                                    style="text-align: center;background: #8386c3;"> Proveedor </th>
                                                <th class="ui-sortable md-1"
                                                    style="text-align: center;background: #8386c3;"> Apostado </th>
                                                <th class="ui-sortable md-1"
                                                    style="text-align: center;background: #8386c3;"> Ganado </th>
                                                <th class="ui-sortable" style="text-align: center;background: #8386c3;">
                                                    Saldo </th>
                                                <!----><!---->
                                            </tr><!----><!----><!----><!---->
                                            <tr class="ng-tns-c19-4 ng-tns-c24-5 ng-star-inserted"
                                                style="font-weight: bold;">
                                                <td class="ng-tns-c19-4" style="text-align: center;"> TOTAL
                                                    POKER </td>
                                                <td style="text-align: right;">{{
                                                    parseNumbers(Number.isNaN(reportData?.poker?.bet_amount) || 0)
                                                }}</td>
                                                <td style="text-align: right;">{{
                                                    parseNumbers(Number.isNaN(reportData?.poker?.win_amount) || 0)
                                                }}</td>
                                                <td style="text-align: right;">{{
                                                    parseNumbers(Number.isNaN(reportData?.poker?.netwin) || 0)
                                                }}</td>
                                                <!----><!---->
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="row align-items-center ng-tns-c19-4 ng-star-inserted ng-tns-c24-5" style="">
                                        <table class="ng-tns-c19-4 general-report deportes">
                                            <th class="ui-sortable md-1" colspan="8"
                                                style="text-align: center;background: #8386c3;"> BINGO
                                            </th>
                                            <tr class="ng-tns-c19-4">
                                                <th class="ui-sortable md-1"
                                                    style="text-align: center;background: #8386c3;"> Proveedor </th>
                                                <th class="ui-sortable md-1"
                                                    style="text-align: center;background: #8386c3;"> Apostado </th>
                                                <th class="ui-sortable md-1"
                                                    style="text-align: center;background: #8386c3;"> Ganado </th>
                                                <th class="ui-sortable" style="text-align: center;background: #8386c3;">
                                                    Saldo </th>
                                                <!----><!---->
                                            </tr><!----><!----><!----><!---->
                                            <tr class="ng-tns-c19-4 ng-tns-c24-5 ng-star-inserted"
                                                style="font-weight: bold;">
                                                <td class="ng-tns-c19-4" style="text-align: center;"> TOTAL
                                                    BINGO </td>
                                                <td style="text-align: right;">{{
                                                    parseNumbers(Number.isNaN(reportData?.bingo?.bet_amount) || 0)
                                                }}</td>
                                                <td style="text-align: right;">{{
                                                    parseNumbers(Number.isNaN(reportData?.bingo?.win_amount) || 0)
                                                }}</td>
                                                <td style="text-align: right;">{{
                                                    parseNumbers(Number.isNaN(reportData?.bingo?.netwin) || 0)
                                                }}</td>
                                                <!----><!---->
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="row align-items-center ng-tns-c17-1 ng-star-inserted ng-tns-c20-1"
                                        v-if="showAddData" id="otherInfoTable">
                                        <table class="ng-tns-c17-1  general-report otro">
                                            <th class="ui-sortable md-1" colspan="7"
                                                style="text-align: center;background: #9899ac;"> OTRA
                                                INFORMACIÓN<br class="ng-tns-c20-1">(Esta información no se usa para el
                                                cálculo de comisiones) </th>
                                            <tr class="ng-tns-c17-1">
                                                <th class="ui-sortable md-1"
                                                    style="text-align: center;background: #9899ac;"> Depósitos </th>
                                                <th class="ui-sortable md-1"
                                                    style="text-align: center;background: #9899ac;"> Retiros </th>
                                                <th class="ui-sortable" style="text-align: center;background: #9899ac;">
                                                    Saldo </th>
                                                <th class="ui-sortable md-1"
                                                    style="text-align: center;background: #9899ac;"> Depósitos
                                                    Bonificados </th>
                                                <th class="ui-sortable" style="text-align: center;background: #9899ac;">
                                                    Ficha en Agentes </th>
                                                <th class="ui-sortable" style="text-align: center;background: #9899ac;">
                                                    Ficha en Jugadores
                                                </th>
                                                <th class="ui-sortable" style="text-align: center;background: #9899ac;">
                                                    Ajustes de Billetera
                                                </th>
                                            </tr>
                                            <tr class="ng-tns-c17-1">
                                                <td class="ng-tns-c17-1" style="text-align: right;"> {{
                                                    parseNumbers(reportData.fichas.ingreso) }} </td>
                                                <td class="ng-tns-c17-1" style="text-align: right;"> {{
                                                    parseNumbers(reportData.fichas.salida) }} </td>
                                                <td class="ng-tns-c17-1" style="text-align: right;"> {{
                                                    parseNumbers(reportData.fichas.total) }} </td>
                                                <td class="ng-tns-c17-1" style="text-align: right;"> 0 </td>
                                                <td class="ng-tns-c17-1" style="text-align: right;"> {{
                                                    parseNumbers(reportData.paneles.ROLE_PROMOTOR) }} </td>
                                                <td class="ng-tns-c17-1" style="text-align: right;"> {{
                                                    parseNumbers(reportData.paneles.ROLE_APOSTADOR) }} </td>
                                                <td class="ng-tns-c17-1" style="text-align: right;"> 0 </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="row align-items-center ng-tns-c17-1 ng-star-inserted ng-tns-c20-1">
                                        <div class="form-group">
                                            <div class="btn pt-1" data-toggle="buttons"
                                                style="padding-left: unset !important;"><span v-if="!showAddData"
                                                    @click="showAddData = true"
                                                    class="btn btn-bold btn-sm btn-font-sm btn-label-disabled"
                                                    id="showOtherInfoButton"> Mostrar otra
                                                    información </span><span v-else
                                                    @click="showAddData = false"
                                                    class="btn btn-bold btn-sm btn-font-sm btn-label-disabled"
                                                    id="hideOtherInfoButton"> Ocultar otra
                                                    información </span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ARBOL ---------------------------------------->
                <div class="col-lg-3 col-sm-12 pr-0 pl-0" v-if="screenWidth > 1000">
                    <div class="w-100">
                        <div id="treeNode" class="px-2">
                            <div class="kt-portlet"
                                style="opacity: 0.9;border: 1px solid #494141;margin-bottom: unset !important;">
                                <div class="kt-portlet__head" style="background-color: #15172B !important;">
                                    <div class="kt-portlet__head-label">
                                        <h3 class="kt-font-light kt-font-md kt-portlet__head-title text-uppercase">
                                            <span>Agentes</span>
                                        </h3>
                                    </div>
                                </div>
                                <div class="tree kt-content px-1">
                                    <treeNode :node="main_tree" :startDate="getFechaFormated(startDate)" :endDate="getFechaFormated(endDate)" :startHour="startHour" :endHour="endHour"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="offcanvas offcanvas-start p-0" tabindex="-1" id="offcanvasTree" aria-labelledby="offcanvasTreeLabel" style="max-width: 70%;background-color: #15172B !important;">
                    <div class="offcanvas-header p-2">
                        <h5 class="offcanvas-title" id="offcanvasTreeLabel" style="color: white;">Agentes</h5>
                        <button style="background-color: rgba(255, 255, 255, 0.2);color:white" class="close btn btn-sm px-2" type="button" data-bs-dismiss="offcanvas" aria-label="Close"><span aria-hidden="true">×</span></button>
                    </div>
                    <div class="offcanvas-body p-0">
                        <treeNode :node="main_tree" :startDate="getFechaFormated(startDate)" :endDate="getFechaFormated(endDate)" :startHour="startHour" :endHour="endHour"/>
                    </div>
                </div>
            </div>
            <!-- Modal -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-md">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title"><span
                                    style="padding-bottom: 4px;border-bottom: 1px solid #848484;">AYUDA
                                    REPORTE GENERAL</span></h4><button data-bs-dismiss="modal" aria-label="Close" class="close"
                                type="button"><span aria-hidden="true">×</span></button>
                        </div>
                        <div class="modal-body pb-1">
                            <div class="row">
                                <p><b style="font-weight: bold!important;">REPORTE GENERAL:</b> brinda
                                    información de NETWIN, COMISIONES y A PAGAR de cada producto y
                                    proveedor. Los valores COMISIONES y A PAGAR se calculan sobre la NETWIN.
                                    Tenga en cuenta que este reporte no refleja movimientos de caja
                                    (depósitos y retiros), para ver esta información use el "REPORTE DE
                                    CAJA", o presione el botón "OTRA INFORMACIÓN". </p>
                                <p><b style="font-weight: bold!important;">NETWIN CASINO + SPORTS:</b> El
                                    NETWIN es el beneficio generado por los jugadores. Se calcula en base a
                                    la suma de las apuestas realizadas menos el premio total. Este valor se
                                    genera cuando los clientes juegan, y no cuando depositan o retiran. </p>
                                <p><b style="font-weight: bold!important;">COMISIÓN:</b> El total de
                                    comisión que obtiene el agente sobre el NETWIN, se calcula en base al
                                    porcentaje configurado en PERMISOS/COMISIONES. La comisión se puede
                                    configurar por producto o proveedor. </p>
                                <p><b style="font-weight: bold!important;">BONIFICACIÓN:</b> Estos son
                                    pagos/premios a jugadores que están cubiertos por el sitio y/o un
                                    proveedor. Se descuenta del saldo A PAGAR a cubrir en la próxima
                                    liquidación. Generalmente se usa para torneos patrocinados. </p>
                                <p><b style="font-weight: bold!important;">A PAGAR:</b> Es lo que debe pagar
                                    el agente. Resulta del siguiente cálculo: (NETWIN - COMISIÓN -
                                    BONIFICACIÓN). </p>
                                <p><b style="font-weight: bold!important;">SIMPLE / DETALLADO:</b> al hacer
                                    clic en la opción "DETALLADO" se puede observar el detalle de NETWIN y
                                    COMISIÓN por proveedor. (Se puede configurar un porcentaje de comisión
                                    diferente por producto o proveedor). </p>
                                <p><b style="font-weight: bold!important;">SUBAGENTES:</b> Al seleccionar un
                                    SUBAGENTE y luego presionar el botón BUSCAR, el reporte mostrará los
                                    datos de ese subagente y todos sus usuarios. </p>
                                <p><b style="font-weight: bold!important;">OTRA INFORMACIÓN:</b> Muestra el
                                    saldo de caja, y detalla la cantidad de fichas en las cuentas de Agentes
                                    y Jugadores. </p>
                                <p><b style="font-weight: bold!important;">FICHAS DE PÓKER:</b> Son las
                                    fichas de póker que debe pagar el agente. Se calcula el número total de
                                    fichas que los jugadores ganaron o perdieron en las mesas. Las fichas se
                                    SUMAN al saldo A PAGAR. La comisión de PÓKER se calcula sobre el RAKE
                                    total de sus sesiones de juego. Si los jugadores ganaron, el agente debe
                                    recibir fichas, si los jugadores perdieron, el agente debe pagar. </p>
                                <p><b style="font-weight: bold!important;">JUGADORES:</b> si quieres ver la
                                    información de un jugador específico, puedes ir a REPORTES JUGADORES
                                    &gt; GENERAL. Allí puedes buscar un jugador específico. También se puede
                                    ver quiénes fueron los jugadores que más ganaron o perdieron en (MÁS
                                    GANADORES / MÁS PERDEDORES), y así saber cómo se distribuye el NETWIN
                                    entre los jugadores. </p>
                            </div>
                        </div>
                        <div class="modal-footer"><button class="btn btn-default" type="button" data-bs-dismiss="modal" aria-label="Close">Cerrar</button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import treeNode from './treeNode.vue';
import DatePicker from '../datePicker/components/Datepicker'
import { es } from '@sum.cumo/vue-datepicker/dist/locale/index.esm'
export default {
    name: 'reporteGeneral',
    components: { treeNode, DatePicker },
    data() {
        return {
            selectedDate: "Hoy",
            startDate: new Date(),
            startHour: "00:00:00",
            endDate: new Date(),
            endHour: "00:00:00",
            dateType: 'local',
            es: es,
            showLess: true,
            showAddData: false,
            firstLoadReport: false,
            detalles: false,
            showPopover2: false,
            loading: false
        }
    },
    props: ["view_type","screenWidth"],
    computed: {
        main_tree() {
            return this.$store.getters["getMainTree"];
        },
        reportData() {
            return this.$store.getters["getReportData"];
        },
        loadingReport() {
            return this.$store.getters["getLoadingReport"];
        },
        targetUser() {
            return this.$store.getters["getTargetUser"];
        },
    },
    methods: {
        openModal(target) {
            try {
                this.backdrop(true);
                let modal = document.getElementById(target);
                modal.classList.add("show");
                modal.style.display = "block";
            } catch (error) {
                console.log(error);
            }
        },
        backdrop(action) {
            if (action) {
                document.getElementById("modalBackdrop").style.display = "block";
                document.getElementById("modalBackdrop").classList.add("show");
            }
            else {
                document.getElementById("modalBackdrop").style.display = "none";
                document.getElementById("modalBackdrop").classList.remove("show");
            }
        },
        closeModal() {
            let modals = document.getElementsByClassName("modal");
            [...modals].forEach(modal => {
                modal.classList.remove("show");
                modal.style.display = "none";
            })
            document.getElementById("modalBackdrop").style.display = "none";
            document.getElementById("modalBackdrop").classList.remove("show");
        },
        totalizar(numbers) {
            return numbers.reduce((a, b) => (a ?? 0) + (b ?? 0), 0);
        },
        parseNumbers(number) {
            return new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number)
        },
        showHiddenTree() {
            let checked = document.getElementById("TreeShowHidden").checked;
            this.$store.dispatch("showHiddenTree", checked);
        },
        getFechaFormated(fecha) {
            var año = fecha.getFullYear();
            var mes = ('0' + (fecha.getMonth() + 1)).slice(-2);
            var dia = ('0' + fecha.getDate()).slice(-2);
            var cadenaFecha = año + '-' + mes + '-' + dia;
            return cadenaFecha
        },
        getFecha(date) {
            return date
        },
        setDateRanges(range) {
            let hoy = new Date()
            this.selectedDate = range;
            if (range == "Hoy") {
                const tomorrow = new Date(hoy);
                tomorrow.setDate(tomorrow.getDate() + 1);
                this.startDate = this.getFecha(hoy);
                this.startHour = "00:00:00"
                this.endDate = this.getFecha(tomorrow);
                this.endHour = "00:00:00"
            }
            else if (range == "Ayer") {
                let ayer = new Date(hoy);
                ayer.setDate(ayer.getDate() - 1);
                this.startDate = this.getFecha(ayer);
                this.endDate = this.getFecha(hoy);
                this.startHour = "00:00:00"
                this.endHour = "00:00:00"
            }
            else if (range == "Semana previa") {
                const diaDeLaSemana = hoy.getDay();
                const diasParaRetroceder = diaDeLaSemana === 0 ? 6 : diaDeLaSemana - 1;
                const lunesSemanaAnterior = new Date(hoy);
                lunesSemanaAnterior.setDate(hoy.getDate() - (diasParaRetroceder + 7));
                const fechasSemanaPrevia = [];
                for (let i = 0; i < 8; i++) {
                    const fecha = new Date(lunesSemanaAnterior);
                    fecha.setDate(lunesSemanaAnterior.getDate() + i);
                    fechasSemanaPrevia.push(fecha);
                }
                this.startDate = this.getFecha(fechasSemanaPrevia[0]);
                this.endDate = this.getFecha(fechasSemanaPrevia[7]);
                this.startHour = "00:00:00"
                this.endHour = "00:00:00"
            }
            else if (range == "Semana actual") {
                const primerDiaSemana = new Date(hoy);
                primerDiaSemana.setDate(hoy.getDate() - (hoy.getDay() === 0 ? 7 : hoy.getDay()));
                // Paso 3: Obtener el rango de fechas de la semana
                const rangoSemana = [];
                for (let i = 0; i < 9; i++) {
                    const fecha = new Date(primerDiaSemana);
                    fecha.setDate(primerDiaSemana.getDate() + i);
                    rangoSemana.push(fecha);
                }
                this.startDate = this.getFecha(rangoSemana[1]);
                this.endDate = this.getFecha(rangoSemana[8]);
                this.startHour = "00:00:00"
                this.endHour = "00:00:00"
            }
            else if (range == "Mes actual") {
                const primerDiaMes = new Date(hoy.getFullYear(), hoy.getMonth(), 1);
                const tomorrow = new Date(hoy);
                tomorrow.setDate(tomorrow.getDate() + 1);
                this.startDate = this.getFecha(primerDiaMes);
                this.endDate = this.getFecha(tomorrow);
                this.startHour = "00:00:00"
                this.endHour = "00:00:00"
            }
            else if (range == "Mes previo") {
                const primerDiaMesAnterior = new Date(hoy);
                const primerdiaMesActual = new Date(hoy);
                primerDiaMesAnterior.setMonth(hoy.getMonth() - 1);
                primerDiaMesAnterior.setDate(1);
                primerdiaMesActual.setDate(1);
                this.startDate = this.getFecha(primerDiaMesAnterior);
                this.endDate = this.getFecha(primerdiaMesActual);
                this.startHour = "00:00:00"
                this.endHour = "00:00:00"
            }
            // this.getReport();
        },
        async getReport() {
            this.detalles = false;
            this.loading = true;
            await this.$store.dispatch("getReport", {
                startDate: this.getFechaFormated(this.startDate),
                endDate: this.getFechaFormated(this.endDate),
                startHour: this.startHour + ":00",
                endHour: this.endHour + ":00",
                dateType: this.dateType,
                id: this.targetUser
            })
            this.loading = false;
        }
    },
    watch: {
        targetUser(newVal) {
            if (newVal.id && !this.firstLoadReport) {
                this.getReport()
                this.firstLoadReport = true
            }
        }
    },
    mounted() {
        if (this.selectedDate == "Hoy") {
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            this.startDate = this.getFecha(new Date());
            this.startHour = "00:00:00"
            this.endDate = this.getFecha(tomorrow);
            this.endHour = "00:00:00"
            if (this.targetUser.id && !this.firstLoadReport) {
                this.getReport()
                this.firstLoadReport = true
            }
        }
    },
}
</script>
<style scoped>
.ui-table .ui-table-thead>tr>th {
    color: white !important;
    font-family: 'Times New Roman', Times, serif;
}</style>