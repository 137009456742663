<template>
    <div>
        <div v-if="loading" class="freeze-ui" data-text=" " style="position: absolute;z-index:999999 !important"></div>
        <div _ngcontent-niy-c1="" class="kt-header-mobile " id="kt_header_mobile">
            <div _ngcontent-niy-c1="" class="kt-header-mobile__toolbar" _ngcontent-ppm-c1="">
                <button class="kt-header-mobile__toggler kt-header-mobile__toggler--right " id="kt_aside_mobile_toggler"
                    style="margin:0" @click="viewType == 'thin' ? closeNav() : openNav()">
                    <span style="margin-top:5px;"></span>
                </button>
            </div><!---->
            <div _ngcontent-niy-c1="" class="kt-header-mobile__toolbar">
                <button v-if="getActualView !== 'cuentas_corrientes'" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasTree" aria-controls="offcanvasTree" _ngcontent-niy-c1=""
                    class="btn btn-bold btn-font-sm btn-planetabeta btn-sm" id="kt_aside_mobile_toggler2"
                    style="margin-left:auto;margin-right:10px;"> Subagentes </button>
                <!---->
                <div _ngcontent-niy-c1="" class="kt-header__topbar-item dropdown ng-tns-c1-0 ng-star-inserted"
                    dropdown="" headernotifications="" id="header_notification_bar_mobile" style="font-size: large;">
                    <div class="kt-header__topbar-wrapper" data-offset="30px,0px" data-toggle="dropdown"
                        dropdowntoggle="" aria-haspopup="true">
                        <span class="kt-header__topbar-icon kt-header__topbar-icon--primary"><!---->
                            <div class="ng-star-inserted" style="">
                                <i class="flaticon-alert-2 unread-notification"></i>
                                <!-- <span class="kt-badge kt-badge--primary unread-notification-count"> 78 </span> -->
                            </div><!---->
                        </span>
                    </div>
                </div><!---->
                <button _ngcontent-niy-c1="" class="kt-header-mobile__topbar-toggler"
                    id="kt_header_mobile_topbar_toggler" kttoggle="" data-bs-toggle="collapse"
                    data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    <i _ngcontent-niy-c1="" class="flaticon-more"></i>
                </button>

            </div>
        </div>
        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                <div class="kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop sidenav" id="mySidenav"
                    ktoffcanvas="" style="opacity: 1;z-index:99;">
                    <div>
                        <div class="kt-aside__brand kt-grid__item ml-4 mt-1" style="height: auto;">
                            <div class="kt-aside__brand-logo">
                                <img :style="hideArrows ? 'visibility:hidden;' : ''" alt="logo"
                                    src="/ganaencasa/img/Platenabet Logo.svg">
                            </div>
                            <div class="kt-aside__brand-tools">
                                <button class="kt-aside-close" id="kt_aside_close_btn"
                                    @click="viewType == 'thin' ? closeNav() : openNav()"><i class="la la-close"
                                        aria-label="Close"></i></button>
                                <button
                                    :class="viewType !== 'thin' ? 'kt-aside__brand-aside-toggler ng-star-inserted' : 'kt-aside__brand-aside-toggler ng-star-inserted kt-aside__brand-aside-toggler--active'"
                                    id="kt_aside_toggler" @click="viewType == 'thin' ? closeNav() : openNav()" style="">
                                    <span v-if="!hideArrows">
                                        <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="#67738e"
                                            class="kt-svg-icon" height="24px" version="1.1" viewBox="0 0 24 24"
                                            width="24px" xmlns="http://www.w3.org/2000/svg">
                                            <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                                                <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                                                <path
                                                    d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z"
                                                    fill="#67738e" fill-rule="nonzero" id="Path-94"
                                                    transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) ">
                                                </path>
                                                <path
                                                    d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z"
                                                    fill="#67738e" fill-rule="nonzero" id="Path-94" opacity="0.3"
                                                    transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) ">
                                                </path>
                                            </g>
                                        </svg>
                                    </span>
                                    <span v-if="hideArrows" style="position:absolute;left:30px;top:20px;">
                                        <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="#67738e"
                                            class="kt-svg-icon" height="24px" version="1.1" viewBox="0 0 24 24"
                                            width="24px" xmlns="http://www.w3.org/2000/svg">
                                            <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                                                <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                                                <path
                                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                    fill="#67738e" fill-rule="nonzero" id="Path-94"></path>
                                                <path
                                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                    fill="#67738e" fill-rule="nonzero" id="Path-94" opacity="0.3"
                                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) ">
                                                </path>
                                            </g>
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="text-end" v-if="screen_width < 1000">
                        <button @click="closeNav()" class="btn btn-sm" id="kt_aside_close_btn"
                            style="background-color: rgba(255, 255, 255, 0.2);color:white"><i class="la la-close"
                                aria-label="Close"></i></button>
                    </div>
                    <!-- MENU ----------------------->
                    <div class="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
                        <nav>
                            <div class="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
                                id="kt_aside_menu_wrapper">
                                <div class="kt-aside-menu ps" id="kt_aside_menu" ktmenu="" data-ktmenu-vertical="1"
                                    data-ktmenu-scroll="1" data-ktmenu-dropdown="0"
                                    style="max-height: 90vh; position: relative;">
                                    <div class="accordion" id="accordionExample" style="font-size:small;">
                                        <!-- USUARIOS---------------------->
                                        <div :class="hideArrows ? 'mb-3' : ''" class="accordion-item sidebar-menu">
                                            <span class="accordion-header" id="headingOne" style="cursor: pointer;">
                                                <span :class="!hideArrows ? 'accordion-button' : ''"
                                                    class="sidebar-item" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne" aria-expanded="true"
                                                    aria-controls="collapseOne">
                                                    <span class="kt-menu__link-icon ng-star-inserted mx-2"
                                                        style="font-size:large;cursor:pointer;"><i
                                                            class="kt-menu__link-icon la la-users"></i></span> <span
                                                        v-if="!hideArrows" class="mx-3"
                                                        style="font-size:small;">Usuarios</span>
                                                </span>
                                            </span>
                                            <div id="collapseOne" class="accordion-collapse collapse show"
                                                aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body text-start kt-menu__item" v-if="!hideArrows">
                                                    <div class="mb-2" @click="changeUserFilter('todos')"
                                                        style="cursor:pointer;">
                                                        <span class="kt-menu__item-here ng-star-inserted"></span>
                                                        <span class="kt-menu__link-icon ng-star-inserted mx-2"
                                                            style="font-size:large;">
                                                            <i class="kt-menu__link-icon la la-eye"></i>
                                                        </span>
                                                        <span class="kt-menu__link-text ng-star-inserted mx-3">Ver
                                                            Todos</span>
                                                    </div>
                                                    <div class="mb-2" @click="changeUserFilter('agentes')"
                                                        style="cursor:pointer;">
                                                        <span class="kt-menu__item-here ng-star-inserted"></span>
                                                        <span class="kt-menu__link-icon ng-star-inserted mx-2"
                                                            style="font-size:large;">
                                                            <i class="kt-menu__link-icon la la-eye"></i>
                                                        </span>
                                                        <span class="kt-menu__link-text ng-star-inserted mx-3">Ver
                                                            Agentes</span>
                                                    </div>
                                                    <div class="mb-2" @click="changeUserFilter('jugadores')"
                                                        style="cursor:pointer;">
                                                        <span class="kt-menu__item-here ng-star-inserted"></span>
                                                        <span class="kt-menu__link-icon ng-star-inserted mx-2"
                                                            style="font-size:large;">
                                                            <i class="kt-menu__link-icon la la-eye"></i>
                                                        </span>
                                                        <span class="kt-menu__link-text ng-star-inserted mx-3">Ver
                                                            Jugadores</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- REPORTES---------------------->
                                        <div :class="hideArrows ? 'mb-3' : ''" class="accordion-item sidebar-menu">
                                            <span class="accordion-header" id="headingOne">
                                                <span style="cursor:pointer;"
                                                    :class="!hideArrows ? 'accordion-button' : ''"
                                                    class="sidebar-item collapsed" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseTwo" aria-expanded="false"
                                                    aria-controls="collapseTwo">
                                                    <span class="kt-menu__link-icon ng-star-inserted mx-2"
                                                        style="font-size:large;"><i
                                                            class="kt-menu__link-icon la la-area-chart"></i></span>
                                                    <span v-if="!hideArrows" class="mx-3">Reportes</span>
                                                </span>
                                            </span>
                                            <div id="collapseTwo" class="accordion-collapse collapse p-0"
                                                aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div class="accordion-body text-start px-2 py-0" v-if="!hideArrows">
                                                    <div class="mb-2">
                                                        <div class="accordion p-0" id="accordionRFinancieros"
                                                            style="font-size:small;">
                                                            <div class="accordion-item sidebar-menu px-1">
                                                                <div style="cursor:pointer;"
                                                                    class="mb-1 mx-2 accordion-button kt-menu__link kt-menu__toggle ng-star-inserted collapsed"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseRF" aria-expanded="false"
                                                                    aria-controls="collapseRF">
                                                                    <span
                                                                        class="kt-menu__item-here ng-star-inserted"></span>
                                                                    <span class="kt-menu__link-icon ng-star-inserted"
                                                                        style="color:#9899ac!important;font-size:large;"><i
                                                                            class="kt-menu__link-icon la la-area-chart"></i></span>
                                                                    <span
                                                                        class="kt-menu__link-text ng-star-inserted mx-3"
                                                                        style="color:#9899ac!important">Reportes
                                                                        Financieros</span>
                                                                </div>
                                                                <div id="collapseRF" class="accordion-collapse collapse"
                                                                    aria-labelledby="headingOneRF"
                                                                    data-bs-parent="#accordionRFinancieros">
                                                                    <div class="accordion-body text-start kt-menu__item mx-0 py-0 px-5"
                                                                        v-if="!hideArrows">
                                                                        <div style="cursor:pointer;"
                                                                            class="mb-0 mb-3 d-flex"
                                                                            @click="changeView('reporte_general')">
                                                                            <span
                                                                                class="kt-menu__item-here ng-star-inserted"></span>
                                                                            <span
                                                                                class="kt-menu__link-icon ng-star-inserted"
                                                                                style="font-size:large;"><i
                                                                                    class="kt-menu__link-icon la la-area-chart"></i></span>
                                                                            <span
                                                                                class="kt-menu__link-text ng-star-inserted mx-3">General</span>
                                                                        </div>
                                                                        <div style="cursor:pointer;"
                                                                            class="mb-0 mb-3 d-flex"
                                                                            @click="changeView('reporte_cargas')">
                                                                            <span
                                                                                class="kt-menu__item-here ng-star-inserted"></span>
                                                                            <span
                                                                                class="kt-menu__link-icon ng-star-inserted"><i
                                                                                    class="kt-menu__link-icon la la-area-chart"
                                                                                    style="font-size:large;"></i></span>
                                                                            <span
                                                                                class="kt-menu__link-text ng-star-inserted mx-3">Caja
                                                                                (depósitos y retiros)</span>
                                                                        </div>
                                                                        <div style="cursor:pointer;"
                                                                            class="mb-0 mb-3 d-flex"
                                                                            @click="changeView('reporte_saldos')">
                                                                            <span
                                                                                class="kt-menu__item-here ng-star-inserted"></span>
                                                                            <span
                                                                                class="kt-menu__link-icon ng-star-inserted"><i
                                                                                    class="kt-menu__link-icon la la-area-chart"
                                                                                    style="font-size:large;"></i></span>
                                                                            <span
                                                                                class="kt-menu__link-text ng-star-inserted mx-3">Saldos
                                                                                en usuarios</span>
                                                                        </div>
                                                                        <div style="cursor:pointer;"
                                                                            class="mb-0 mb-3 d-flex"
                                                                            @click="changeView('reporte_bonos')">
                                                                            <span
                                                                                class="kt-menu__item-here ng-star-inserted"></span>
                                                                            <span
                                                                                class="kt-menu__link-icon ng-star-inserted"><i
                                                                                    class="kt-menu__link-icon la la-history"
                                                                                    style="font-size:large;"></i></span>
                                                                            <span
                                                                                class="kt-menu__link-text ng-star-inserted mx-3">Detalle
                                                                                bonificaciones</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="accordion-item sidebar-menu px-1">
                                                                <div style="cursor:pointer;"
                                                                    class="mb-1 mx-2 accordion-button kt-menu__link kt-menu__toggle ng-star-inserted collapsed"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseRJ" aria-expanded="false"
                                                                    aria-controls="collapseRF">
                                                                    <span
                                                                        class="kt-menu__item-here ng-star-inserted"></span>
                                                                    <span class="kt-menu__link-icon ng-star-inserted"
                                                                        style="color:#9899ac!important;font-size:large;"><i
                                                                            class="kt-menu__link-icon la la-area-chart"></i></span>
                                                                    <span
                                                                        class="kt-menu__link-text ng-star-inserted mx-3"
                                                                        style="color:#9899ac!important">Reportes
                                                                        Jugadores</span>
                                                                </div>
                                                                <div id="collapseRJ" class="accordion-collapse collapse"
                                                                    aria-labelledby="headingOneRF"
                                                                    data-bs-parent="#accordionRFinancieros">
                                                                    <div class="accordion-body text-start kt-menu__item mx-0 py-0 px-5"
                                                                        v-if="!hideArrows">
                                                                        <div style="cursor:pointer;"
                                                                            class="mb-0 mb-3 d-flex"
                                                                            @click="changeView('reporte_general_jugador')">
                                                                            <span
                                                                                class="kt-menu__item-here ng-star-inserted"></span>
                                                                            <span
                                                                                class="kt-menu__link-icon ng-star-inserted"
                                                                                style="font-size:large;"><i
                                                                                    class="kt-menu__link-icon la la-area-chart"></i></span>
                                                                            <span
                                                                                class="kt-menu__link-text ng-star-inserted mx-3">General</span>
                                                                        </div>
                                                                        <div style="cursor:pointer;"
                                                                            class="mb-0 mb-3 d-flex"
                                                                            @click="changeView('reporte_historial_jugador')">
                                                                            <span
                                                                                class="kt-menu__item-here ng-star-inserted"></span>
                                                                            <span
                                                                                class="kt-menu__link-icon ng-star-inserted"><i
                                                                                    class="kt-menu__link-icon la la-history"
                                                                                    style="font-size:large;"></i></span>
                                                                            <span
                                                                                class="kt-menu__link-text ng-star-inserted mx-3">Historial
                                                                                del Jugador</span>
                                                                        </div>
                                                                        <div style="cursor:pointer;"
                                                                            @click="changeView('reporte_ganadores_perdedores')"
                                                                            class="mb-0 mb-3 d-flex">
                                                                            <span
                                                                                class="kt-menu__item-here ng-star-inserted"></span>
                                                                            <span
                                                                                class="kt-menu__link-icon ng-star-inserted"><i
                                                                                    class="kt-menu__link-icon la la-history"
                                                                                    style="font-size:large;"></i></span>
                                                                            <span
                                                                                class="kt-menu__link-text ng-star-inserted mx-3">Mas
                                                                                Ganadores/Perdedores</span>
                                                                        </div>
                                                                        <div style="cursor:pointer;"
                                                                            class="mb-0 mb-3 d-flex"
                                                                            @click="changeView('reporte_detalle_producto')">
                                                                            <span
                                                                                class="kt-menu__item-here ng-star-inserted"></span>
                                                                            <span
                                                                                class="kt-menu__link-icon ng-star-inserted"><i
                                                                                    class="kt-menu__link-icon la la-history"
                                                                                    style="font-size:large;"></i></span>
                                                                            <span
                                                                                class="kt-menu__link-text ng-star-inserted mx-3">Detalle
                                                                                por producto</span>
                                                                        </div>
                                                                        <div style="cursor:pointer;"
                                                                            class="mb-0 mb-3 d-flex"
                                                                            @click="changeView('reporte_deportes')">
                                                                            <span
                                                                                class="kt-menu__item-here ng-star-inserted"></span>
                                                                            <span
                                                                                class="kt-menu__link-icon ng-star-inserted"><i
                                                                                    class="kt-menu__link-icon la la-area-chart"
                                                                                    style="font-size:large;"></i></span>
                                                                            <span
                                                                                class="kt-menu__link-text ng-star-inserted mx-3">Apuestas
                                                                                deportivas</span>
                                                                        </div>
                                                                        <div style="cursor:pointer;"
                                                                            class="mb-0 mb-3 d-flex"
                                                                            @click="changeView('reporte_poker')">
                                                                            <span
                                                                                class="kt-menu__item-here ng-star-inserted"></span>
                                                                            <span
                                                                                class="kt-menu__link-icon ng-star-inserted"><i
                                                                                    class="kt-menu__link-icon la la-area-chart"
                                                                                    style="font-size:large;"></i></span>
                                                                            <span
                                                                                class="kt-menu__link-text ng-star-inserted mx-3">Poker</span>
                                                                        </div>
                                                                        <div style="cursor:pointer;"
                                                                            class="mb-0 mb-3 d-flex"
                                                                            @click="changeView('reporte_hipicas')">
                                                                            <span
                                                                                class="kt-menu__item-here ng-star-inserted"></span>
                                                                            <span
                                                                                class="kt-menu__link-icon ng-star-inserted"><i
                                                                                    class="kt-menu__link-icon la la-area-chart"
                                                                                    style="font-size:large;"></i></span>
                                                                            <span
                                                                                class="kt-menu__link-text ng-star-inserted mx-3">Carrera
                                                                                de Caballos</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- FINANZAS---------------------->
                                        <div :class="hideArrows ? 'mb-3' : ''" class="accordion-item sidebar-menu">
                                            <span style="cursor:pointer;" class="accordion-header" id="headingThree">
                                                <span :class="!hideArrows ? 'accordion-button' : ''"
                                                    class="sidebar-item collapsed" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseThree" aria-expanded="false"
                                                    aria-controls="collapseThree">
                                                    <span class="kt-menu__link-icon ng-star-inserted mx-2"
                                                        style="font-size:large;"><i
                                                            class="kt-menu__link-icon flaticon-settings"></i></span>
                                                    <span v-if="!hideArrows" class="mx-3"
                                                        style="font-size:small;">Finanzas</span>
                                                </span>
                                            </span>
                                            <div id="collapseThree" class="accordion-collapse collapse"
                                                aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div class="accordion-body text-start kt-menu__item" v-if="!hideArrows">
                                                    <div class="mb-2" style="cursor:pointer;"
                                                        @click="changeView('mi_cuenta_corriente')">
                                                        <span class="kt-menu__item-here ng-star-inserted"></span>
                                                        <span class="kt-menu__link-icon ng-star-inserted mx-2"
                                                            style="font-size:large;">
                                                            <i class="kt-menu__link-icon la la-user"></i>
                                                        </span>
                                                        <span class="kt-menu__link-text ng-star-inserted mx-3">Mi cuenta
                                                            corriente</span>
                                                    </div>
                                                    <div class="mb-2" style="cursor:pointer;"
                                                        @click="changeView('cuentas_corrientes')">
                                                        <span class="kt-menu__item-here ng-star-inserted"></span>
                                                        <span class="kt-menu__link-icon ng-star-inserted mx-2"
                                                            style="font-size:large;">
                                                            <i class="kt-menu__link-icon la la-bank"></i>
                                                        </span>
                                                        <span class="kt-menu__link-text ng-star-inserted mx-3">Cuentas
                                                            corrientes</span>
                                                    </div>
                                                    <div class="mb-2" style="cursor:pointer;">
                                                        <span class="kt-menu__item-here ng-star-inserted"></span>
                                                        <span class="kt-menu__link-icon ng-star-inserted mx-2"
                                                            style="font-size:large;">
                                                            <i class="kt-menu__link-icon la la-exclamation-circle"></i>
                                                        </span>
                                                        <span class="kt-menu__link-text ng-star-inserted mx-3">Cómo
                                                            empezar</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ps__rail-x" style="left: 0px; bottom: 0px; width: 113px;">
                                        <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
                                    </div>
                                    <div class="ps__rail-y" style="top: 0px; right: 3px;">
                                        <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid">
                                <div class="kt-aside-menu ps" data-ktmenu-dropdown="0" data-ktmenu-scroll="1"
                                    data-ktmenu-vertical="1" id="kt_aside_menu" ktmenu="" style="padding: 15px;">
                                    <ul class="kt-menu__nav">
                                        <li aria-haspopup="true"
                                            class="kt-menu__item kt-menu__item--submenu ng-star-inserted"
                                            data-ktmenu-submenu-toggle="hover">
                                            <a class="btn kt-menu__link kt-menu__toggle ng-star-inserted waves-effect waves-light"
                                            :href="telegram" target="_blank"
                                                style="display: flex!important; background-color: #0088cc!important; color: #fff!important;"
                                                title="Canal de Telegram"><img id="imgTelegram"
                                                    src="/ganaencasa/img/telegram.png"
                                                    style="height:20px!important; margin-right: 1rem!important">
                                                <span class="kt-menu__link-text ng-star-inserted"
                                                    style="color: white!important;">Novedades de Telegram</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                <div id="navContainerdummy" style="transition: 0.6s;" class="d-none d-md-block"></div>
                <div :style="'width:' + contentWidth" class="kt-grid__item kt-grid kt-grid--hor kt-wrapper kt-grid-tree"
                    id="kt_wrapper">
                    <div class="collapse" id="collapseExample" style="background-color: #15172B !important;">
                        <div class="card card-body" style="background-color: #15172B !important;">
                            <div class="kt-header__topbar-wrapper text-end" data-offset="0px,0px" data-toggle="dropdown"
                                @click="showProfile = !showProfile" dropdowntoggle="" aria-haspopup="true">
                                <img alt="Pic"
                                    class="header-profile-picture m--img-rounded m--marginless m--img-centered ng-star-inserted"
                                    src="/ganaencasa/img/default-profile-picture.png" style="width:10%">
                            </div><!---->
                            <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
                                :style="showProfile ? 'display:block !important;' : ''"
                                style="position: absolute; will-change: transform; left: auto; right: -150px;"
                                x-placement="bottom-end">
                                <div class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
                                    style="background: url('/ganaencasa/img/bg-1.jpg') 0% 0% / cover;">
                                    <div class="kt-user-card__avatar"><img alt="Pic" class=""
                                            src="/ganaencasa/img/default-profile-picture.png"></div>
                                    <div class="kt-user-card__name">{{ UserData.name }}</div>
                                    <div class="kt-user-card__name"><span class="MainAgentBalance">{{ new
            Intl.NumberFormat("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(UserData.balance) }}</span>
                                    </div>
                                </div>
                                <div class="kt-notification"><!----><!---->
                                    <div class="kt-notification__custom py-0 linked-accounts"><!----></div>
                                    <span style="cursor: pointer;" class="kt-notification__item">
                                        <div class="kt-notification__item-icon"><i class="flaticon-more-v6"></i>
                                        </div>
                                        <div class="kt-notification__item-details"
                                            @click="openModal('ModalChangePassword1')">
                                            <div class="kt-notification__item-title kt-font-bold"><span> Cambiar
                                                    contraseña </span></div>
                                        </div>
                                    </span>
                                    <span style="cursor: pointer;" class="kt-notification__item login-history-btn">
                                        <div class="kt-notification__item-icon"><i class="flaticon-list"></i></div>
                                        <div class="kt-notification__item-details">
                                            <div class="kt-notification__item-title kt-font-bold"><span>Intentos de
                                                    iniciar sesión</span></div>
                                        </div>
                                    </span>
                                    <span style="cursor: pointer;" class="kt-notification__item">
                                        <div class="kt-notification__item-icon"><i class="flaticon-profile-1"></i>
                                        </div>
                                        <div class="kt-notification__item-details">
                                            <div class="kt-notification__item-title kt-font-bold"><span> Cambiar foto de
                                                    perfil </span></div>
                                        </div>
                                    </span>
                                    <span style="cursor: pointer;" class="kt-notification__item">
                                        <div class="kt-notification__item-icon"><i class="flaticon-security"></i>
                                        </div>
                                        <div class="kt-notification__item-details">
                                            <div class="kt-notification__item-title kt-font-bold"><span> Configuración
                                                    autenticación 2-factores </span></div>
                                        </div>
                                    </span><!---->
                                    <div class="kt-notification__custom"><button @click="logout"
                                            class="btn btn-label-brand btn-sm btn-bold waves-effect waves-light">Salir</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="kt-header kt-grid__item kt-head--skin-light" id="kt_header">
                        <div class="d-flex w-100">
                            <div class=""
                                style="width:70%;align-content: center; font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif; color: #FFC529; font-weight: 400; background: #15172B; overflow: hidden; padding: 5px;">
                                <span class="float-start"
                                    style="font-weight:bold; color: #FFC529; font-size: 14px; text-align: left;">AVISO
                                    IMPORTANTE</span><!---->
                                <p style="color: rgb(255, 197, 41); font-size: 13px; text-align: left;"
                                    class="ng-star-inserted">
                                    <marquee onmouseout="this.start();" onmouseover="this.stop();">
                                        <span style="color: #FFC529"> Nuevo botón GENERAR CONTRASEÑA: presione el botón
                                            para
                                            generar y copiar la contraseña nueva, luego presione PEGAR en la
                                            conversación
                                            del cliente para compartir.</span>
                                    </marquee>
                                </p><!---->
                            </div>
                            <div style="margin-left: 5px; margin-top: 15px;"
                                :style="screen_width > 1000 ? 'width:20%' : ''">
                                <span class="kt-header__topbar-username" style="font-size: 18px;">
                                    <span style="color:white;width:20%;">{{ UserData.name }}
                                        ({{ new Intl.NumberFormat("de-DE", {
            minimumFractionDigits:
                2, maximumFractionDigits: 2
        }).format(UserData.balance) }})</span> </span>
                            </div>
                            <div v-if="screen_width > 1000" class="kt-header__topbar"
                                style="width:10%;padding-right: 5px !important;"><!----><!----><!---->
                                <div class="kt-header__topbar-item dropdown dropdown-language">
                                    <div class="kt-header__topbar-wrapper" data-toggle="dropdown" aria-haspopup="true">
                                        <span class="kt-header__topbar-icon kt-header__topbar-icon--brand"><i
                                                class="famfamfam-flags es"></i></span>
                                    </div><!---->
                                    <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right  dropdown-menu-top-unround dropdown-menu-lg"
                                        aria-label="Languages">
                                        <ul class="kt-nav kt-margin-t-10 kt-margin-b-10"><!---->
                                            <li class="kt-nav__item" data-type="en">
                                                <a class="kt-nav__link">
                                                    <span class="kt-nav__link-icon"><i
                                                            class="famfamfam-flags gb"></i></span>
                                                    <span class="kt-nav__link-text"> English (United Kingdom) </span>
                                                </a>
                                            </li>
                                            <li class="kt-nav__item" data-type="es">
                                                <a class="kt-nav__link">
                                                    <span class="kt-nav__link-icon"><i
                                                            class="famfamfam-flags es"></i></span>
                                                    <span class="kt-nav__link-text"> Español (Spanish) </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="kt-header__topbar-item dropdown" id="header_notification_bar" style="">
                                    <div class="kt-header__topbar-wrapper" data-toggle="dropdown" aria-haspopup="true">
                                        <span class="kt-header__topbar-icon kt-header__topbar-icon--primary"><!---->
                                            <div class="ng-star-inserted" style=""><i
                                                    class="flaticon-alert-2 unread-notification"></i>
                                                <!-- <span class="kt-badge kt-badge--primary unread-notification-count"> 1
                                                </span> -->
                                            </div><!---->
                                        </span>
                                    </div>
                                </div><!---->
                                <div class="dropdown kt-header__topbar-item kt-header__topbar-item--user">
                                    <div class="kt-header__topbar-wrapper" id="dropdownMenuUser"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <div class="kt-header__topbar-user"><!----><!---->
                                            <img alt="Pic"
                                                class="header-profile-picture m--img-rounded m--marginless m--img-centered ng-star-inserted"
                                                src="/ganaencasa/img/default-profile-picture.png" style="">
                                        </div>
                                    </div>
                                    <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-top-unround dropdown-menu-xl"
                                        aria-labelledby="dropdownMenuUser">
                                        <div class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
                                            style="background: url('/ganaencasa/img/bg-1.jpg') 0% 0% / cover;">
                                            <div class="kt-user-card__avatar"><img alt="Pic" class=""
                                                    src="/ganaencasa/img/default-profile-picture.png"></div>
                                            <div class="kt-user-card__name">{{ UserData.name }}</div>
                                            <div class="kt-user-card__name"><span class="MainAgentBalance">{{ new
            Intl.NumberFormat("de-DE", {
                minimumFractionDigits:
                    2, maximumFractionDigits: 2
            }).format(UserData.balance) }}</span>
                                            </div>
                                        </div>
                                        <div class="kt-notification"><!----><!---->
                                            <div class="kt-notification__custom py-0 linked-accounts"><!----></div>
                                            <span style="cursor: pointer;" class="kt-notification__item" @click="openModal('ModalChangePassword1')">
                                                <div class="kt-notification__item-icon"><i class="flaticon-more-v6"></i>
                                                </div>
                                                <div class="kt-notification__item-details">
                                                    <div class="kt-notification__item-title kt-font-bold"><span> Cambiar
                                                            contraseña</span></div>
                                                </div>
                                            </span>
                                            <span style="cursor: pointer;" @click="openModal('loginAtempts')"
                                                class="kt-notification__item login-history-btn" href="javascript:;">
                                                <div class="kt-notification__item-icon"><i class="flaticon-list"></i>
                                                </div>
                                                <div class="kt-notification__item-details">
                                                    <div class="kt-notification__item-title kt-font-bold"><span>Intentos
                                                            de iniciar sesión</span></div>
                                                </div>
                                            </span>
                                            <span style="cursor: pointer;" class="kt-notification__item">
                                                <div class="kt-notification__item-icon"><i
                                                        class="flaticon-profile-1"></i>
                                                </div>
                                                <div class="kt-notification__item-details">
                                                    <div class="kt-notification__item-title kt-font-bold"><span>Cambiar
                                                            foto de perfil</span></div>
                                                </div>
                                            </span>
                                            <span style="cursor: pointer;" class="kt-notification__item">
                                                <div class="kt-notification__item-icon"><i
                                                        class="flaticon-security"></i>
                                                </div>
                                                <div class="kt-notification__item-details">
                                                    <div class="kt-notification__item-title kt-font-bold">
                                                        <span>Configurar autenticación 2-factores </span>
                                                    </div>
                                                </div>
                                            </span><!---->
                                            <div class="kt-notification__custom"><button @click="logout"
                                                    class="btn btn-label-brand btn-sm btn-bold waves-effect waves-light">Salir</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><!---->
                    <div class="p-1">
                        <UsersComponent :screenWidth="screen_width" v-if="getActualView === 'users'"></UsersComponent>
                        <reporteGeneral :screenWidth="screen_width" v-if="getActualView === 'reporte_general'">
                        </reporteGeneral>
                        <reporteGeneralJugador :screenWidth="screen_width"
                            v-if="getActualView === 'reporte_general_jugador'"></reporteGeneralJugador>
                        <reporteCargas :screenWidth="screen_width" v-if="getActualView === 'reporte_cargas'">
                        </reporteCargas>
                        <reporteSaldos :screenWidth="screen_width" v-if="getActualView === 'reporte_saldos'">
                        </reporteSaldos>
                        <reporteBonos :screenWidth="screen_width" v-if="getActualView === 'reporte_bonos'">
                        </reporteBonos>
                        <reporteHistorialJugador :screenWidth="screen_width"
                            v-if="getActualView === 'reporte_historial_jugador'"></reporteHistorialJugador>
                        <reporteDetalleProducto :screenWidth="screen_width"
                            v-if="getActualView === 'reporte_detalle_producto'"></reporteDetalleProducto>
                        <reporteDeportes :screenWidth="screen_width" v-if="getActualView === 'reporte_deportes'">
                        </reporteDeportes>
                        <reportePoker :screenWidth="screen_width" v-if="getActualView === 'reporte_poker'">
                        </reportePoker>
                        <reporteHipicas :screenWidth="screen_width" v-if="getActualView === 'reporte_hipicas'">
                        </reporteHipicas>
                        <cuentasCorrientes :screenWidth="screen_width" v-if="getActualView === 'cuentas_corrientes'">
                        </cuentasCorrientes>
                        <miCuentaCorriente :screenWidth="screen_width" v-if="getActualView === 'mi_cuenta_corriente'">
                        </miCuentaCorriente>
                        <reporteGanadoresPerdedores :screenWidth="screen_width" v-if="getActualView === 'reporte_ganadores_perdedores'">
                        </reporteGanadoresPerdedores>
                    </div>
                </div>

            </div>
            <div _ngcontent-qlf-c13="" aria-hidden="false" aria-labelledby="myLargeModalLabel" bsmodal=""
                id="ModalChangePassword1" class="modal fade in show" role="dialog" tabindex="-1" aria-modal="true">
                <div _ngcontent-qlf-c13="" class="modal-dialog modal-lg">
                    <div _ngcontent-qlf-c13="" class="modal-content"><!---->
                        <form v-on:submit.prevent="changePassword"
                            class="ng-pristine ng-star-inserted ng-invalid ng-touched">
                            <div _ngcontent-qlf-c13="" class="modal-header">
                                <h5 _ngcontent-qlf-c13="" class="modal-title"><span _ngcontent-qlf-c13="">Cambiar
                                        contraseña</span></h5><button _ngcontent-qlf-c13="" class="close" type="button"
                                    @click="closeModal" aria-label="Cerrar"><span _ngcontent-qlf-c13=""
                                        aria-hidden="true">×</span></button>
                            </div>
                            <div _ngcontent-qlf-c13="" class="modal-body">
                                <div _ngcontent-qlf-c13="" class="form-group"><label _ngcontent-qlf-c13=""
                                        for="CurrentPassword">Contraseña actual</label><input _ngcontent-qlf-c13=""
                                        class="form-control ng-pristine ng-invalid ng-touched" id="CurrentPassword"
                                        name="CurrentPassword" required="" type="password"></div>
                                <div _ngcontent-qlf-c13="" class="form-group"><label _ngcontent-qlf-c13=""
                                        for="NewPassword">Nueva contraseña</label><input v-model="new_password"
                                        class="form-control " id="NewPassword" name="NewPassword" required
                                        type="password"></div>
                                <div _ngcontent-qlf-c13="" class="form-group"><label _ngcontent-qlf-c13=""
                                        for="NewPasswordRepeat">Nueva contraseña (repetir)</label><input
                                        v-model="new_password2" _ngcontent-qlf-c13="" class="form-control"
                                        id="NewPasswordRepeat" name="NewPasswordRepeat" required type="password"></div>
                                <div _ngcontent-qlf-c13=""
                                    class="d-flex align-items-center justify-content-between p-2">
                                    <div _ngcontent-qlf-c13="" class="d-flex align-items-center my-translated-paragraph"
                                        placement="bottom" type="button" aria-describedby="tooltip-70"><i
                                            _ngcontent-qlf-c13="" class="fa fa-info-circle mr-2"></i><label
                                            _ngcontent-qlf-c13="" class="mb-0">Requisitos de contraseña</label></div>
                                </div>
                                <div v-if="changePasswordMessage.message != ''"
                                    class="font-size-custom text-center pb-4" id="ChangePasswordError"
                                    style="display: block;"
                                    :style="changePasswordMessage.type == 'error' ? 'color:red;' : 'color:green;'">{{
                                    changePasswordMessage.message }}</div>
                            </div>
                            <div _ngcontent-qlf-c13="" class="modal-footer"><button _ngcontent-qlf-c13=""
                                    class="btn btn-secondary" type="button" @click="closeModal">Cancelar</button><button
                                    _ngcontent-qlf-c13="" class="btn btn-primary" type="submit"
                                    :disabled="!(new_password!=='' && new_password2!=='')"><i _ngcontent-qlf-c13=""
                                        class="fa fa-save"></i><span _ngcontent-qlf-c13="">Guardar</span></button></div>
                        </form>
                    </div>
                </div>
            </div>
            <div aria-hidden="false" aria-labelledby="myLargeModalLabel" bsmodal="" class="modal fade in show" id="loginAtempts"
                role="dialog" tabindex="-1" aria-modal="true" style="overflow-y: auto;">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title"><span>Intentos de iniciar sesión</span></h5><button class="close"
                                type="button" aria-label="Cerrar" @click="closeModal"><span aria-hidden="true">×</span></button>
                        </div>
                        <div class="modal-body">
                            <form class="form-horizontal ng-untouched ng-pristine ng-valid" id="loginAttemptsModalForm"
                                novalidate="">
                                <div class="form-body">
                                    <div class="row"><!---->
                                        <div class="col-sm-12 ng-star-inserted" v-for="(item, index) in ingresos" :key="index">
                                            <div class="alert alert-outline-success" role="alert">
                                                <div class="alert__text"><!----><!---->
                                                    <div class="row ng-star-inserted"><label
                                                            class="kt-font-bolder col-sm-3 text-right">Plataforma</label>
                                                        <div class="col-sm-9">
                                                            <p class="form-control-static">{{ item.plataforma }} IP: {{ item.ip }} </p>
                                                        </div>
                                                    </div>
                                                    <div class="row"><label
                                                            class="kt-font-bolder col-sm-3 text-right">Tiempo</label>
                                                        <div class="col-sm-9">
                                                            <p class="form-control-static login-attempt-date"> {{ formatearFecha(item.fecha) }} </p>
                                                        </div>
                                                    </div>
                                                    <div class="row"><label
                                                            class="kt-font-bolder col-sm-3 text-right">Descripción</label>
                                                        <div class="col-sm-9">
                                                            <p class="form-control-static login-attempt-date"> {{ item.descripcion }} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <a class="wfloat" :href="'https://api.whatsapp.com/send?phone='+whatsapp+'&amp;text=Por favor escribe tu mensaje.'" target="_blank"><i class="fab fa-whatsapp my-float" title="CONTACTANOS POR WHASTAPP"></i></a>
            <div id="modalBackdrop" class="modal-backdrop fade" style="display: none;"></div>
            <div id="modalBackdropMovil" class="modal-backdrop fade" style="display: none;z-index: 8;"
                @click="closeNav"></div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import router from '../router/index';
import UsersComponent from './dashboard-components/users-component'
import reporteGeneral from './dashboard-components/reporte-general.vue';
import reporteCargas from './dashboard-components/reporte-cargas.vue';
import reporteSaldos from './dashboard-components/reporte-saldos.vue';
import reporteBonos from './dashboard-components/reporte-bonos.vue';
import reporteGeneralJugador from './dashboard-components/reporte-general-jugador.vue';
import reporteHistorialJugador from './dashboard-components/reporte-historial-jugador.vue';
import reporteDetalleProducto from './dashboard-components/reporte-detalle-producto.vue';
import reporteDeportes from './dashboard-components/reporte-deportes.vue';
import reportePoker from './dashboard-components/reporte-poker.vue';
import reporteHipicas from './dashboard-components/reporte-hipicas.vue';
import cuentasCorrientes from './dashboard-components/cuentas-corrientes.vue';
import miCuentaCorriente from './dashboard-components/mi-cuenta-corriente.vue';
import reporteGanadoresPerdedores from './dashboard-components/reporte-ganadores-perdedores.vue';
export default {
    name: 'DashboardComponent',
    components: {
        UsersComponent,
        reporteGeneral,
        reporteCargas,
        reporteGeneralJugador,
        reporteSaldos,
        reporteBonos,
        reporteHistorialJugador,
        reporteDetalleProducto,
        reporteDeportes,
        reportePoker,
        reporteHipicas,
        cuentasCorrientes,
        miCuentaCorriente,
        reporteGanadoresPerdedores
    },
    data() {
        return {
            telegram: process.env.VUE_APP_TELEGRAM,
            whatsapp: process.env.VUE_APP_WHATSAPP,
            showProfile: false,
            screen_width: 0,
            viewType: 'wide',
            hideArrows: false,
            contentWidth: 0,
            new_password2: "",
            new_password: "",
            changePasswordMessage: { type: "", message: "" },
            loading: false,
            support_subject: "",
            support_message: "",
            support_image: "",
            support_image_src: "",
            supportTickets: [],
            supportTicketTarget: {},
            ingresos:[]
        }
    },
    methods: {
        async getDataIngresos() {
            try {
                this.loading_report=true;
                this.data_requested=true;
                let data = await axios.post(process.env.VUE_APP_DATA_SERVER_URL +"/auditoria", {
                    "login_Id": this.main_tree.id
                })
                this.ingresos = data.data.filter(item=>item.descripcion == 'ingreso falló').slice(0, 10);
            } catch (error) {
                console.log(error)
            }
        },
        logout() {
            this.$store.dispatch("logOut")
        },
        reload() {
            this.reloadCharts++;
        },
        sizeControl() {
            this.screen_width = window.innerWidth;
        },
        changeView: function (view) {
            this.$store.dispatch('setDashboardView', view);
            if (view === 'mi_cuenta_corriente') {
                this.$store.dispatch('setCuentaCorrienteTarget', '');
            }
            if (this.screen_width < 1000) {
                this.closeNav()
            }
        },
        changeUserFilter(filter) {
            this.$store.dispatch('setDashboardView', 'users');
            this.$store.dispatch('setCuentaCorrienteTarget', this.main_tree.user_name);
            if (document.getElementById('tgAll')) {
                this.$root.$emit('changeUserFilter', filter);
            }
            if (this.screen_width < 1000) {
                this.closeNav()
            }
        },
        openNav() {
            document.getElementById("mySidenav").style.width = "250px";
            if (this.screen_width < 1000) {
                document.getElementById("modalBackdropMovil").setAttribute('style', 'display:block !important');
                document.getElementById("modalBackdropMovil").classList.add("d-block");
                this.contentWidth = '100%'
            }
            else {
                document.getElementById('navContainerdummy').style.width = "250px";
                this.hideArrows = false
                this.viewType = "thin"
                this.contentWidth = (window.innerWidth - 250) + 'px'
            }
        },
        closeNav() {
            document.getElementById("mySidenav").style.width = "0px";
            if (this.screen_width < 1000) {
                this.contentWidth = '100%'
                document.getElementById("modalBackdropMovil").setAttribute('style', 'display:none !important');
                document.getElementById("modalBackdropMovil").classList.remove("d-block");
            }
            else {
                document.getElementById('navContainerdummy').style.width = "60px";
                document.getElementById("mySidenav").style.width = "60px";
                this.contentWidth = (window.innerWidth - 60) + 'px'
                this.hideArrows = true
                this.viewType = "wide"
            }
        },
        async changePassword() {
            try {
                this.loading = true;
                let response = await axios.post(process.env.VUE_APP_DATA_SERVER_URL + "/cambiar_password", {
                    "users": [this.UserData.id],
                    "password": this.new_password,
                    "password1": this.new_password2,
                    "token": this.UserData.token,
                    "login_id": this.main_tree.id
                }
                )
                if (response.data) {
                    this.changePasswordMessage = {
                        message: response.data.message ?? response.data.error,
                        type: response.data.message ? 'success' : 'error'
                    };
                    this.loading = false;
                }
            } catch (error) {
                console.log(error.message);
                this.loading = false;
            }
        },
        openModal(target, ticket) {
            try {
                // this.backdrop(true);
                let modal = document.getElementById(target);
                modal.classList.add("show");
                modal.style.display = "block";
                if (target == 'loginAtempts') {
                    this.getDataIngresos()
                }
                if (ticket) {
                    this.supportTicketTarget = ticket
                    this.readTicket(ticket)
                }
            } catch (error) {
                console.log(error);
            }
        },
        showHidePassword(target, icon, flag) {
            if (flag == 1 && !this.showPassword) {
                document.getElementById(target).type = "text";
                document.getElementById(icon).classList.remove("fa-eye");
                document.getElementById(icon).classList.add("fa-eye-slash");
                this.showPassword = true
            }
            else if (flag == 1 && this.showPassword) {
                document.getElementById(target).type = "password";
                document.getElementById(icon).classList.remove("fa-eye-slash");
                document.getElementById(icon).classList.add("fa-eye");
                this.showPassword = false
            }
            if (flag == 2 && !this.showPassword2) {
                document.getElementById(target).type = "text";
                document.getElementById(icon).classList.remove("fa-eye");
                document.getElementById(icon).classList.add("fa-eye-slash");
                this.showPassword2 = true
            }
            else if (flag == 2 && this.showPassword2) {
                document.getElementById(target).type = "password";
                document.getElementById(icon).classList.remove("fa-eye-slash");
                document.getElementById(icon).classList.add("fa-eye");
                this.showPassword2 = false
            }
        },
        backdrop(action) {
            if (action) {
                document.getElementById("modalBackdrop").style.display = "block";
                document.getElementById("modalBackdrop").classList.add("show");
            }
            else {
                document.getElementById("modalBackdrop").style.display = "none";
                document.getElementById("modalBackdrop").classList.remove("show");
            }
        },
        closeModal() {
            // this.backdrop(false);
            let modals = document.getElementsByClassName("modal");
            [...modals].forEach(modal => {
                modal.classList.remove("show");
                modal.style.display = "none";
            })
            if (this.changePasswordMessage.type == 'success') {
                this.logout()
            }
            this.new_password = "";
            this.new_password2 = "";
            this.support_subject = "";
            this.support_message = "";
            this.support_image = "";
            this.support_image_src = "";
            this.changePasswordMessage = { type: "", message: "" }
        },
        showPasswordChangeSecurity() {
            setTimeout(() => { this.openModal('ModalChangePassword1') }, 2000)
        },
        async getSupportTickets() {
            let tickets = await axios.post(process.env.VUE_APP_DATA_SERVER_URL + "/estado_soporte", {
                "login_Id": this.main_tree.id
            })
            if (tickets.data) {
                this.supportTickets = tickets.data;
            }
        },
        countDays(init) {
            // Crear dos objetos Date con las fechas que quieres comparar
            var fecha1 = new Date(init);
            var fecha2 = new Date();

            // Calcular la diferencia en milisegundos entre las dos fechas
            var diferenciaEnMilisegundos = fecha2 - fecha1;

            // Convertir la diferencia en días
            var diferenciaEnDias = diferenciaEnMilisegundos / (1000 * 60 * 60 * 24);

            // Redondear la diferencia si es necesario
            diferenciaEnDias = Math.round(diferenciaEnDias);
            return diferenciaEnDias;
        },
        async createTicket() {
            this.loading = true
            await axios.post(process.env.VUE_APP_DATA_SERVER_URL + "/soporte", {
                "asunto": this.support_subject,
                "descripcion": this.support_message,
                "login_Id": this.main_tree.id,
                "estado": 1,
                "image": this.support_image_src,
                "gplus_uid": 1001
            })
            this.loading = false
            this.support_subject = "";
            this.support_message = "";
            this.support_image = "";
            this.support_image_src = "";
            this.getSupportTickets()
            this.closeModal()
        },
        async replyTicket() {
            this.loading = true
            await axios.post(process.env.VUE_APP_DATA_SERVER_URL + "/respuesta_soporte", {
                "ticket": this.supportTicketTarget.id,
                "descripcion": this.support_message,
                "image": this.support_image_src,
                "login_Id": this.main_tree.id,
                "estado": 1
            })
            this.loading = false
            this.support_subject = "";
            this.support_message = "";
            this.support_image = "";
            this.support_image_src = "";
            this.getSupportTickets()
            this.closeModal()
        },
        triggerImageUpload() {
            this.$refs.imageInput.click();  // Activa el input de tipo "file"
        },
        compressImage(imgToCompress, resizingFactor = 0.3, quality = 0.5) {
            // resizing the image
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");

            const originalWidth = imgToCompress.width;
            const originalHeight = imgToCompress.height;

            const canvasWidth = originalWidth * resizingFactor;
            const canvasHeight = originalHeight * resizingFactor;

            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            context.drawImage(
                imgToCompress,
                0,
                0,
                originalWidth * resizingFactor,
                originalHeight * resizingFactor
            );

            // reducing the quality of the image
            const self = this;
            canvas.toBlob(
                (blob) => {
                    if (blob) {
                        var reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onloadend = function () {
                            var base64data = reader.result;
                            self.support_image_src = base64data; // AQUI ESTA EL BASE 64 RESULTANTE
                        }
                    }
                },
                "image/jpeg",
                quality
            );
        },
        handleImageUpload(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    // Aquí reducimos la calidad de la imagen antes de convertirla en Data URL
                    const image = new Image();
                    image.src = e.target.result;
                    // console.log(file.name)
                    this.support_image = file.name;
                    const self = this;
                    image.onload = () => {
                        self.compressImage(image)
                    };
                };
                reader.readAsDataURL(file);
            }
        },
        async readTicket(ticket) {
            console.log(ticket.leido_padre, ticket.padre, this.main_tree.id);
            if ((ticket.leido_user_creacion == 1 && ticket.user_creacion == this.main_tree.id) || (ticket.leido_padre == 1 && ticket.padre == this.main_tree.id)) {
                console.log("ticket read");
                await axios.post(process.env.VUE_APP_DATA_SERVER_URL + "/leido_soporte", {
                    "login_Id": this.main_tree.id,
                    "ticket": this.supportTicketTarget.id
                })
                this.getSupportTickets();
            }
        },
        formatearFecha(fechaString) {
            const fecha = new Date(fechaString);
            const anio = fecha.getFullYear();
            const mes = this.agregarCeroDelante(fecha.getMonth() + 1);
            const dia = this.agregarCeroDelante(fecha.getDate());
            const Hora = this.agregarCeroDelante(fecha.getHours());
            const Minutos = this.agregarCeroDelante(fecha.getMinutes());
            const Segundos = this.agregarCeroDelante(fecha.getSeconds());
            return `${anio}/${mes}/${dia} ${Hora}:${Minutos}:${Segundos}`;
        },
        agregarCeroDelante(numero) {
            return numero < 10 ? '0' + numero : numero;
        },
        isTreeLoaded() {
            this.treeLoaded = true;
        }
    },
    computed: {
        showChangePassword() {
            if (this.$store.getters['getShowChangePassword']) {
                this.showPasswordChangeSecurity()
            }
            return this.$store.getters['getShowChangePassword'];
        },
        reloadStats() {
            return this.$store.getters["getRenderStats"];
        },
        getActualView() {
            return this.$store.getters['getDashboardView'];
        },
        UserData() {
            return this.$store.getters["getUserData"];
        },
        main_tree() {
            let data = this.$store.getters["getMainTree"]
            if (data.id) {
                this.isTreeLoaded();
            }
            return data;
        },
    },
    created() {
        this.sizeControl();
        if (localStorage.getItem("loginToken") && !this.UserData.token) {
            this.$store.dispatch("login", { token: localStorage.getItem("loginToken") })
        }
        else if (this.$store.getters["getUserData"].token == "") {
            router.push({ name: 'login' })
        }
    },
    mounted() {
        if (this.screen_width > 1000) {
            this.openNav()
        }
        else {
            this.closeNav()
        }
        window.addEventListener('resize', () => { this.sizeControl(); });
    }
}
</script>

<style scoped>
.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    background-color: #15172B;
    overflow-x: hidden;
    transition: 0.5s;
}

.navbar {
    transition: 0.5s;
}

.sidenav ul {
    list-style: none;
    padding-left: 0;
}

.sidenav>ul {
    max-height: 100vh;
}

.sidenav .fa-angle-right {
    position: absolute;
    right: 0;
    top: 0.8rem;
    margin-right: 1.25rem !important;
}

.sidenav.wide .fa-angle-down.rotate-icon {
    opacity: 1;
    display: block;
}

.btn.btn-label-white.group-active,
.btn.btn-label-white:hover {
    background-color: #fff !important;
    color: #000 !important;
    border-color: #fff !important;
}

.kt-content px-1,
.kt-portlet__head,
.tree,
.ui-tree-selectable {
    background-color: #15172b !important;
}

.nav-tabs li .btn {
    width: 100%;
    border-radius: 7px 7px 0 0;
    border: 1px solid #45484b !important;
    color: #fff;
}

.dataTables_wrapper .table thead {
    background: #24284b;
}

.dataTables_wrapper .table thead tr th {
    color: #dce7f9;
    font-family: 'Times New Roman', sans-serif;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    padding: 10px !important;
    font-weight: 600 !important;
}

.sidenav .fa-angle-down.rotate-icon {
    position: absolute;
    right: 0;
    top: 0.8rem;
    margin-right: 1.25rem;
}

a {
    color: black !important;
    display: block;
    height: 40px;
    line-height: 40px;
    padding-left: 20px;
    transition: 0.3s;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

.sidebar-button {
    display: inline-block;
    padding: 0;
    border: 0;
    background: none;
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.input-icon .m-input {
    padding-left: calc(1.5em + 1.3rem + 2px);
    background-color: #15172b;
    border: 1px solid #45484b !important;
}

.btn-active {
    background-color: rgb(255, 197, 41) !important;
    color: rgb(36, 40, 75) !important;
    border-color: rgb(255, 197, 41) !important;
}

.header-message {
    position: relative;
    overflow: hidden;
    animation: move-words 20s linear infinite;
    margin: 0;

    &:hover {
        animation-play-state: paused;
    }
}

@keyframes move-words {
    0% {
        left: 100%;
    }

    100% {
        left: -100%;
    }
}
</style>
