import { render, staticRenderFns } from "./reporte-ganadores-perdedores.vue?vue&type=template&id=36dd8701&scoped=true"
import script from "./reporte-ganadores-perdedores.vue?vue&type=script&lang=js"
export * from "./reporte-ganadores-perdedores.vue?vue&type=script&lang=js"
import style0 from "./reporte-ganadores-perdedores.vue?vue&type=style&index=0&id=36dd8701&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36dd8701",
  null
  
)

export default component.exports