<template>
  <div style="margin: 2px 2px;" v-if="(node.show || showHiddenTree)">
      <div :id="'container-'+node.id" href="#" style="color:white !important;" :style="targetUser.id==node.id?'background-color: #626272 !important':'background-color: #3f3e4d !important'" class="ui-treenode ng-star-inserted">
          <span @click="isExpanded(node.id)" style="color:white !important" :id="'button-'+node.id" type="button" data-bs-toggle="collapse" :data-bs-target="'#c'+node.id" aria-expanded="false" :aria-controls="'c'+node.id">
            <i v-if="hasChildren" role="presentation" :id="'icon-'+node.id" class="ui-tree-toggler pi pi-fw ui-unselectable-text" :class="targetUser.id==node.id?'pi-caret-down':'pi-caret-right'"></i>
            <i v-else :id="'icon-'+node.id" role="presentation" class="ui-tree-toggler pi pi-fw ui-unselectable-text pi-caret-right" style="visibility: hidden;cursor: none;"></i>
          </span>
          <span style="font-weight: lighter;" :id="'name-'+node.id" :style="targetUser.id==node.id?'color: #ffc529 !important;':''" class="ui-treenode-icon flaticon2-user ng-star-inserted">
            <span class="ui-treenode-label" style="cursor: pointer;border-radius: 5px;font-family: 'Rubik', sans-serif !important;font-size: 14px !important;" @click="showChildrenCapital(node.id,node.user_name)">{{ node.user_name }}</span>
          </span>
        </div>
      <div v-if="hasChildren" class="collapse" style="padding-left: 10px;" :id="'c'+node.id" :class="main_tree.user_name==node.user_name?'show':''">
      <treeNode
          v-for="child in node.hijos"
          :key="child.id"
          :node="child"
          :startDate="startDate" :endDate="endDate" :startHour="startHour" :endHour="endHour" :provider="provider"
      />
      </div>
  </div>
</template>
<script>
import treeNode from './treeNode.vue';
export default {
  name: 'treeNode',
  components:{treeNode},
  props: ["node","startDate","endDate","startHour","endHour","provider"],
  computed: {
    main_tree() {
        return this.$store.getters["getMainTree"];
    },
    hasChildren() {
      return this.$props.node && this.$props.node.hijos.length > 0
    },
    user(){
      return this.$store.getters["getUserData"];
    },
    showHiddenTree(){
      return this.$store.getters["getShowHiddenTree"];
    },
    targetUser(){
      return this.$store.getters["getTargetUser"];
    },
    getActualView(){
      return this.$store.getters['getDashboardView']; 
    },
  },
  methods: {
    isExpanded(id){
      let element = document.getElementById('button-'+id);
      let icon = document.getElementById('icon-'+id)
      let name = document.getElementById('name-'+id)
      let container = document.getElementById('container-'+id)
      if(element?.getAttribute('aria-expanded') == 'true'){
        icon.classList.add('pi-caret-down')
        icon.classList.remove('pi-caret-right')
        name.style.color = '#ffc529';
        container.style.backgroundColor = '#626272'
      }
      else{
        icon.classList.remove('pi-caret-down')
        icon.classList.add('pi-caret-right')
        name.style.color = 'white'
        container.style.backgroundColor = '#3f3e4d'
      }
    },
    showChildrenCapital(id,name){
      if(this.getActualView=="users"){
        this.$store.dispatch("getChildrenTree",{id:id,name:name});
      }
      else if(this.getActualView=="reporte_general"){
        this.$store.dispatch("getReport",{
          startDate:this.$props.startDate,
          endDate:this.$props.endDate,
          startHour:this.$props.startHour+":00",
          endHour:this.$props.endHour+":00",
          id:{id:this.$props.node.id,name:this.$props.node.user_name}
        })
      }
      // else if(this.getActualView=="reporte_saldos"){
      //   this.$store.dispatch("getChildrenCapital",{id:id,name:name})
      // }
      else if(this.getActualView=="reporte_cargas"){
        this.$store.dispatch("getReportCargas",{
          startDate:this.$props.startDate,
          endDate:this.$props.endDate,
          startHour:this.$props.startHour+":00",
          endHour:this.$props.endHour+":00",
          id:{id:this.$props.node.id,name:this.$props.node.user_name}
        })
      }
      else if(this.getActualView=="reporteDeportes"){
        this.$store.dispatch("getReportDeportes",{
          startDate: this.$props.startDate+" "+this.$props.startHour+":00",
          endDate: this.$props.endDate+" "+this.$props.endHour+":00",
          id:{id:this.$props.node.id,name:this.$props.node.user_name}
        })
      }
      else if(this.getActualView=="reporteCasino" && this.$props.provider){
        this.$store.dispatch("getReportCasino",{
          startDate: this.$props.startDate+" "+this.$props.startHour+":00",
          endDate: this.$props.endDate+" "+this.$props.endHour+":00",
          id:{id:this.$props.node.id,name:this.$props.node.user_name},
          provider:this.$props.provider
        })
      }
      else if(this.getActualView=="reportePoker"){
        this.$store.dispatch("getReportPoker",{
          startDate: this.$props.startDate+" "+this.$props.startHour+":00",
          endDate: this.$props.endDate+" "+this.$props.endHour+":00",
          id:{id:this.$props.node.id,name:this.$props.node.user_name}
        })
      }
    }
  },
}
</script>
<style scoped>
.ui-treenode-label:hover {
    background-color: #eaeaea;
    color: #333;
}
</style>