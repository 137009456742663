/* eslint-disable */
<template>
    <div style="font-family: 'Rubik', sans-serif;">
        <div v-if="loading" class="freeze-ui" data-text=" " style="position: absolute;z-index:999999 !important"></div>
        <div class="kt-grid__item kt-grid__item--fluid kt-grid w-100"
            style="margin: 0px; padding: 0px; flex: 0 1 0%;">
            <div _ngcontent-lul-c1="" class="w-100 pl-0">
                <div _ngcontent-lul-c1="" class="ng-tns-c1-0"></div>
                <div class="ng-tns-c30-18 ng-star-inserted" style="">
                    <div class="kt-login__form ng-trigger ng-trigger-routerTransition"
                        style="padding-top: 0px; opacity: 1;">
                        <div class="kt-subheader kt-grid__item">
                            <div class="kt-subheader__main">
                                <h3 class="kt-subheader__title"><span class="ng-tns-c30-18" style="color:white">Agentes
                                    </span></h3><span
                                    class="kt-subheader__separator kt-subheader__separator--v"></span><span
                                    class="kt-subheader__desc"> Más ganadores/perdedores</span>
                            </div>
                        </div>
                    </div>
                </div><!---->
            </div>
            <!-- Reporte ---------------------------------------->
            <div class="row w-100 m-0 p-0">
                <div class="kt-content col-lg-9 col-sm-12 pr-0"
                    style="padding-right: unset !important;overflow:hidden;padding:0">
                    <div class="row resize" style="flex-direction: row;">
                        <div class="col-lg-12">
                            <div class="kt-portlet" style="opacity: 0.9;">
                                <div class="kt-portlet__head row">
                                    <div class="row align-itms-center pl-0 col-sm-12 mb-4 mt-4">
                                        <div class="col-12 mb-4"><span @click="setDateRanges('Hoy')"
                                                :class="selectedDate === 'Hoy' ? 'group-active' : ''"
                                                class="col-sm-3 d-inline btn btn-bold btn-font-sm btn-label-disabled"
                                                id="tgToday"> Hoy </span><span @click="setDateRanges('Ayer')"
                                                :class="selectedDate === 'Ayer' ? 'group-active' : ''"
                                                class="col-sm-3 d-inline  btn btn-bold btn-font-sm btn-label-disabled"
                                                id="tgYesterday"> Ayer </span><span
                                                @click="setDateRanges('Semana previa')"
                                                :class="selectedDate === 'Semana previa' ? 'group-active' : ''"
                                                class="col-sm-3 d-inline  btn btn-bold btn-font-sm btn-label-disabled"
                                                id="tgLastWeek"> Última Semana </span><span
                                                @click="setDateRanges('Mes previo')"
                                                :class="selectedDate === 'Mes previo' ? 'group-active' : ''"
                                                class="col-sm-3 d-inline  btn btn-bold btn-font-sm btn-label-disabled"
                                                id="tgLastMonth"> Mes Anterior </span><span
                                                @click="setDateRanges('Mes actual')"
                                                :class="selectedDate === 'Mes actual' ? 'group-active' : ''"
                                                class="col-sm-3 d-inline  btn btn-bold btn-font-sm btn-label-disabled"
                                                id="tgCurrMonth"> Mes Actual </span>
                                        </div>
                                        <div class="pl-3 col-md-6 col-sm-12">
                                            <div class="form-group"><label class="text-white" for="DateFrom">Fecha
                                                    Desde</label>
                                                <div class="row" style="margin-left:0">
                                                    <div class="col-4 px-1">
                                                        <DatePicker v-model="startDate" :language="es"
                                                            :format="'dd/MM/yyyy'"></DatePicker>
                                                    </div>
                                                    <input class="form-control col-4 ml-1 " v-model="startHour" required
                                                        step="1" type="time">
                                                    <div class="btn col-3 mt-n4 pt-3 " btnradiogroup=""
                                                        style="padding-left: unset !important;top:5px"><span
                                                            :class="dateType == 'local' ? 'active' : ''"
                                                            @click="dateType = 'local'"
                                                            class="btn btn-bold  btn-font-sm btn-label-disabled"
                                                            btnradio="local" id="local" aria-pressed="true"> Local
                                                        </span><span
                                                            class="btn btn-bold  btn-font-sm btn-label-disabled"
                                                            :class="dateType == 'utc' ? 'active' : ''"
                                                            @click="dateType = 'utc'" btnradio="utc" id="utc"
                                                            aria-pressed="false"> UTC
                                                        </span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="pl-3 col-md-6 col-sm-12">
                                            <div class="form-group"><label class="text-white" for="DateTo">Fecha
                                                    Hasta</label>
                                                <div class="row" style="margin-left:0">
                                                    <div class="col-4 px-1">
                                                        <DatePicker v-model="endDate" :language="es"
                                                            :format="'dd/MM/yyyy'">
                                                        </DatePicker>
                                                    </div>
                                                    <input class="form-control col-4 ml-1 " v-model="endHour" required
                                                        step="1" type="time">
                                                    <div class="btn col-3 mt-n4 pt-3 " btnradiogroup=""
                                                        style="padding-left: unset !important;top:5px"><span
                                                            :class="dateType == 'local' ? 'active' : ''"
                                                            @click="dateType = 'local'"
                                                            class="btn btn-bold  btn-font-sm btn-label-disabled"
                                                            btnradio="local" id="local" aria-pressed="true"> Local
                                                        </span><span
                                                            class="btn btn-bold  btn-font-sm btn-label-disabled"
                                                            :class="dateType == 'utc' ? 'active' : ''"
                                                            @click="dateType = 'utc'" btnradio="utc" id="utc"
                                                            aria-pressed="false"> UTC
                                                        </span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label class="text-white ml-2" for="ngSelectUsers">Jugador</label>
                                                <input class="form-control m-input ng-pristine ng-valid ng-touched"
                                                    v-model="username" autofocus="" id="selectedUserId" maxlength="20"
                                                    name="filterText" type="text" placeholder="Buscar...">
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label class="text-white ml-2"
                                                    for="ngSelectDirectOptions">Mostrar</label>
                                                <div class="custom-w3-select">
                                                    <select id="ngSelectDirectOptions" class="form-select"
                                                        v-model="selectedTarget" style="border-radius: 5px;">
                                                        <option value="Todos">Todos</option>
                                                        <option value="Directos">Solo directos
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label class="text-white ml-2"
                                                    for="ngSelectDirectOptions2">Ordenar por</label>
                                                <div class="custom-w3-select">
                                                    <select id="ngSelectDirectOptions2" class="form-select"
                                                        v-model="orderItemsBy" style="border-radius: 5px;">
                                                        <option value="desc">Mas ganadores</option>
                                                        <option value="asc">Mas perdedores
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-n3 pt-1 col-12" style="padding:0 0.5rem;"><span class="ng-tns-c30-18"
                                            :class="showLess ? 'show-less' : ''"
                                            style="font-size: 11.7px !important;color: #FFC529;width:80% !important">
                                            La zona horaria local toma en consideración la fecha y hora que tiene
                                            configurada en su dispositivo. La zona horaria UTC 00:00 es un estandar
                                            de hora universal.
                                            Si selecciona la opción "Local" será tomado en cuenta el horario de s1u
                                            pais, y si selecciona la opción "UTC" será tomado en cuenta el horario
                                            estandar universal. </span><a class="ng-tns-c30-18" href="javascript:;"
                                            @click="showLess = !showLess"
                                            style="font-size: 11.7px !important;color: #FFC529 !important;text-decoration: underline; display:inline;float:right">
                                            {{ showLess ? 'Mostrar más' : 'Mostrar menos' }} </a><br
                                            class="ng-tns-c30-18">
                                    </div>
                                    <div class="col-md-4 col-sm-6 pl-0 mb-3"><button class="col-sm-12 btn btn-primary"
                                            @click="getReport" id="btn_search" type="button"><i class="la la-search"
                                                style="width: 20px;"></i> Buscar</button></div>
                                </div>
                                <div class="kt-portlet__body">
                                    <div class="row align-items-center ng-tns-c28-16 ng-star-inserted" style="">
                                        <div class="primeng-datatable-container"><div class="ng-tns-c28-16"
                                                scrollwidth="100%" rows="10">
                                                <div class="ui-table ui-widget ui-table-responsive">
                                                    <!----><!----><!----><!----><!----><!---->
                                                    <div class="ui-table-scrollable-wrapper ng-star-inserted"><!---->
                                                        <div class="ui-table-scrollable-view">
                                                            <div class="ui-table-scrollable-header ui-widget-header">
                                                                <div class="ui-table-scrollable-header-box"
                                                                    style="margin-right: 0px;">
                                                                    <table class="ui-table-scrollable-header-table">
                                                                        <!---->
                                                                        <thead class="ui-table-thead"><!----><tr class="ng-tns-c24-5 ng-star-inserted"><th class="ui-sortable" style="text-align: center;"></th><th class="ui-sortable" colspan="3" style="text-align: center;"> Total </th><th class="ui-sortable" colspan="3" style="text-align: center;"> Casino </th><th class="ui-sortable" colspan="3" style="text-align: center;"> Casino Vivo </th><th class="ui-sortable" colspan="3" style="text-align: center;"> Póker </th><th class="ui-sortable" colspan="3" style="text-align: center;"> Deportes </th></tr><tr class="ng-tns-c24-5 ng-star-inserted"><th class="ui-sortable" style="white-space: nowrap;"> Jugador </th><th class="ui-sortable" style="white-space: nowrap;"> Apost. </th><th class="ui-sortable" style="white-space: nowrap;"> Ganado </th><th class="ui-sortable" style="white-space: nowrap;"> GGR </th><th class="ui-sortable" style="white-space: nowrap;"> Apost </th><th class="ui-sortable" style="white-space: nowrap;"> Ganado </th><th class="ui-sortable" style="white-space: nowrap;"> GGR </th><th class="ui-sortable" style="white-space: nowrap;"> Apost </th><th class="ui-sortable" style="white-space: nowrap;"> Ganado </th><th class="ui-sortable" style="white-space: nowrap;"> GGR </th><th class="ui-sortable" style="white-space: nowrap;"> Apost. </th><th class="ui-sortable" style="white-space: nowrap;"> Ganado </th><th class="ui-sortable" style="white-space: nowrap;"> GGR </th><th class="ui-sortable" style="white-space: nowrap;"> Apost. </th><th class="ui-sortable" style="white-space: nowrap;"> Ganado </th><th class="ui-sortable" style="white-space: nowrap;"> GGR </th></tr></thead>
                                                                        <tbody class="ui-table-tbody"><!----></tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div class="ui-table-scrollable-body">
                                                                <table class="ui-table-scrollable-body-table"><!---->
                                                                    <tbody class="ui-table-tbody">
                                                                        <!----><!----><!----><!---->
                                                                        <tr class="ng-tns-c28-16 ng-star-inserted"
                                                                            style="font-size: 11px !important;" v-for="(item, index) in paginarArray(reportDataArray, registers_per_page)[actual_page - 1]"
                                                                            :key="index">
                                                                            <td class="ng-tns-c28-16"><span class="ui-column-title">Jugador</span><span
                                                                                    class="">{{ item.username }}
                                                                                </span></td>
                                                                            <td class="ng-tns-c28-16"
                                                                                style="text-align: center;"><span class="ui-column-title">Total Apostado</span><span
                                                                                    class="">{{ parseNumbers(item.bet ||0) }}
                                                                                </span> </td>
                                                                            <td class="ng-tns-c28-16"
                                                                                style="text-align: center;"><span class="ui-column-title">Total Ganado</span><span
                                                                                    class="">{{ parseNumbers(item.win ||0) }}
                                                                                </span> </td>
                                                                            <td class="ng-tns-c28-16"
                                                                                style="text-align: center;"><span class="ui-column-title">Total GGR</span><span
                                                                                    class="">{{ parseNumbers(item.netwin / item.data.length) }}
                                                                                </span> </td>
                                                                            <td class="ng-tns-c28-16"
                                                                                style="text-align: center;"><span class="ui-column-title">Casino Apostado</span><span
                                                                                    class="">{{ parseNumbers(item.data.find(game=>game.proveedor == 'Casino')?.bet_amount || 0) }}
                                                                                </span> </td>
                                                                            <td class="ng-tns-c28-16"
                                                                                style="text-align: center;"><span class="ui-column-title">Casino Ganado</span><span
                                                                                    class="">{{ parseNumbers(item.data.find(game=>game.proveedor == 'Casino')?.win_amount || 0) }}
                                                                                </span> </td>
                                                                            <td class="ng-tns-c28-16"
                                                                                style="text-align: center;"><span class="ui-column-title">Casino GGR</span><span
                                                                                    class="">{{ parseNumbers(item.data.find(game=>game.proveedor == 'Casino')?.netwin || 0) }}
                                                                                </span> </td>
                                                                            <td class="ng-tns-c28-16"
                                                                                style="text-align: center;"><span class="ui-column-title">Casino Vivo Apostado</span><span
                                                                                    class="">{{ parseNumbers(item.data.find(game=>game.proveedor == 'Casino vivo')?.bet_amount || 0) }}
                                                                                </span> </td>
                                                                            <td class="ng-tns-c28-16"
                                                                                style="text-align: center;"><span class="ui-column-title">Casino Vivo Ganado</span><span
                                                                                    class="">{{ parseNumbers(item.data.find(game=>game.proveedor == 'Casino vivo')?.win_amount || 0) }}
                                                                                </span> </td>
                                                                            <td class="ng-tns-c28-16"
                                                                                style="text-align: center;"><span class="ui-column-title">Casino Vivo GGR</span><span
                                                                                    class="">{{ parseNumbers(item.data.find(game=>game.proveedor == 'Casino vivo')?.netwin || 0) }}
                                                                                </span> </td>
                                                                            <td class="ng-tns-c28-16"
                                                                                style="text-align: center;"><span class="ui-column-title">Poker Apostado</span><span
                                                                                    class="">{{ parseNumbers(item.data.find(game=>game.proveedor == 'Poker')?.bet_amount || 0) }}
                                                                                </span> </td>
                                                                            <td class="ng-tns-c28-16"
                                                                                style="text-align: center;"><span class="ui-column-title">Poker Ganado</span><span
                                                                                    class="">{{ parseNumbers(item.data.find(game=>game.proveedor == 'Poker')?.win_amount || 0) }}
                                                                                </span> </td>
                                                                            <td class="ng-tns-c28-16"
                                                                                style="text-align: center;"><span class="ui-column-title">Poker GGR</span><span
                                                                                    class="">{{ parseNumbers(item.data.find(game=>game.proveedor == 'Poker')?.netwin || 0) }}
                                                                                </span> </td>
                                                                            <td class="ng-tns-c28-16"
                                                                                style="text-align: center;"><span class="ui-column-title">Deportes Apostado</span><span
                                                                                    class="">{{ parseNumbers(item.data.find(game=>game.proveedor == 'Deportivas')?.bet_amount || 0) }}
                                                                                </span> </td>
                                                                            <td class="ng-tns-c28-16"
                                                                                style="text-align: center;"><span class="ui-column-title">Deportes Ganado</span><span
                                                                                    class="">{{ parseNumbers(item.data.find(game=>game.proveedor == 'Deportivas')?.win_amount || 0) }}
                                                                                </span> </td>
                                                                            <td class="ng-tns-c28-16"
                                                                                style="text-align: center;"><span class="ui-column-title">Deportes GGR</span><span
                                                                                    class="">{{ parseNumbers(item.data.find(game=>game.proveedor == 'Deportivas')?.netwin || 0) }}
                                                                                </span> </td>
                                                                        </tr><!----><!----><!----><!---->
                                                                    </tbody>
                                                                </table><!----><!---->
                                                            </div><!---->
                                                        </div>
                                                    </div><!----><!----><!----><!----><!---->
                                                </div>
                                            </div><div class="ng-tns-c28-16">
                                                <div class="ui-table ui-widget"><!----><!----><!----><!----><!---->
                                                    <div class="ui-table-wrapper ng-star-inserted">
                                                        <table><!---->
                                                            <thead class="ui-table-thead"><!----></thead><!---->
                                                            <tfoot class="ui-table-tfoot ng-star-inserted">
                                                                <!----><!---->
                                                                <tr class="ng-tns-c23-7 ng-star-inserted ng-tns-c28-16"
                                                                    style="font-size: 11px !important;background: #80808038;color: white;">
                                                                    <td class="ng-tns-c23-7"><span
                                                                            class="">TOTALES</span></td>
                                                                    <td class="ng-tns-c23-7" style="text-align: right;">
                                                                        {{ parseNumbers(totalizar(reportDataArray.map(item=>item.bet || 0))) }}</td>
                                                                    <td class="ng-tns-c23-7" style="text-align: right;">
                                                                        {{ parseNumbers(totalizar(reportDataArray.map(item=>item.win || 0))) }}</td>
                                                                    <td class="ng-tns-c23-7" style="text-align: right;">
                                                                        {{ parseNumbers(totalizar(reportDataArray.map(item=>item.netwin || 0)) / reportDataArray.length) }}</td>
                                                                    <td class="ng-tns-c23-7" style="text-align: right;">
                                                                        {{ parseNumbers(totalizar(reportDataArray.map(item=>item.data.find(game => game.proveedor === 'Casino')?.bet_amount || false))) }}</td>
                                                                    <td class="ng-tns-c23-7" style="text-align: right;">
                                                                        {{ parseNumbers(totalizar(reportDataArray.map(item=>item.data.find(game => game.proveedor === 'Casino')?.win_amount || false))) }}</td>
                                                                    <td class="ng-tns-c23-7" style="text-align: right;">
                                                                        {{ parseNumbers(totalizar(reportDataArray.map(item=>item.data.find(game => game.proveedor === 'Casino')?.netwin || false)) ) }}</td>
                                                                    <td class="ng-tns-c23-7" style="text-align: right;">
                                                                        {{ parseNumbers(totalizar(reportDataArray.map(item=>item.data.find(game => game.proveedor === 'Casino vivo')?.bet_amount || false))) }}</td>
                                                                    <td class="ng-tns-c23-7" style="text-align: right;">
                                                                        {{ parseNumbers(totalizar(reportDataArray.map(item=>item.data.find(game => game.proveedor === 'Casino vivo')?.win_amount || false))) }}</td>
                                                                    <td class="ng-tns-c23-7" style="text-align: right;">
                                                                        {{ parseNumbers(totalizar(reportDataArray.map(item=>item.data.find(game => game.proveedor === 'Casino vivo')?.netwin || false)) ) }}</td>
                                                                    <td class="ng-tns-c23-7" style="text-align: right;">
                                                                        {{ parseNumbers(totalizar(reportDataArray.map(item=>item.data.find(game => game.proveedor === 'Poker')?.bet_amount || false))) }}</td>
                                                                    <td class="ng-tns-c23-7" style="text-align: right;">
                                                                        {{ parseNumbers(totalizar(reportDataArray.map(item=>item.data.find(game => game.proveedor === 'Poker')?.win_amount || false))) }}</td>
                                                                    <td class="ng-tns-c23-7" style="text-align: right;">
                                                                        {{ parseNumbers(totalizar(reportDataArray.map(item=>item.data.find(game => game.proveedor === 'Poker')?.netwin || false)) ) }}</td>
                                                                    <td class="ng-tns-c23-7" style="text-align: right;">
                                                                        {{ parseNumbers(totalizar(reportDataArray.map(item=>item.data.find(game => game.proveedor === 'Deportivas')?.bet_amount || false))) }}</td>
                                                                    <td class="ng-tns-c23-7" style="text-align: right;">
                                                                        {{ parseNumbers(totalizar(reportDataArray.map(item=>item.data.find(game => game.proveedor === 'Deportivas')?.win_amount || false))) }}</td>
                                                                    <td class="ng-tns-c23-7" style="text-align: right;">
                                                                        {{ parseNumbers(totalizar(reportDataArray.map(item=>item.data.find(game => game.proveedor === 'Deportivas')?.netwin || false)) ) }}</td>
                                                                </tr>
                                                            </tfoot>
                                                            <tbody class="ui-table-tbody">
                                                                <!----><!----><!----><!----><!----><!----><!----><!----><!---->
                                                            </tbody>
                                                        </table>
                                                    </div><!----><!----><!----><!----><!----><!---->
                                                </div>
                                            </div><!---->
                                            <div class="bottom row">
                                                <div class="col-12 col-md-6">

                                                </div>
                                                <div
                                                    class="dataTables_paginate paging_simple_numbers col-12 col-md-6 d-flex">
                                                    <div class="me-1 w-100">
                                                        <pagination :options="{ texts: { count: '' } }"
                                                            style="float: inline-end;" v-model="actual_page"
                                                            :records="records" :per-page="registers_per_page"
                                                            @paginate="paginate" />
                                                    </div>
                                                    <select id="registersPerPageSelect" name="users_length"
                                                        aria-controls="users" @change="setRegistersPerPage()"
                                                        class="form-select" style="max-width:60px;">
                                                        <option value="10">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ARBOL ---------------------------------------->
                <div class="col-lg-3 col-sm-12 pr-0 pl-0" v-if="screenWidth > 1000">
                    <div class="w-100">
                        <div id="treeNode" class="px-2">
                            <div class="kt-portlet"
                                style="opacity: 0.9;border: 1px solid #494141;margin-bottom: unset !important;">
                                <div class="kt-portlet__head" style="background-color: #15172B !important;">
                                    <div class="kt-portlet__head-label">
                                        <h3 class="kt-font-light kt-font-md kt-portlet__head-title text-uppercase">
                                            <span>Agentes</span>
                                        </h3>
                                    </div>
                                </div>
                                <div class="tree kt-content px-1">
                                    <treeNode :node="main_tree" :startDate="getFechaFormated(startDate)"
                                        :endDate="getFechaFormated(endDate)" :startHour="startHour"
                                        :endHour="endHour" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="offcanvas offcanvas-start p-0" tabindex="-1" id="offcanvasTree" aria-labelledby="offcanvasTreeLabel" style="max-width: 70%;background-color: #15172B !important;">
                    <div class="offcanvas-header p-2">
                        <h5 class="offcanvas-title" id="offcanvasTreeLabel" style="color: white;">Agentes</h5>
                        <button style="background-color: rgba(255, 255, 255, 0.2);color:white" class="close btn btn-sm px-2" type="button" data-bs-dismiss="offcanvas" aria-label="Close"><span aria-hidden="true">×</span></button>
                    </div>
                    <div class="offcanvas-body p-0">
                        <treeNode :node="main_tree" :startDate="getFechaFormated(startDate)"
                                        :endDate="getFechaFormated(endDate)" :startHour="startHour"
                                        :endHour="endHour" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import treeNode from './treeNode.vue';
import Pagination from 'vue-pagination-2';
import DatePicker from '../datePicker/components/Datepicker'
import { es } from '@sum.cumo/vue-datepicker/dist/locale/index.esm'
export default {
    name: 'reporteGanadoresPerdedores',
    components: { treeNode, DatePicker, Pagination },
    data() {
        return {
            selectedTarget: "Todos",
            reportData: [],
            orderItemsBy: 'desc',
            actual_page: 1,
            registers_per_page: 10,
            startDate: "",
            startHour: "",
            selectedDate: "Hoy",
            endDate: "",
            endHour: "",
            username: "",
            refId: "",
            pages: [],
            records: 0,
            dateType: 'local',
            es: es,
            showLess: true,
            loading: false
        }
    },
    props: ["view_type","screenWidth"],
    computed: {
        main_tree() {
            return this.$store.getters["getMainTree"];
        },
        targetUser() {
            return this.$store.getters["getTargetUser"];
        },
        reportDataArray() {
            let maindata = this.reportData;
            if (this.username !== '') {
                return maindata.filter(user => user.username.toLowerCase().includes(this.username.toLowerCase()))
            }
            if (this.orderItemsBy === 'asc') {
                return maindata.sort((a, b) => a.win - b.win);
            }
            return maindata.sort((a, b) => b.win - a.win);
        }
    },
    methods: {
        totalizar(numbers) {
            return numbers.reduce((a, b) => (a ?? 0) + (b ?? 0), 0);
        },
        setTargetMovement(item) {
            this.targetMovement = item
            this.openModal('balancesModal')
        },
        paginate(page) {
            this.actual_page = page;
        },
        parseRole(role) {
            if (role) {
                let role_name = role.split('"')[1].split("_")[1];
                if (role_name == "PROMOTOR") {
                    return "Agente";
                }
                else if (role_name == "APOSTADOR") {
                    return "Jugador";
                }
                else {
                    return role_name
                }
            }
        },
        openModal(target) {
            try {
                this.backdrop(true);
                let modal = document.getElementById(target);
                modal.classList.add("show");
                modal.style.display = "block";
            } catch (error) {
                console.log(error);
            }
        },
        backdrop(action) {
            if (action) {
                document.getElementById("modalBackdrop").style.display = "block";
                document.getElementById("modalBackdrop").classList.add("show");
            }
            else {
                document.getElementById("modalBackdrop").style.display = "none";
                document.getElementById("modalBackdrop").classList.remove("show");
            }
        },
        closeModal() {
            let modals = document.getElementsByClassName("modal");
            [...modals].forEach(modal => {
                modal.classList.remove("show");
                modal.style.display = "none";
            })
            this.targetMovement = {}
            document.getElementById("modalBackdrop").style.display = "none";
            document.getElementById("modalBackdrop").classList.remove("show");
        },
        parseNumbers(number) {
            return new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number)
        },
        showHiddenTree() {
            let checked = document.getElementById("TreeShowHidden").checked;
            this.$store.dispatch("showHiddenTree", checked);
        },
        formatearFecha(fechaString) {
            const fecha = new Date(fechaString);
            const anio = fecha.getFullYear();
            const mes = this.agregarCeroDelante(fecha.getMonth() + 1);
            const dia = this.agregarCeroDelante(fecha.getDate());
            const Hora = this.agregarCeroDelante(fecha.getHours());
            const Minutos = this.agregarCeroDelante(fecha.getMinutes());
            const Segundos = this.agregarCeroDelante(fecha.getSeconds());
            return `${anio}/${mes}/${dia} ${Hora}:${Minutos}:${Segundos}`;
        },
        agregarCeroDelante(numero) {
            return numero < 10 ? '0' + numero : numero;
        },
        getFechaFormated(fecha) {
            fecha = new Date(fecha);
            var año = fecha.getFullYear();
            var mes = ('0' + (fecha.getMonth() + 1)).slice(-2);
            var dia = ('0' + fecha.getDate()).slice(-2);
            var cadenaFecha = año + '-' + mes + '-' + dia;
            return cadenaFecha
        },
        getFecha(date) {
            return date
        },
        setDateRanges(range) {
            let hoy = new Date()
            this.selectedDate = range;
            if (range == "Hoy") {
                const tomorrow = new Date(hoy);
                tomorrow.setDate(tomorrow.getDate() + 1);
                this.startDate = this.getFecha(hoy);
                this.startHour = "00:00:00"
                this.endDate = this.getFecha(tomorrow);
                this.endHour = "00:00:00"
            }
            else if (range == "Ayer") {
                let ayer = new Date(hoy);
                ayer.setDate(ayer.getDate() - 1);
                this.startDate = this.getFecha(ayer);
                this.endDate = this.getFecha(hoy);
                this.startHour = "00:00:00"
                this.endHour = "00:00:00"
            }
            else if (range == "Semana previa") {
                const diaDeLaSemana = hoy.getDay();
                const diasParaRetroceder = diaDeLaSemana === 0 ? 6 : diaDeLaSemana - 1;
                const lunesSemanaAnterior = new Date(hoy);
                lunesSemanaAnterior.setDate(hoy.getDate() - (diasParaRetroceder + 7));
                const fechasSemanaPrevia = [];
                for (let i = 0; i < 8; i++) {
                    const fecha = new Date(lunesSemanaAnterior);
                    fecha.setDate(lunesSemanaAnterior.getDate() + i);
                    fechasSemanaPrevia.push(fecha);
                }
                this.startDate = this.getFecha(fechasSemanaPrevia[0]);
                this.endDate = this.getFecha(fechasSemanaPrevia[7]);
                this.startHour = "00:00:00"
                this.endHour = "00:00:00"
            }
            else if (range == "Semana actual") {
                const primerDiaSemana = new Date(hoy);
                primerDiaSemana.setDate(hoy.getDate() - (hoy.getDay() === 0 ? 7 : hoy.getDay()));
                // Paso 3: Obtener el rango de fechas de la semana
                const rangoSemana = [];
                for (let i = 0; i < 9; i++) {
                    const fecha = new Date(primerDiaSemana);
                    fecha.setDate(primerDiaSemana.getDate() + i);
                    rangoSemana.push(fecha);
                }
                this.startDate = this.getFecha(rangoSemana[1]);
                this.endDate = this.getFecha(rangoSemana[8]);
                this.startHour = "00:00:00"
                this.endHour = "00:00:00"
            }
            else if (range == "Mes actual") {
                const primerDiaMes = new Date(hoy.getFullYear(), hoy.getMonth(), 1);
                const tomorrow = new Date(hoy);
                tomorrow.setDate(tomorrow.getDate() + 1);
                this.startDate = this.getFecha(primerDiaMes);
                this.endDate = this.getFecha(tomorrow);
                this.startHour = "00:00:00"
                this.endHour = "00:00:00"
            }
            else if (range == "Mes previo") {
                const primerDiaMesAnterior = new Date(hoy);
                const primerdiaMesActual = new Date(hoy);
                primerDiaMesAnterior.setMonth(hoy.getMonth() - 1);
                primerDiaMesAnterior.setDate(1);
                primerdiaMesActual.setDate(1);
                this.startDate = this.getFecha(primerDiaMesAnterior);
                this.endDate = this.getFecha(primerdiaMesActual);
                this.startHour = "00:00:00"
                this.endHour = "00:00:00"
            }
            // this.getReport();
        },
        async getReport() {
            try {
                this.actual_page = 1;
                this.loading = true;
                const timeZoneOffset = new Date().getTimezoneOffset() / 60;
                const timezoneFormated = ((Math.abs(timeZoneOffset) < 9 ? ((-1 * (timeZoneOffset)) < 0 ? '-0' + timeZoneOffset : '0' + timeZoneOffset) : (-1 * (timeZoneOffset)))) + ":00";
                let response = await axios.post(process.env.VUE_APP_DATA_SERVER_URL + "/detalle_producto", {
                    "login_Id": this.main_tree.id,
                    "startDate": this.getFechaFormated(this.startDate) + " " + this.startHour,
                    "endDate": this.getFechaFormated(this.endDate) + " " + this.endHour,
                    "timezone": timezoneFormated
                })
                if (response.data) {
                    const groupedData = this.groupBy(response.data, 'username');
                    const groupedArray = Object.keys(groupedData).map(key => ({
                        username: key,
                        data: groupedData[key],
                        bet: this.totalizar(groupedData[key].map(item=>item.bet_amount)),
                        win: this.totalizar(groupedData[key].map(item=>item.win_amount)),
                        netwin: this.totalizar(groupedData[key].map(item=>item.netwin)),
                    }));
                    this.reportData = groupedArray;
                }
                this.loading = false;
            } catch (error) {
                this.loading = false;
                console.log(error);
            }
        },
        groupBy(array, key){
            return array.reduce((result, obj) => {
                (result[obj[key]] = result[obj[key]] || []).push(obj);
                return result;
            }, {});
        },
        paginarArray(array, elementosPorPagina) {
            const paginas = [];
            let paginaActual = [];

            for (let i = 0; i < array.length; i++) {
                if (paginaActual.length === elementosPorPagina) {
                    paginas.push(paginaActual);
                    paginaActual = [];
                }
                paginaActual.push(array[i]);
            }

            if (paginaActual.length > 0) {
                paginas.push(paginaActual);
            }
            this.records = array.length;
            this.pages = paginas;
            return paginas;
        },
        showMaxRegisterSelection() {
            document.getElementById("selectPage").style.display = "block";
        },
        setRegistersPerPage() {
            const pages = parseInt(document.getElementById('registersPerPageSelect').value)
            this.registers_per_page = pages;
        },
        changePage(page) {
            this.actual_page = page
        }
    },
    watch: {
        targetUser(newVal) {
            if (newVal.id && !this.firstLoadReport) {
                this.getReport()
                this.firstLoadReport = true
            }
        },
        username(){
            this.actual_page = 1
        }
    },
    mounted() {
        if (this.selectedDate == "Hoy") {
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            this.startDate = this.getFecha(new Date());
            this.startHour = "00:00:00"
            this.endDate = this.getFecha(tomorrow);
            this.endHour = "00:00:00"
        }
    },
}
</script>
<style scoped>
.ui-table .ui-table-thead>tr>th {
    color: #dce7f9;
    background-color: #24284b;
}
</style>