<template>
  <div id="app">
    <router-view>
    </router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
@import './assets/styles.css';
@import './assets/css/dark.css';
@import './assets/css/dark\(1\).css';
@import './assets/css/light.css';
@import './assets/css/metronic-customize.css';
@import './assets/css/metronic-customize\(1\).css';
@import './assets/css/primeng.datatable.css';
@import './assets/css/restylish.css';
@import './assets/css/style.bundle.css';
@import './assets/css/styles.bc9f38b360af560b1da3.css';
@import './assets/css/restylish.css';
@import './assets/css/style.min.css';
@import './assets/css/additional.css';
@import './assets/css/Datepicker.css';
#app, body {
  background-color: #15172B;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.accordion-button:after { 
    background-image: url("data:image/svg+xml,<svg width='25px' height='25px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }
</style>
