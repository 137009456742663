/* eslint-disable */
<template>
    <div style="font-family: 'Rubik', sans-serif;">
        <span style="display: none;">{{ cuentaCorrienteTarget }}</span>
        <div v-if="loading" class="freeze-ui" data-text=" " style="position: absolute;z-index:999999 !important"></div>
        <div class="kt-grid__item kt-grid__item--fluid kt-grid w-100"
            style="margin: 0px; padding: 0px; flex: 0 1 0%;">
            <div _ngcontent-lul-c1="" class="w-100 pl-0">
                <div _ngcontent-lul-c1="" class="ng-tns-c1-0"></div>
                <div class="ng-tns-c30-18 ng-star-inserted" style="">
                    <div class="kt-login__form ng-trigger ng-trigger-routerTransition"
                        style="padding-top: 0px; opacity: 1;">
                        <div class="kt-subheader kt-grid__item">
                            <div class="kt-subheader__main">
                                <h3 class="kt-subheader__title"><span class="ng-tns-c30-18" style="color:white">Agentes
                                    </span></h3><span
                                    class="kt-subheader__separator kt-subheader__separator--v"></span><span
                                    class="kt-subheader__desc"> Reporte de Depósitos y Retiros (CAJA) </span>
                            </div>
                        </div>
                    </div>
                </div><!---->
            </div>
            <!-- Reporte ---------------------------------------->
            <div class="row w-100 m-0 p-0">
                <div class="kt-content col-lg-9 col-sm-12 pr-0"
                    style="padding-right: unset !important;overflow:hidden;padding:0">
                    <div class="row resize" style="flex-direction: row;">
                        <div class="col-lg-12">
                            <div class="kt-portlet" style="opacity: 0.9;">
                                <div class="kt-portlet__head row">
                                    <div class="row align-itms-center pl-0 col-sm-12 mb-4 mt-4">
                                        <div class="col-12 mb-4"><span @click="setDateRanges('Hoy')"
                                                :class="selectedDate === 'Hoy' ? 'group-active' : ''"
                                                class="col-sm-3 d-inline btn btn-bold btn-font-sm btn-label-disabled"
                                                id="tgToday"> Hoy </span><span @click="setDateRanges('Ayer')"
                                                :class="selectedDate === 'Ayer' ? 'group-active' : ''"
                                                class="col-sm-3 d-inline  btn btn-bold btn-font-sm btn-label-disabled"
                                                id="tgYesterday"> Ayer </span><span
                                                @click="setDateRanges('Semana previa')"
                                                :class="selectedDate === 'Semana previa' ? 'group-active' : ''"
                                                class="col-sm-3 d-inline  btn btn-bold btn-font-sm btn-label-disabled"
                                                id="tgLastWeek"> Última Semana </span><span
                                                @click="setDateRanges('Mes previo')"
                                                :class="selectedDate === 'Mes previo' ? 'group-active' : ''"
                                                class="col-sm-3 d-inline  btn btn-bold btn-font-sm btn-label-disabled"
                                                id="tgLastMonth"> Mes Anterior </span><span
                                                @click="setDateRanges('Mes actual')"
                                                :class="selectedDate === 'Mes actual' ? 'group-active' : ''"
                                                class="col-sm-3 d-inline  btn btn-bold btn-font-sm btn-label-disabled"
                                                id="tgCurrMonth"> Mes Actual </span>
                                        </div>
                                        <div class="pl-3 col-md-4 col-sm-12">
                                            <div class="form-group"><label class="text-white" for="DateFrom">Fecha
                                                    Desde</label>
                                                <div class="row" style="margin-left:0">
                                                    <div class="col-4 px-1">
                                                        <DatePicker v-model="startDate" :language="es"
                                                            :format="'dd/MM/yyyy'"></DatePicker>
                                                    </div>
                                                    <input class="form-control col-4 ml-1 " v-model="startHour" required
                                                        step="1" type="time">
                                                    <div class="btn col-3 mt-n4 pt-3 " btnradiogroup=""
                                                        style="padding-left: unset !important;top:5px"><span
                                                            :class="dateType == 'local' ? 'active' : ''"
                                                            @click="dateType = 'local'"
                                                            class="btn btn-bold  btn-font-sm btn-label-disabled"
                                                            btnradio="local" id="local" aria-pressed="true"> Local
                                                        </span><span
                                                            class="btn btn-bold  btn-font-sm btn-label-disabled"
                                                            :class="dateType == 'utc' ? 'active' : ''"
                                                            @click="dateType = 'utc'" btnradio="utc" id="utc"
                                                            aria-pressed="false"> UTC
                                                        </span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="pl-3 col-md-4 col-sm-12">
                                            <div class="form-group"><label class="text-white" for="DateTo">Fecha
                                                    Hasta</label>
                                                <div class="row" style="margin-left:0">
                                                    <div class="col-4 px-1">
                                                        <DatePicker v-model="endDate" :language="es"
                                                            :format="'dd/MM/yyyy'">
                                                        </DatePicker>
                                                    </div>
                                                    <input class="form-control col-4 ml-1 " v-model="endHour" required
                                                        step="1" type="time">
                                                    <div class="btn col-3 mt-n4 pt-3 " btnradiogroup=""
                                                        style="padding-left: unset !important;top:5px"><span
                                                            :class="dateType == 'local' ? 'active' : ''"
                                                            @click="dateType = 'local'"
                                                            class="btn btn-bold  btn-font-sm btn-label-disabled"
                                                            btnradio="local" id="local" aria-pressed="true"> Local
                                                        </span><span
                                                            class="btn btn-bold  btn-font-sm btn-label-disabled"
                                                            :class="dateType == 'utc' ? 'active' : ''"
                                                            @click="dateType = 'utc'" btnradio="utc" id="utc"
                                                            aria-pressed="false"> UTC
                                                        </span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-12">
                                            <div class="form-group">
                                                <label class="text-white ml-2" for="ngSelectDirectOptions">Buscar
                                                    en:</label>
                                                <div class="custom-w3-select">
                                                    <select id="ngSelectDirectOptions" class="" v-model="selectedTarget"
                                                        style="border-radius: 5px;">
                                                        <option value="Jugadores" selected="selected">Jugadores</option>
                                                        <option value="Agentes" @click="setTarget('Agentes')">Agentes
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div><!---->
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label class="text-white ml-2" for="ngSelectUsers">Usuario</label>
                                                <input class="form-control m-input ng-pristine ng-valid ng-touched"
                                                    v-model="username" autofocus="" id="selectedUserId" maxlength="20"
                                                    name="filterText" type="text" placeholder="Buscar...">
                                            </div>
                                        </div>
                                        <div class="col-md-2 mt-2 ml-3">
                                            <div class="form-group">
                                                <div class="row">
                                                    <label class="kt-checkbox" for="ngDirectos">
                                                        <input class="ng-tns-c26-6 ng-untouched ng-pristine ng-valid"
                                                        v-model="directos" id="ngDirectos" name="chbDirectos"
                                                            type="checkbox">
                                                        <div class="text-white">Solo directos</div>
                                                        <span class="ng-tns-c26-6"></span>
                                                    </label>
                                                </div>
                                                <div class="row">
                                                    <label class="kt-checkbox" for="ngSuperior">
                                                        <input class="ng-tns-c26-6 ng-untouched ng-pristine ng-valid"
                                                            v-model="superiores" id="ngSuperior" name="chbSuperior"
                                                            type="checkbox">
                                                        <div class="text-white">Superior </div>
                                                        <span class="ng-tns-c26-6"></span>
                                                    </label>
                                                </div><!---->
                                                <div class="row ng-tns-c26-6 ng-star-inserted" style=""
                                                    v-if="selectedTarget == 'Agentes'">
                                                    <label class="kt-checkbox" for="ngReceived">
                                                        <input class="ng-tns-c26-6 ng-untouched ng-pristine ng-valid"
                                                            id="ngReceived" name="chbReceived" type="checkbox"
                                                            v-model="recibidas">
                                                        <div class="text-white"> Recibidas </div>
                                                        <span class="ng-tns-c26-6"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-n3 pt-1 col-12" style="padding:0 0.5rem;"><span class="ng-tns-c30-18"
                                            :class="showLess ? 'show-less' : ''"
                                            style="font-size: 11.7px !important;color: #FFC529;width:80% !important">
                                            La zona horaria local toma en consideración la fecha y hora que tiene
                                            configurada en su dispositivo. La zona horaria UTC 00:00 es un estandar
                                            de hora universal.
                                            Si selecciona la opción "Local" será tomado en cuenta el horario de s1u
                                            pais, y si selecciona la opción "UTC" será tomado en cuenta el horario
                                            estandar universal. </span><a class="ng-tns-c30-18" href="javascript:;"
                                            @click="showLess = !showLess"
                                            style="font-size: 11.7px !important;color: #FFC529 !important;text-decoration: underline; display:inline;float:right">
                                            {{ showLess ? 'Mostrar más' : 'Mostrar menos' }} </a><br
                                            class="ng-tns-c30-18">
                                    </div>
                                    <div class="col-md-4 col-sm-6 pl-0 mb-3"><button class="col-sm-12 btn btn-primary"
                                        @click="setDataFilters" id="btn_search" type="button"><i class="la la-search"
                                                style="width: 20px;"></i> Buscar</button></div>
                                </div>
                                <div class="kt-portlet__body">
                                    <div class="row align-items-center ng-tns-c26-6 ng-star-inserted" style="">
                                        <div class="primeng-datatable-container">
                                            <div class="ng-tns-c26-6" scrollwidth="100%" rows="10">
                                                <div class="ui-table ui-widget ui-table-responsive">
                                                    <!----><!----><!----><!----><!----><!---->
                                                    <div class="ui-table-scrollable-wrapper ng-star-inserted"><!---->
                                                        <div class="ui-table-scrollable-view">
                                                            <div class="ui-table-scrollable-header ui-widget-header">
                                                                <div class="ui-table-scrollable-header-box"
                                                                    style="margin-right: 0px;">
                                                                    <table class="ui-table-scrollable-header-table">
                                                                        <!---->
                                                                        <thead class="ui-table-thead"><!---->
                                                                            <tr class="ng-tns-c26-6 ng-star-inserted">
                                                                                <th class="ui-sortable md-1" id="thDate"
                                                                                    style="text-align: center;">
                                                                                    Transaccion </th>
                                                                                <th class="ui-sortable md-1" id="thDate"
                                                                                    style="text-align: center;"> Fecha
                                                                                </th>
                                                                                <th class="ui-sortable md-1" id="thUser"
                                                                                    style="text-align: center;">
                                                                                    Operación </th>
                                                                                <th class="ui-sortable" id="thOp"
                                                                                    style="text-align: center;"> Agente
                                                                                </th>
                                                                                <th class="ui-sortable" id="thGame"
                                                                                    style="text-align: center;"> Cuenta
                                                                                    Destino </th>
                                                                                <th class="ui-sortable"
                                                                                    style="text-align: center;">
                                                                                    Depósito </th>
                                                                                <th class="ui-sortable"
                                                                                    style="text-align: center;"> Retiro
                                                                                </th>
                                                                                <th class="ui-sortable"
                                                                                    style="text-align: center;"> Saldo
                                                                                </th>
                                                                                <th class="ui-sortable"
                                                                                    style="text-align: center;">
                                                                                    Balances </th>
                                                                            </tr>
                                                                        </thead>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div class="ui-table-scrollable-body">
                                                                <table class="ui-table-scrollable-body-table"><!---->
                                                                    <tbody class="ui-table-tbody">
                                                                        <!----><!----><!----><!---->
                                                                        <tr class="ng-tns-c26-6 ng-star-inserted"
                                                                            v-for="(item, index) in paginarArray(reportData.data, registers_per_page)[actual_page - 1]"
                                                                            :key="index"
                                                                            style="font-size: 11px !important;">
                                                                            <td class="ng-tns-c26-6"><span class="ui-column-title">Transaccion</span><span class="">{{item.id }}</span> </td>
                                                                            <td class="ng-tns-c26-6"><span class="ui-column-title">Fecha</span><span class="">{{formatearFecha(item.fecha) }}
                                                                                </span></td>
                                                                            <td class="ng-tns-c26-6"><span class="ui-column-title">Operación</span><span class="">{{item.detalles.includes('Carga') ? 'DEPOSITO' : 'RETIRO' }} </span> </td>
                                                                            <td class="ng-tns-c26-6"><span class="ui-column-title">Agente</span><span class="">{{item.creator_username }}</span></td>
                                                                            <td class="ng-tns-c26-6"><span class="ui-column-title">Cuenta destino</span><span class="">{{item.username }}</span></td>
                                                                            <td class="ng-tns-c26-6"
                                                                                style="text-align: right;"><span class="ui-column-title">Deposito</span><span
                                                                                    class="">{{item.detalles.includes('Carga') ? parseNumbers(Math.abs(item.valor)) : '0,00' }}</span> </td>
                                                                            <td class="ng-tns-c26-6"
                                                                                style="text-align: right;"><span class="ui-column-title">Retiro</span><span
                                                                                    class="">{{item.detalles.includes('Retiro') ? parseNumbers(Math.abs(item.valor)) : '0,00' }}</span></td>
                                                                            <td class="ng-tns-c26-6"
                                                                                style="text-align: right;"><span class="ui-column-title">Saldo</span><span
                                                                                    class="">{{ parseNumbers(item.valor)}}</span></td>
                                                                            <td class="ng-tns-c26-6"
                                                                                style="text-align: center;"><span
                                                                                    class=""><span class="ui-column-title">Balances</span>
                                                                                </span><a @click="setTargetMovement(item)"
                                                                                    class="btn kt-subheader__btn-primary btn-icon btn-rose"
                                                                                    title="Balances"><i
                                                                                        class="la la-book icon-big"
                                                                                        id="item__rocio32casco__herreralu10__0__2000__11952.25__9952.25__566463129"></i></a>
                                                                            </td>
                                                                        </tr><!----><!---->
                                                                    </tbody>
                                                                </table><!----><!---->
                                                            </div><!---->
                                                        </div>
                                                    </div><!----><!----><!----><!----><!---->
                                                </div>
                                            </div>
                                            <div class="ng-tns-c26-6">
                                                <div class="ui-table ui-widget"><!----><!----><!----><!----><!---->
                                                    <div class="ui-table-wrapper ng-star-inserted">
                                                        <table><!---->
                                                            <thead class="ui-table-thead"><!----></thead><!---->
                                                            <tfoot class="ui-table-tfoot ng-star-inserted">
                                                                <!----><!---->
                                                                <tr class="ng-tns-c23-7 ng-star-inserted ng-tns-c26-6"
                                                                    style="font-size: 11px !important;background: #80808038;color: white;font-weight: BOLD;">
                                                                    <td class="ng-tns-c23-7"><span
                                                                            class="">TOTALES</span></td>
                                                                    <td class="ng-tns-c23-7" style="text-align: right;">
                                                                        <span class="" style="color: #6c79e1;">Depósito
                                                                            : <br class="ng-tns-c26-6"></span>{{
                                                                        parseNumbers(reportData.data?.filter(item =>
                                                                        item.detalles.includes('Carga')).map(item=>item.valor).reduce((a,
                                                                        b) => a + b, 0)) }}
                                                                    </td>
                                                                    <td class="ng-tns-c23-7" style="text-align: right;">
                                                                        <span class="" style="color: #6c79e1;">Depósito
                                                                            Bonificado
                                                                            : <br class="ng-tns-c26-6"></span>0,00
                                                                    </td>
                                                                    <td class="ng-tns-c23-7" style="text-align: right;">
                                                                        <span class="" style="color: #6c79e1;">Retiro :
                                                                            <br class="ng-tns-c26-6"></span>{{
                                                                        parseNumbers(Math.abs(reportData.data?.filter(item =>
                                                                        item.detalles.includes('Retiro')).map(item=>item.valor).reduce((a,
                                                                        b) => a + b, 0))) }}
                                                                    </td>
                                                                    <td class="ng-tns-c23-7" style="text-align: right;">
                                                                        <span class="" style="color: #6c79e1;">Saldo :
                                                                            <br
                                                                                class="ng-tns-c26-6"></span>{{parseNumbers(reportData.data?.filter(item=>
                                                                        item.detalles.includes('Carga')).map(item=>item.valor).reduce((a,
                                                                        b) => a + b, 0) -
                                                                        Math.abs(reportData.data?.filter(item =>
                                                                        item.detalles.includes('Retiro')).map(item=>item.valor).reduce((a,
                                                                        b) => a + b, 0)))}}
                                                                    </td>
                                                                </tr>
                                                            </tfoot>
                                                            <tbody class="ui-table-tbody">
                                                                <!----><!----><!----><!----><!----><!----><!----><!----><!---->
                                                            </tbody>
                                                        </table>
                                                    </div><!----><!----><!----><!----><!----><!---->
                                                </div>
                                            </div><!---->
                                            <div class="bottom row">
                                                <div class="col-12 col-md-6">

                                                </div>
                                                <div
                                                    class="dataTables_paginate paging_simple_numbers col-12 col-md-6 d-flex">
                                                    <div class="me-1 w-100">
                                                        <pagination :options="{ texts: { count: '' } }"
                                                            style="float: inline-end;" v-model="actual_page"
                                                            :records="records" :per-page="registers_per_page"
                                                            @paginate="paginate" />
                                                    </div>
                                                    <select id="registersPerPageSelect" name="users_length"
                                                        aria-controls="users" @change="setRegistersPerPage()"
                                                        class="form-select" style="max-width:60px;">
                                                        <option value="10">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ARBOL ---------------------------------------->
                <div class="col-lg-3 col-sm-12 pr-0 pl-0" v-if="screenWidth > 1000">
                    <div class="w-100">
                        <div id="treeNode" class="px-2">
                            <div class="kt-portlet"
                                style="opacity: 0.9;border: 1px solid #494141;margin-bottom: unset !important;">
                                <div class="kt-portlet__head" style="background-color: #15172B !important;">
                                    <div class="kt-portlet__head-label">
                                        <h3 class="kt-font-light kt-font-md kt-portlet__head-title text-uppercase">
                                            <span>Agentes</span>
                                        </h3>
                                    </div>
                                </div>
                                <div class="tree kt-content px-1">
                                    <treeNode :node="main_tree" :startDate="getFechaFormated(startDate)"
                                        :endDate="getFechaFormated(endDate)" :startHour="startHour"
                                        :endHour="endHour" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="offcanvas offcanvas-start p-0" tabindex="-1" id="offcanvasTree" aria-labelledby="offcanvasTreeLabel" style="max-width: 70%;background-color: #15172B !important;">
                    <div class="offcanvas-header p-2">
                        <h5 class="offcanvas-title" id="offcanvasTreeLabel" style="color: white;">Agentes</h5>
                        <button style="background-color: rgba(255, 255, 255, 0.2);color:white" class="close btn btn-sm px-2" type="button" data-bs-dismiss="offcanvas" aria-label="Close"><span aria-hidden="true">×</span></button>
                    </div>
                    <div class="offcanvas-body p-0">
                        <treeNode :node="main_tree" :startDate="getFechaFormated(startDate)"
                                        :endDate="getFechaFormated(endDate)" :startHour="startHour"
                                        :endHour="endHour" />
                    </div>
                </div>
            </div>
            <!-- Modal -->
            <div class="modal fade in show" aria-hidden="false" id="balancesModal" bsmodal="" role="dialog"
                tabindex="-1" aria-modal="true">
                <div class="modal-dialog modal-md">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title"><span class="ng-tns-c26-6"
                                    style="padding-bottom: 4px;border-bottom: 1px solid #848484;">Balances Transacción :
                                    {{ targetMovement.id }}</span></h4><button class="close" aria-label="Close" @click="closeModal" type="button"><span
                                    class="ng-tns-c26-6" aria-hidden="true">×</span></button>
                        </div>
                        <div class="modal-body pb-1">
                            <h5 class="modal-title mb-1"><span class="ng-tns-c26-6">Cuenta Origen</span></h5>
                            <div class="input-group input-group-md mb-3">
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"
                                                id="basic-addon2">Nombre</span></div><input class="form-control" :value="targetMovement.creator_username"
                                            disabled="true" id="lbl_parentuserName" type="text">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"
                                                id="basic-addon2">Balance Anterior</span></div><input :value="parseNumbers(targetMovement.antiguo_capital_creator)"
                                            class="form-control" disabled="true" id="lbl_parentBalanceBefore"
                                            type="text">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"
                                                id="basic-addon2">Balance Posterior</span></div><input :value="parseNumbers(targetMovement.nuevo_capital_creator)"
                                            class="form-control" disabled="true" id="lbl_parentBalanceAfter"
                                            type="text">
                                    </div>
                                </div>
                            </div>
                            <h5 class="modal-title mb-1"><span class="ng-tns-c26-6">Cuenta Destino</span></h5>
                            <div class="input-group input-group-md">
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"
                                                id="basic-addon2">Nombre</span></div><input class="form-control" :value="targetMovement.username"
                                            disabled="true" id="lbl_childUserNameText" type="text">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"
                                                id="basic-addon2">Balance Anterior</span></div><input :value="parseNumbers(targetMovement.antiguo_capital)"
                                            class="form-control" disabled="true" id="lbl_childBalanceBeforerText"
                                            type="text">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"
                                                id="basic-addon2">Balance Posterior</span></div><input :value="parseNumbers(targetMovement.nuevo_capital)"
                                            class="form-control" disabled="true" id="lbl_childBalanceAfter" type="text">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer"><button class="btn btn-default" type="button" @click="closeModal">Cerrar</button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import treeNode from './treeNode.vue';
import Pagination from 'vue-pagination-2';
import DatePicker from '../datePicker/components/Datepicker'
import { es } from '@sum.cumo/vue-datepicker/dist/locale/index.esm'
export default {
    name: 'reporteCargas',
    components: { treeNode, DatePicker, Pagination },
    data() {
        return {
            targetMovement:{},
            actual_page: 1,
            registers_per_page: 10,
            startDate: "",
            startHour: "",
            selectedDate: "Hoy",
            endDate: "",
            endHour: "",
            username: "",
            searchByName: "",
            selectedTarget: "Jugadores",
            searchByTarget: "",
            pages: [],
            records: 0,
            directos: false,
            superiores: false,
            recibidas: false,
            selectedFilter: "",
            itemSuperior: {},
            dateType: 'local',
            es: es,
            showLess: true,
            loading: false
        }
    },
    props: ["view_type","screenWidth"],
    computed: {
        cuentaCorrienteTarget(){
            return this.$store.getters["getCuentaCorrienteTarget"]
        },
        main_tree() {
            return this.$store.getters["getMainTree"];
        },
        reportData() {
            let main = this.$store.getters["getReportDataCargas"];
            let data = main.data ?? [];
            let report = {
                data: data,
                ingreso: main.ingreso,
                salida: main.salida,
                total: main.total
            };
            let targetSelected = this.selectedTarget == "Jugadores" ? "APOSTADOR" : "PROMOTOR";
            if (this.searchByName != "") {
                report.data = data.filter(target => target.roles.split('"')[1].split("_")[1] == targetSelected && target.username.toLowerCase().includes(this.searchByName.toLowerCase()))
                return report
            }
            if (this.selectedFilter == "superior") {
                report.data = data.filter(target => target.detalles.includes("superior") && target.roles.split('"')[1].split("_")[1] == "APOSTADOR")
                return report
            }
            if (this.selectedFilter == "directos") {
                report.data = data.filter(target => !target.detalles.includes("superior") && target.roles.split('"')[1].split("_")[1] == targetSelected)
                return report
            }
            if (this.selectedFilter == "recibidas") {
                report.data = data.filter(target => target.username == this.targetUser.name && target.roles.split('"')[1].split("_")[1] == "PROMOTOR")
                return report
            }
            report.data = data.filter(target => target.roles.includes(targetSelected))
            return report
        },
        loadingReport() {
            return this.$store.getters["getLoadingReport"];
        },
        targetUser() {
            return this.$store.getters["getTargetUser"];
        },
    },
    methods: {
        setTargetMovement(item){
            this.targetMovement = item
            this.openModal('balancesModal')
        },
        paginate(page) {
            this.actual_page = page;
        },
        setFilter() {
            if (this.superiores) {
                this.selectedFilter = "superior";
                this.directos = false;
                this.recibidas = false;
            }
            else if (this.directos) {
                this.selectedFilter = "directos";
                this.superiores = false;
                this.recibidas = false;
            }
            else if (this.recibidas) {
                this.selectedFilter = "recibidas";
                this.directos = false;
                this.superiores = false;
            }
            else {
                this.selectedFilter = "";
            }
        },
        setDataFilters(){
            this.setNameSearch();
            this.setFilter();
        },
        setNameSearch() {
            this.searchByName = this.username;
            this.searchByTarget = this.selectedTarget;
            this.getReport();
        },
        parseRole(role) {
            if (role) {
                let role_name = role.split('"')[1].split("_")[1];
                if (role_name == "PROMOTOR") {
                    return "Agente";
                }
                else if (role_name == "APOSTADOR") {
                    return "Jugador";
                }
                else {
                    return role_name
                }
            }
        },
        openModal(target) {
            try {
                this.backdrop(true);
                let modal = document.getElementById(target);
                modal.classList.add("show");
                modal.style.display = "block";
            } catch (error) {
                console.log(error);
            }
        },
        backdrop(action) {
            if (action) {
                document.getElementById("modalBackdrop").style.display = "block";
                document.getElementById("modalBackdrop").classList.add("show");
            }
            else {
                document.getElementById("modalBackdrop").style.display = "none";
                document.getElementById("modalBackdrop").classList.remove("show");
            }
        },
        closeModal() {
            let modals = document.getElementsByClassName("modal");
            [...modals].forEach(modal => {
                modal.classList.remove("show");
                modal.style.display = "none";
            })
            this.targetMovement = {}
            document.getElementById("modalBackdrop").style.display = "none";
            document.getElementById("modalBackdrop").classList.remove("show");
        },
        parseNumbers(number) {
            return new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number)
        },
        showHiddenTree() {
            let checked = document.getElementById("TreeShowHidden").checked;
            this.$store.dispatch("showHiddenTree", checked);
        },
        formatearFecha(fechaString) {
            const fecha = new Date(fechaString);
            const anio = fecha.getFullYear();
            const mes = this.agregarCeroDelante(fecha.getMonth() + 1);
            const dia = this.agregarCeroDelante(fecha.getDate());
            const Hora = this.agregarCeroDelante(fecha.getHours());
            const Minutos = this.agregarCeroDelante(fecha.getMinutes());
            const Segundos = this.agregarCeroDelante(fecha.getSeconds());
            return `${anio}/${mes}/${dia} ${Hora}:${Minutos}:${Segundos}`;
        },
        agregarCeroDelante(numero) {
            return numero < 10 ? '0' + numero : numero;
        },
        getFechaFormated(fecha) {
            fecha = new Date(fecha);
            var año = fecha.getFullYear();
            var mes = ('0' + (fecha.getMonth() + 1)).slice(-2);
            var dia = ('0' + fecha.getDate()).slice(-2);
            var cadenaFecha = año + '-' + mes + '-' + dia;
            return cadenaFecha
        },
        getFecha(date) {
            return date
        },
        setDateRanges(range) {
            let hoy = new Date()
            this.selectedDate = range;
            if (range == "Hoy") {
                const tomorrow = new Date(hoy);
                tomorrow.setDate(tomorrow.getDate() + 1);
                this.startDate = this.getFecha(hoy);
                this.startHour = "00:00:00"
                this.endDate = this.getFecha(tomorrow);
                this.endHour = "00:00:00"
            }
            else if (range == "Ayer") {
                let ayer = new Date(hoy);
                ayer.setDate(ayer.getDate() - 1);
                this.startDate = this.getFecha(ayer);
                this.endDate = this.getFecha(hoy);
                this.startHour = "00:00:00"
                this.endHour = "00:00:00"
            }
            else if (range == "Semana previa") {
                const diaDeLaSemana = hoy.getDay();
                const diasParaRetroceder = diaDeLaSemana === 0 ? 6 : diaDeLaSemana - 1;
                const lunesSemanaAnterior = new Date(hoy);
                lunesSemanaAnterior.setDate(hoy.getDate() - (diasParaRetroceder + 7));
                const fechasSemanaPrevia = [];
                for (let i = 0; i < 8; i++) {
                    const fecha = new Date(lunesSemanaAnterior);
                    fecha.setDate(lunesSemanaAnterior.getDate() + i);
                    fechasSemanaPrevia.push(fecha);
                }
                this.startDate = this.getFecha(fechasSemanaPrevia[0]);
                this.endDate = this.getFecha(fechasSemanaPrevia[7]);
                this.startHour = "00:00:00"
                this.endHour = "00:00:00"
            }
            else if (range == "Semana actual") {
                const primerDiaSemana = new Date(hoy);
                primerDiaSemana.setDate(hoy.getDate() - (hoy.getDay() === 0 ? 7 : hoy.getDay()));
                // Paso 3: Obtener el rango de fechas de la semana
                const rangoSemana = [];
                for (let i = 0; i < 9; i++) {
                    const fecha = new Date(primerDiaSemana);
                    fecha.setDate(primerDiaSemana.getDate() + i);
                    rangoSemana.push(fecha);
                }
                this.startDate = this.getFecha(rangoSemana[1]);
                this.endDate = this.getFecha(rangoSemana[8]);
                this.startHour = "00:00:00"
                this.endHour = "00:00:00"
            }
            else if (range == "Mes actual") {
                const primerDiaMes = new Date(hoy.getFullYear(), hoy.getMonth(), 1);
                const tomorrow = new Date(hoy);
                tomorrow.setDate(tomorrow.getDate() + 1);
                this.startDate = this.getFecha(primerDiaMes);
                this.endDate = this.getFecha(tomorrow);
                this.startHour = "00:00:00"
                this.endHour = "00:00:00"
            }
            else if (range == "Mes previo") {
                const primerDiaMesAnterior = new Date(hoy);
                const primerdiaMesActual = new Date(hoy);
                primerDiaMesAnterior.setMonth(hoy.getMonth() - 1);
                primerDiaMesAnterior.setDate(1);
                primerdiaMesActual.setDate(1);
                this.startDate = this.getFecha(primerDiaMesAnterior);
                this.endDate = this.getFecha(primerdiaMesActual);
                this.startHour = "00:00:00"
                this.endHour = "00:00:00"
            }
            // this.getReport();
        },
        async getReport() {
            try {
                this.loading = true;
                this.actual_page = 1;
                await this.$store.dispatch("getReportCargas", {
                    startDate: this.getFechaFormated(this.startDate),
                    endDate: this.getFechaFormated(this.endDate),
                    startHour: this.startHour + ":00",
                    endHour: this.endHour + ":00",
                    id: this.targetUser
                })
                this.loading = false;
            } catch (error) {
                console.log(error);
                this.reportData = {}
                this.loading = false;
            }
        },
        paginarArray(array, elementosPorPagina) {
            const paginas = [];
            let paginaActual = [];

            for (let i = 0; i < array.length; i++) {
                if (paginaActual.length === elementosPorPagina) {
                    paginas.push(paginaActual);
                    paginaActual = [];
                }
                paginaActual.push(array[i]);
            }

            if (paginaActual.length > 0) {
                paginas.push(paginaActual);
            }
            this.records = array.length;
            this.pages = paginas;
            return paginas;
        },
        showMaxRegisterSelection() {
            document.getElementById("selectPage").style.display = "block";
        },
        setRegistersPerPage() {
            const pages = parseInt(document.getElementById('registersPerPageSelect').value)
            this.registers_per_page = pages;
        },
        changePage(page) {
            this.actual_page = page
        }
    },
    watch: {
        cuentaCorrienteTarget(newVal){
            this.username = newVal
        },
        targetUser(newVal) {
            if (newVal.id && !this.firstLoadReport) {
                this.getReport()
                this.firstLoadReport = true
            }
        },
        directos(){
            this.setFilter();
        },
        superiores(){
            this.setFilter();
        },
        recibidas(){
            this.setFilter();
        }
    },
    mounted() {
        if (this.selectedDate == "Hoy") {
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            this.startDate = this.getFecha(new Date());
            this.startHour = "00:00:00"
            this.endDate = this.getFecha(tomorrow);
            this.endHour = "00:00:00"
        }
    },
}
</script>
<style scoped>
.ui-table .ui-table-thead>tr>th {
    color: #dce7f9;
    background-color: #24284b;
}
</style>