<template>
    <div class="kt-grid__item kt-grid__item--fluid kt-grid row flex-lg-row"
        style="margin: 0px; padding: 0px; flex: 0 1 0%;">
        <div id="mainAlert"></div>
        <div v-if="loadingCapital || loading" class="freeze-ui" data-text=" "
            style="position: absolute;z-index:999999 !important"></div>
        <div class="kt-subheader kt-grid__item">
            <div class="kt-subheader__main" style="width:100%;">
                <h3 class="kt-subheader__title" style="color:white;"><span>Agentes </span></h3>
                <span class="kt-subheader__separator kt-subheader__separator--v"></span>
                <span class="kt-subheader__desc"> Cuentas Corrientes </span><!---->
            </div>
        </div>
        <div class="col-lg-12 col-sm-12 pr-0 pl-0">
            <div class="ng-star-inserted">
                <div class="kt-login__form ng-trigger ng-trigger-routerTransition"
                    style="padding-top: 0px; opacity: 1;">
                    <div class="kt-content" style="padding: 0 1rem;">
                        <div class="row resize" style="flex-direction: row;">
                            <div class="col-lg-12" style="padding:0;">
                                <div class="mb-4 py-4 row planetabet-border" style="margin:0">
                                    <div class="col-lg-2" style="display:block">
                                        <div class="input-icon position-relative ml-4"
                                            style="margin:0.5rem !important;">
                                            <form v-on:submit.prevent="searchText">
                                                <input
                                                    class="text-white form-control m-input ng-pristine ng-valid ng-touched input-icon"
                                                    autofocus="" id="UserSearch" maxlength="20" name="filterText"
                                                    v-model="searchModel"
                                                    style="font-weight: bold; border-radius: 4px 0px 0px 4px !important;"
                                                    type="text" placeholder="Buscar...">
                                            </form>
                                        </div>
                                        <div class="mr-4" style="position:absolute;right:-0.5rem;top:0.5rem">
                                            <button class="btn btn-primary pt-2 waves-effect waves-light"
                                                @click="searchText()" id="btn_search"
                                                style="border-radius: 0 7px 7px 0 !important;" type="button">
                                                <i class="la la-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="kt-portlet" style="opacity: 1;">
                                    <div class="kt-portlet__head p-0" style="border: 0;padding: 0 !important; min-height: 0;">
                                        <div class="w-100">
                                            <ul class="nav nav-tabs">
                                                <li style="width: 32% !important;margin-right: 1% !important;">
                                                    <button @click="changeFilter('todos')"
                                                        class="btn btn-bold btn-sm btn-font-sm btn-label-white section_select waves-effect waves-light group-active"
                                                        id="tgAll" data-type="all"> Todos</button>
                                                </li>
                                                <li style="width: 32% !important;margin-right: 1% !important;">
                                                    <button @click="changeFilter('agentes')"
                                                        class="btn btn-bold btn-sm btn-font-sm btn-label-white section_select waves-effect waves-light"
                                                        id="tgAgent" data-type="affiliates"> Agentes</button>
                                                </li>
                                                <li style="width: 32% !important;margin-right: 1% !important;">
                                                    <button @click="changeFilter('jugadores')"
                                                        class="btn btn-bold btn-sm btn-font-sm btn-label-white section_select waves-effect waves-light"
                                                        id="tgPlayer" data-type="players"> Jugadores</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="kt-portlet__body">
                                        <div class="row align-items-center">
                                            <div class="primeng-datatable-container">
                                                <div class="ng-tns-c24-30" scrollwidth="100%">
                                                    <div class="ui-table ui-widget ui-table-responsive">
                                                        <!----><!----><!----><!----><!----><!---->
                                                        <div class="ui-table-scrollable-wrapper ng-star-inserted"
                                                            style=""><!---->
                                                            <div class="ui-table-scrollable-view">
                                                                <div
                                                                    class="ui-table-scrollable-header ui-widget-header">
                                                                    <div class="ui-table-scrollable-header-box"
                                                                        style="margin-right: 0px;">
                                                                        <table class="ui-table-scrollable-header-table">
                                                                            <!---->
                                                                            <thead class="ui-table-thead"><!---->
                                                                                <tr
                                                                                    class="ng-tns-c24-30 ng-star-inserted">
                                                                                    <th class="ng-tns-c24-30 ui-sortable-column"
                                                                                        psortablecolumn="userName"
                                                                                        style="text-align: center; width: 30%;color:white !important"
                                                                                        tabindex="0">Usuario</th>
                                                                                    <th class="ng-tns-c24-30"
                                                                                        style="text-align: center; width: 25%;color:white !important">
                                                                                        Moneda</th>
                                                                                    <th class="ng-tns-c24-30"
                                                                                        style="text-align: center; width: 25%;display:none">
                                                                                        Categoría</th>
                                                                                    <th class="ng-tns-c24-30"
                                                                                        style="text-align: center; width: 25%;color:white !important">
                                                                                        Saldo acumulado</th>
                                                                                    <!----><!---->
                                                                                    <th style="text-align: center; width: 25%;color:white !important"
                                                                                        class="ng-tns-c24-30 ng-star-inserted">
                                                                                        Memoria Acumulada</th>
                                                                                    <th class="ng-tns-c24-30"
                                                                                        style="text-align: center; width: 25%;color:white !important">
                                                                                        Detalle</th>
                                                                                    <th class="ng-tns-c24-30"
                                                                                        style="text-align: center; width: 25%;color:white !important">
                                                                                        Acción</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody class="ui-table-tbody"><!---->
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                <div class="ui-table-scrollable-body">
                                                                    <table class="ui-table-scrollable-body-table">
                                                                        <!---->
                                                                        <tbody class="ui-table-tbody">
                                                                            <!----><!----><!----><!---->
                                                                            <tr class="ng-tns-c24-30 ng-star-inserted" v-for="(user, index) in paginarArray(users_capital, registers_per_page)[actual_page - 1]"
                                                                                :key="index"
                                                                                style="">
                                                                                <td class="ng-tns-c24-30" hidden="">
                                                                                    10019769</td>
                                                                                <td class="ng-tns-c24-30"
                                                                                    style="text-align: left; font-size:medium; width: 30%;">
                                                                                    <span
                                                                                        class="ui-column-title">Usuario</span><span
                                                                                        class="ng-tns-c24-30"
                                                                                        data-placement="right"
                                                                                        aria-describedby="tooltip-48"
                                                                                        @click="setCuentasUser(user.username)"><!----><!----><!----><img
                                                                                            class="img-fluid ng-tns-c24-30 ng-star-inserted"
                                                                                            :src="user.role === 'ROLE_APOSTADOR' ? '/ganaencasa/img/Player.svg' : '/ganaencasa/img/Agent.svg'"><!----><span
                                                                                            class="ng-tns-c24-30"
                                                                                            style="text-decoration: underline;cursor: pointer;">
                                                                                            {{ user.username }} </span></span></td>
                                                                                <td class="ng-tns-c24-30"
                                                                                    style="text-align: center; font-size:medium; width: 25%;display:none">
                                                                                    <span
                                                                                        class="ui-column-title">Categoría</span>
                                                                                    A </td>
                                                                                <td class="ng-tns-c24-30"
                                                                                    style="text-align: center; font-size:medium; width: 25%;">
                                                                                    <span
                                                                                        class="ui-column-title">Moneda</span>
                                                                                    ARS </td>
                                                                                <td class="ng-tns-c24-30"
                                                                                    style="text-align: center; font-size:medium; width: 25%;">
                                                                                    <span class="ui-column-title">Saldo
                                                                                        acumulado</span> {{ parseNumbers(user.capital_total) }} </td>
                                                                                <!----><!----><!---->
                                                                                <td style="text-align: center; font-size:medium; width: 25%;"
                                                                                    class="ng-tns-c24-30 ng-star-inserted">
                                                                                    <span
                                                                                        class="ui-column-title">Memoria
                                                                                        Acumulada</span> - </td>
                                                                                <!---->
                                                                                <td class="ng-tns-c24-30"
                                                                                    style="text-align: center; font-size:medium; width: 25%;text-decoration: underline !important;">
                                                                                    <span
                                                                                        class="ui-column-title">Detalle</span><button
                                                                                        class="btn btn-label-dark"
                                                                                        @click="setCuentasUser(user.username)"
                                                                                        tabindex="0">Mostrar
                                                                                        detalle</button></td>
                                                                                <td class="ng-tns-c24-30"
                                                                                    style="text-align: center; font-size:medium; width: 25%;">
                                                                                    <div class="d-flex"><span
                                                                                            class="ui-column-title">Acción</span>
                                                                                            <div class="dropdown">
                                                                                            <button
                                                                                                class="btn btn-hover-red btn-elevate-hover btn-icon btn-sm btn-icon-md btn-circle btn-action rounded-circle"
                                                                                                id="dropdownMenuButton1"
                                                                                                data-bs-toggle="dropdown"
                                                                                                aria-expanded="false" type="button">
                                                                                                <i class="flaticon-more">
                                                                                                </i>
                                                                                            </button>
                                                                                            <ul class="dropdown-menu"
                                                                                                aria-labelledby="dropdownMenuButton1"
                                                                                                style="font-size:small !important;">
                                                                                                <li v-if="user.enabled == 1">
                                                                                                    <a href="javascript:;"
                                                                                                        @click="openModal('paymentModal', user.user)"
                                                                                                        class="payment-btn"><img
                                                                                                            class="mr-2 img-fluid"
                                                                                                            style="height: 18px !important;"
                                                                                                            src="/ganaencasa/img/Payment.svg">
                                                                                                        Pago
                                                                                                    </a>
                                                                                                </li>
                                                                                                <li v-if="user.enabled == 1">
                                                                                                    <a href="javascript:;"
                                                                                                        @click="openModal('manualAdjustmentsModal', user.user)"
                                                                                                        class="manual-adjustment-btn"><img
                                                                                                            class="mr-2 img-fluid"
                                                                                                            style="height: 18px !important;"
                                                                                                            src="/ganaencasa/img/Manual Adjustment.svg">
                                                                                                        Ajustes manuales
                                                                                                    </a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table><!----><!---->
                                                                </div><!---->
                                                            </div>
                                                        </div><!----><!----><!----><!----><!---->
                                                    </div>
                                                </div>
                                                <div id="users_wrapper"
                                                    class="dataTables_wrapper dt-bootstrap4 no-footer row">
                                                    <div class="col-12 col-md-6">

                                                    </div>
                                                    <div
                                                        class="dataTables_paginate paging_simple_numbers col-12 col-md-6 d-flex">
                                                        <div class="me-1">
                                                            <pagination :options="{ texts: { count: '' } }"
                                                                v-model="actual_page" :records="records"
                                                                :per-page="registers_per_page"
                                                                @paginate="paginate" />
                                                        </div>
                                                        <select id="registersPerPageSelect" name="users_length"
                                                            aria-controls="users"
                                                            @change="setRegistersPerPage()" class="form-select"
                                                            style="max-width:60px;">
                                                            <option value="10">10</option>
                                                            <option value="25">25</option>
                                                            <option value="50">50</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- ------- MODALES ------------------------------------------->
                    <div data-type="create">
                        <div aria-hidden="true" aria-labelledby="createAgentModal" class="modal fade in show"
                            id="ModalNewUser" role="dialog" tabindex="-1">
                            <div class="modal-dialog modal-md">
                                <div class="modal-content"><!---->
                                    <div v-if="new_user_data.error != ''"
                                        class="font-size-custom modal-clear-hide font-size-custom text-center pb-4">
                                        <span style="color:red">{{ new_user_data.error }}</span>
                                    </div>
                                    <div class="modal-header">
                                        <h4 class="modal-title">
                                            <span style="padding-bottom: 4px;border-bottom: 1px solid #848484;">Nuevo
                                                Agente</span>
                                        </h4>
                                        <button aria-label="Close" class="close" type="button" @click="closeModal"><span
                                                aria-hidden="true">×</span></button>
                                    </div>
                                    <div class="modal-body pb-1"><!----><!---->
                                        <form v-on:submit.prevent="create_user">
                                            <div class="form-group ng-star-inserted">
                                                <div class="input-group">
                                                    <div class="input-group-prepend"><span class="input-group-text"
                                                            id="basic-addon2">Rol</span></div>
                                                    <div btnradiogroup=""
                                                        class="btn-group ng-untouched ng-pristine ng-valid"
                                                        style="padding: 0px !important;">
                                                        <div class="form-control" style="padding: 0px !important;">
                                                            <div class="form-check form-check-inline"
                                                                style="margin-left: 10px;">
                                                                <input btnradio="Vendedor" class="active" disabled
                                                                    id="inlineRadio1" name="roleName" type="radio"
                                                                    value="Vendedor" aria-pressed="true">
                                                                <label class="form-check-label"
                                                                    for="inlineRadio1">&nbsp;VENDEDOR</label>
                                                            </div>
                                                            <div class="form-check form-check-inline">
                                                                <input btnradio="Agente" class="" checked=""
                                                                    id="inlineRadio2" name="roleName" type="radio"
                                                                    value="Agente" aria-pressed="false">
                                                                <label class="form-check-label"
                                                                    for="inlineRadio2">&nbsp;AGENTE</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group ">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon2"><i
                                                                class="flaticon-users"></i></span>
                                                    </div>
                                                    <input class="form-control w-90 ng-untouched ng-pristine ng-invalid"
                                                        maxlength="18" name="UserName" v-model="new_user_data.username"
                                                        placeholder="Nombre de usuario" required type="text">
                                                </div>
                                            </div><!---->
                                            <div class="form-group password-form-group">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon2"><i
                                                                class="flaticon-lock"></i></span>
                                                    </div>
                                                    <input class="form-control w-90 ng-untouched ng-pristine ng-invalid"
                                                        v-model="new_user_data.password" maxlength="18" name="Password"
                                                        placeholder="Contraseña" required type="text">
                                                </div>
                                                <div class="d-flex align-items-center justify-content-between p-2">
                                                    <div class="d-flex align-items-center" placement="bottom"
                                                        type="button" aria-describedby="tooltip-0">
                                                        <i class="fa fa-info-circle mr-2"></i>
                                                        <label class="mb-0">Requisitos de contraseña</label>
                                                    </div>
                                                    <button @click="passwordGenerator(8, 'new_user_data')"
                                                        class="btn btn-success ml-2 auto-generate-password waves-effect waves-light"
                                                        type="button">GENERADOR DE CONTRASEÑA</button>
                                                </div>
                                            </div><!----><!---->
                                            <div class="form-group ng-star-inserted">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon2"><i
                                                                class="flaticon-users"></i></span>
                                                    </div>
                                                    <input aria-describedby="basic-addon2" v-model="new_user_data.email"
                                                        class="form-control w-90 ng-untouched ng-pristine ng-invalid"
                                                        id="txt_create_email" maxlength="100" name="Email"
                                                        placeholder="Email" required="" type="text">
                                                </div>
                                            </div>
                                            <div class="form-group ng-star-inserted">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon2"><i
                                                                class="flaticon-users"></i></span>
                                                    </div>
                                                    <input aria-describedby="basic-addon2"
                                                        v-model="new_user_data.firstname"
                                                        class="form-control w-90 ng-untouched ng-pristine ng-valid"
                                                        id="txt_first_name" maxlength="32" name="FirsName"
                                                        placeholder="Nombre" type="text">
                                                </div>
                                            </div>
                                            <div class="form-group ng-star-inserted">
                                                <div class="input-group">
                                                    <div class="input-group-prepend"><span class="input-group-text"
                                                            id="basic-addon2"><i class="flaticon-users"></i></span>
                                                    </div>
                                                    <input aria-describedby="basic-addon2"
                                                        class="form-control w-90 ng-untouched ng-pristine ng-valid"
                                                        id="txt_first_surname" maxlength="32" name="SurName"
                                                        placeholder="Apellido" type="text">
                                                </div>
                                            </div>
                                            <div class="form-group ng-star-inserted">
                                                <div class="input-group">
                                                    <div class="input-group-prepend"><span class="input-group-text"
                                                            id="basic-addon2"><i class="flaticon-users"></i></span>
                                                    </div>
                                                    <select
                                                        class="form-control w-90 ng-untouched ng-pristine ng-valid d-block"
                                                        id="languageSelected" name="languageSelected"
                                                        placeholder="Idioma" required=""><!---->
                                                        <option value="es" class="ng-star-inserted">es
                                                        </option>
                                                    </select>
                                                </div>
                                            </div><!----><!---->
                                            <div class="form-group ng-star-inserted">
                                                <div class="input-group">
                                                    <div
                                                        class="col-form-label col-sm-6 d-flex align-items-center justify-content-start">
                                                        Puede realizar retiros</div>
                                                    <div class="col-sm-6"><!----><!---->
                                                        <div class="form-check form-switch" style="font-size: larger;">
                                                            <input class="form-check-input" type="checkbox"
                                                                id="flexSwitchCheckCheckedEdit" checked
                                                                style="height: 1em !important;">
                                                            <label class="form-check-label"
                                                                for="flexSwitchCheckCheckedEdit"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!----><!----><!---->
                                            <div class="modal-footer">
                                                <button class="btn btn-default waves-effect waves-light"
                                                    @click="closeModal" type="button">Cancelar</button>
                                                <button class="btn btn-primary waves-effect waves-light" type="submit"
                                                    id="ModalNewUserAffiliateSubmit"
                                                    data-loading="<i class='fa fa-spinner fa-spin '></i> Creando..."><i
                                                        class="fa fa-save"></i><span>Guardar</span></button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div aria-hidden="true" aria-labelledby="createAgentModal" bsmodal="" class="modal fade in show"
                            id="ModalNewUser2" role="dialog" tabindex="-1" aria-modal="true">
                            <div class="modal-dialog modal-md">
                                <div class="modal-content"><!---->
                                    <div v-if="new_user_data.error != ''"
                                        class="font-size-custom modal-clear-hide font-size-custom text-center pb-4">
                                        <span style="color:red">{{ new_user_data.error }}</span>
                                    </div>
                                    <form v-on:submit.prevent="create_user">
                                        <div class="modal-header">
                                            <h4 class="modal-title">
                                                <span
                                                    style="padding-bottom: 4px;border-bottom: 1px solid #848484;">Nuevo
                                                    Jugador</span>
                                            </h4>
                                            <button aria-label="Close" class="close" type="button"
                                                @click="closeModal"><span aria-hidden="true">×</span></button>
                                        </div>
                                        <div class="modal-body pb-1"><!---->
                                            <div class="form-group ">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon2"><i
                                                                class="flaticon-users"></i></span>
                                                    </div>
                                                    <input class="form-control w-90 ng-untouched ng-pristine ng-invalid"
                                                        maxlength="18" name="UserName" v-model="new_user_data.username"
                                                        placeholder="Username" required="" type="text">
                                                </div>
                                            </div><!---->
                                            <div class="form-group ng-star-inserted">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon2"><i
                                                                class="flaticon-lock"></i></span>
                                                    </div>
                                                    <input class="form-control w-90 ng-untouched ng-pristine ng-invalid"
                                                        v-model="new_user_data.password" maxlength="18" name="Password"
                                                        placeholder="Password" required="" type="text">
                                                </div>
                                                <div class="d-flex align-items-center justify-content-between p-2">
                                                    <div class="d-flex align-items-center" placement="bottom"
                                                        type="button" aria-describedby="tooltip-0">
                                                        <i class="fa fa-info-circle mr-2"></i><label
                                                            class="mb-0">Requisitos
                                                            de contraseña</label>
                                                    </div>
                                                    <button @click="passwordGenerator(8, 'new_user_data')"
                                                        class="btn btn-success ml-2 auto-generate-password waves-effect waves-light"
                                                        type="button">GENERADOR DE CONTRASEÑA</button>
                                                </div>
                                            </div><!----><!----><!---->
                                        </div>
                                        <div class="modal-footer">
                                            <button class="btn btn-default waves-effect waves-light" @click="closeModal"
                                                type="button">Cancel</button>
                                            <button class="btn btn-primary waves-effect waves-light" type="submit"
                                                id="ModalNewUserPlayerSubmit"
                                                data-loading="<i class='fa fa-spinner fa-spin '></i> Creando..."><i
                                                    class="fa fa-save"></i><span>Save</span></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div aria-hidden="true" aria-labelledby="depositModal" bsmodal="" class="modal fade in show"
                            id="ModalCredit" role="dialog" tabindex="-1">
                            <div class="modal-dialog modal-md">
                                <div class="modal-content"><!---->
                                    <form v-on:submit.prevent="cargar_descargar_Fichas">
                                        <div class="modal-header">
                                            <h4 class="modal-title"><span
                                                    style="padding-bottom: 4px;border-bottom: 1px solid #848484;">{{
            credit_modal.action }}</span>
                                            </h4>
                                            <button aria-label="Close" class="close" type="button"
                                                @click="closeModal"><span aria-hidden="true">×</span></button>
                                        </div>
                                        <div class="modal-body pb-1">
                                            <div class="input-group input-group-md mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text px-3" id="lbl_parentuserText"
                                                        style="width: 75px;">{{
            credit_modal.role == 'ROLE_PROMOTOR' ? 'Agente' : 'Jugador' }}</span>
                                                    <span class="input-group-text px-3" id="lbl_parentuserName">{{
            userInfo.name }}</span>
                                                </div>
                                                <input
                                                    class="form-control px-1 mx-0 text-center ng-untouched ng-pristine"
                                                    :value="new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(userInfo.balance)" disabled="" enabled="false" type="text">
                                            </div>
                                            <div class="input-group input-group-md">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text px-3" id="lbl_usertext"
                                                        style="width: 75px;">Usuario</span>
                                                    <span class="input-group-text px-3" id="lbl_username">{{
            credit_modal.username }}</span>
                                                </div>
                                                <input
                                                    class="form-control px-1 mx-0 text-center ng-untouched ng-pristine"
                                                    disabled="" enabled="false" type="text"
                                                    :value="credit_modal.balance">
                                            </div>
                                            <div class="form-group mt-3">
                                                <label for="Agent_monto">Monto</label>
                                                <div class="form-group row">
                                                    <div class="col-md-7 pl-2 pr-0 mb-3">
                                                        <input class="form-control ng-untouched ng-pristine ng-invalid"
                                                            id="ModalCreditAmount" required="" type="text" value="0"
                                                            @input="parseNumbers('', 'ModalCreditAmount')">
                                                    </div>
                                                    <div class="col-md-5 px-0 pl-2">
                                                        <div class="btn kt-subheader__btn-primary btn-icon deductArrow waves-effect waves-light"
                                                            style="width: 3rem;height: 2.6rem;"
                                                            @click="removeValue('ModalCreditAmount', 50)"><i
                                                                class="flaticon2-arrow-down"></i></div>
                                                        <div class="btn kt-subheader__btn-primary btn-icon addArrow waves-effect waves-light"
                                                            style="width: 3rem;height: 2.6rem;"
                                                            @click="addValue('ModalCreditAmount', 50)"><i
                                                                class="flaticon2-arrow-up"></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group"><!----><!---->
                                                <button type="button"
                                                    class="btn btn-label-info ng-star-inserted add-balance waves-effect waves-light"
                                                    @click="addValue('ModalCreditAmount', 100)" data-amount="100">$
                                                    100</button><!---->
                                                <button type="button"
                                                    class="btn btn-label-info ng-star-inserted add-balance waves-effect waves-light"
                                                    @click="addValue('ModalCreditAmount', 500)" data-amount="500">$
                                                    500</button><!---->
                                                <button type="button"
                                                    class="btn btn-label-info ng-star-inserted add-balance waves-effect waves-light"
                                                    @click="addValue('ModalCreditAmount', 1000)" data-amount="1000">$
                                                    1000</button><!---->
                                                <button type="button"
                                                    class="btn btn-label-info ng-star-inserted add-balance waves-effect waves-light"
                                                    @click="addValue('ModalCreditAmount', 5000)" data-amount="5000">$
                                                    5000</button><!----><!---->
                                                <div class="form-group row mt-4 ng-star-inserted"
                                                    style="margin-bottom: -25px !important;">
                                                    <div class="col-md-7 pl-2 pr-0 mb-3"><!----></div>
                                                </div>
                                            </div><!---->
                                        </div>
                                        <div class="modal-footer">
                                            <button class="btn btn-default waves-effect waves-light" @click="closeModal"
                                                type="button">Cancelar</button>
                                            <button class="btn btn-primary waves-effect waves-light"
                                                id="ModalCreditSubmit"
                                                :disabled="credit_modal.value == '0,00' || credit_modal.value == '0'"
                                                type="submit"><i class="fa fa-save"></i><span>{{ credit_modal.action
                                                    }}</span></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div aria-hidden="true" aria-labelledby="withdrawModal" bsmodal="" class="modal fade in show"
                            role="dialog" tabindex="-1">
                            <div class="modal-dialog modal-md">
                                <div class="modal-content"><!---->
                                    <form novalidate="" class="ng-untouched ng-pristine ng-star-inserted ng-invalid"
                                        style="">
                                        <div class="modal-header">
                                            <h4 class="modal-title"><span
                                                    style="padding-bottom: 4px;border-bottom: 1px solid #848484;">Withdrawal</span>
                                            </h4>
                                            <button aria-label="Close" class="close" type="button"><span
                                                    aria-hidden="true">×</span></button>
                                        </div>
                                        <div class="modal-body pb-1">
                                            <div class="input-group input-group-md mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text px-3" id="lbl_user"
                                                        style="width: 75px;">Agent</span>
                                                    <span class="input-group-text px-3" id="lbl_parentuserName"></span>
                                                </div>
                                                <input
                                                    class="form-control px-txt_id mx-0 text-center ng-untouched ng-pristine"
                                                    disabled="" enabled="false" type="text">
                                            </div>
                                            <div class="input-group input-group-md">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text px-3" id="lbl_user"
                                                        style="width: 75px;">User</span>
                                                    <span class="input-group-text px-3" id="lbl_username"></span>
                                                </div>
                                                <input
                                                    class="form-control px-1 mx-0 text-center ng-untouched ng-pristine"
                                                    disabled="" enabled="false" type="text">
                                            </div>
                                            <div class="form-group mt-3">
                                                <label for="Agent_monto">Amount</label>
                                                <div class="form-group row" style="margin-bottom: 0rem !important;">
                                                    <div class="col-md-7 pl-2 pr-0 mb-3">
                                                        <input class="form-control ng-untouched ng-pristine ng-invalid"
                                                            mask="separator.0" maxlength="32" name="monto" required=""
                                                            separatorlimit="0" showmask="true" thousandseparator="."
                                                            type="text">
                                                        <div><!---->
                                                            <div class="has-danger ng-star-inserted"><!---->
                                                                <div class="ng-star-inserted"><!---->
                                                                    <div class="form-control-feedback ng-star-inserted">
                                                                        This field is required.</div>
                                                                </div>
                                                                <div class="ng-star-inserted"><!----></div>
                                                                <div class="ng-star-inserted"><!----></div>
                                                                <div class="ng-star-inserted"><!----></div>
                                                                <div class="ng-star-inserted"><!----></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-5 px-0 pl-2">
                                                        <a class="btn kt-subheader__btn-primary btn-icon deductArrow waves-effect waves-light"
                                                            style="width: 3rem;height: 2.6rem;"><i
                                                                class="flaticon2-arrow-down"></i></a>
                                                        <a class="btn kt-subheader__btn-primary btn-icon addArrow waves-effect waves-light"
                                                            style="width: 3rem;height: 2.6rem;"><i
                                                                class="flaticon2-arrow-up"></i></a>
                                                    </div>
                                                </div><!----><!---->
                                                <div class="form-group row mt-1 ng-star-inserted"
                                                    style="margin-bottom: -25px !important;">
                                                    <div class="col-md-7 pl-2 pr-0 mb-3"><!----></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button class="btn btn-default waves-effect waves-light"
                                                type="button">Cancel</button>
                                            <button class="btn btn-primary waves-effect waves-light" id="_withdraw"
                                                type="submit"><i class="fa fa-save"></i><span>Withdraw</span></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div aria-hidden="true" aria-labelledby="showInfoModal" bsmodal="" class="modal fade in show"
                            id="ModalViewInfo" role="dialog" tabindex="-1">
                            <div class="modal-dialog modal-md">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title"><span
                                                style="padding-bottom: 4px;border-bottom: 1px solid #848484;">Información</span>
                                        </h4>
                                        <button aria-label="Close" class="close" type="button" @click="closeModal"><span
                                                aria-hidden="true">×</span></button>
                                    </div>
                                    <div class="modal-body pb-1" v-if="Object.keys(target_user_info).length > 0">
                                        <div class="row">
                                            <div class="col-md-6"><!----><!----><!---->
                                                <div class="form-group ng-star-inserted" style="">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" id="basic-addon2">ID</span>
                                                        </div>
                                                        <input class="form-control" disabled="true" id="txt_playerId"
                                                            :value="target_user_info.id" name="userId" type="text">
                                                    </div>
                                                </div>
                                                <div class="form-group ng-star-inserted" style="">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><span class="input-group-text"
                                                                id="basic-addon2">Usuario</span>
                                                        </div>
                                                        <input class="form-control" disabled="true"
                                                            id="txt_playerusr_name" :value="target_user_info.username"
                                                            name="userName" type="text">
                                                    </div>
                                                </div>
                                                <div class="form-group ng-star-inserted" style="">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><span class="input-group-text"
                                                                id="basic-addon2">Moneda</span>
                                                        </div>
                                                        <input class="form-control" disabled="true" value="ARS"
                                                            id="txt_playerusr_currency" name="currency" type="text">
                                                    </div>
                                                </div>
                                                <div class="form-group ng-star-inserted" style="">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><span class="input-group-text"
                                                                id="basic-addon2">Padre</span>
                                                        </div>
                                                        <input class="form-control" disabled="true"
                                                            :value="target_user_info.padre"
                                                            id="txt_playerparentusr_name" name="parentUserName"
                                                            type="text">
                                                    </div>
                                                </div>
                                                <div class="form-group ng-star-inserted" style="">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><span class="input-group-text"
                                                                id="basic-addon2">Jugadores</span>
                                                        </div>
                                                        <input class="form-control" disabled="true"
                                                            :value="target_user_info.jugadores"
                                                            id="txt_Agentplayerscount_name" name="playersCount"
                                                            type="text">
                                                    </div>
                                                </div>
                                                <div class="form-group ng-star-inserted" style="">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><span class="input-group-text"
                                                                id="basic-addon2">Fecha Creación</span>
                                                        </div>
                                                        <input class="form-control" disabled="true"
                                                            :value="formatearFecha(target_user_info.created_at)"
                                                            id="txt_player_createdDate" name="createdDate" type="text">
                                                    </div>
                                                </div>
                                                <div class="form-group ng-star-inserted" style="">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><span class="input-group-text"
                                                                id="basic-addon2">Fecha Modificación</span>
                                                        </div>
                                                        <input class="form-control" disabled="true"
                                                            id="txt_player_lastLoginDate" name="lastLoginDate"
                                                            type="text">
                                                    </div>
                                                </div>
                                                <div class="form-group ng-star-inserted" style="">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><span class="input-group-text"
                                                                id="basic-addon2">Skin</span>
                                                        </div>
                                                        <input class="form-control" disabled="true"
                                                            id="txt_player_skinName" :value="target_user_info.marca"
                                                            name="skinName" type="text">
                                                    </div>
                                                </div>
                                                <div class="form-group ng-star-inserted" style="">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><span class="input-group-text"
                                                                id="basic-addon2">Grupo</span>
                                                        </div>
                                                        <input class="form-control" disabled="true"
                                                            id="txt_agent_playerGroupName" name="groupName" type="text">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 pr-0">
                                                <div class="">
                                                    <h5 class="modal-title mb-1"><span>Jerarquía</span></h5>
                                                </div>
                                                <div class="form-group pe-1">
                                                    <div class="ui-tree ui-widget ui-widget-content ui-corner-all ng-star-inserted"
                                                        id="ModalViewInfoAncestryData">
                                                        <ul id="ViewInfoTree"
                                                            v-for="(user, index) in target_user_info.estructura"
                                                            :key="index" class="ui-tree-container ng-star-inserted"
                                                            style="list-style-type: none;">
                                                            <li :style="'margin-left:' + (index * 6) + 'px;'"
                                                                class="ui-treenode-content"
                                                                style="border: 1px solid #0000008f !important;">
                                                                <div style="cursor:pointer;"
                                                                    @click="showUsersCapital(user.id, user.username)"
                                                                    v-if="index == target_user_info.estructura.length - 1">
                                                                    <span
                                                                        class="ui-tree-toggler pi pi-fw ui-unselectable-text pi-caret-down"
                                                                        style="line-height: inherit;visibility: hidden;"></span>
                                                                    <span
                                                                        class="ui-treenode-icon flaticon-users-1 ng-star-inserted mx-1"></span>
                                                                    <span class="ui-treenode-label ui-corner-all px-1">
                                                                        <!----><span class="ng-star-inserted">{{
            user.username }}</span>
                                                                        <!---->
                                                                    </span>
                                                                </div>
                                                                <div v-else>
                                                                    <span
                                                                        class="ui-tree-toggler pi pi-fw ui-unselectable-text pi-caret-down"
                                                                        style="line-height: inherit;"></span><!----><!---->
                                                                    <span
                                                                        class="ui-treenode-icon flaticon-users ng-star-inserted mx-1"></span>
                                                                    <span
                                                                        class="ui-treenode-label ui-corner-all px-1"><!---->
                                                                        <span class="ng-star-inserted">{{ user.username
                                                                            }}</span><!---->
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button class="btn btn-default waves-effect waves-light" @click="closeModal"
                                            type="button">Cerrar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div aria-hidden="true" aria-labelledby="showHideModal" bsmodal="" class="modal fade in show"
                            id="showHideModal" role="dialog" tabindex="-1">
                            <div class="modal-dialog modal-md">
                                <div class="modal-content"><!---->
                                    <form
                                        v-on:submit.prevent="show_hide_action == 'hide' ? hideChildren(target_user_id) : showChildren(target_user_id)">
                                        <div class="modal-header">
                                            <h4 class="modal-title"><span
                                                    style="padding-bottom: 4px;border-bottom: 1px solid #848484;">{{ show_hide_action
            == 'show' ? 'Mostrar usuario' : 'Ocultar usuario' }}</span></h4>
                                            <button aria-label="Close" class="close" type="button"
                                                @click="closeModal"><span aria-hidden="true">×</span></button>
                                        </div>
                                        <div class="modal-body pb-1">
                                            <div class="row">
                                                <div class="col-md-11 mx-auto">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend"><span
                                                                    class="input-group-text"
                                                                    id="basic-addon2">Nombre</span></div>
                                                            <input class="form-control" disabled="true"
                                                                :value="getUserData(target_user_id)?.username"
                                                                id="txt_showHide_usr_name" name="UserName"
                                                                placeholder="Username" type="text">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button class="btn btn-default waves-effect waves-light" @click="closeModal"
                                                type="button">Cancelar</button>
                                            <button class="btn btn-primary waves-effect waves-light" type="submit"><i
                                                    class="fa fa-save"></i><span>Aceptar</span></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div aria-hidden="true" aria-labelledby="enableDisableModal" bsmodal=""
                            class="modal fade in show" id="enableDisableModal" role="dialog" tabindex="-1">
                            <div class="modal-dialog modal-md">
                                <div class="modal-content"><!---->
                                    <form
                                        v-on:submit.prevent="enable_disable_action == 'disable' ? blockUser() : unBlockUser()">
                                        <div class="modal-header">
                                            <h4 class="modal-title"><span
                                                    style="padding-bottom: 4px;border-bottom: 1px solid #848484;">{{
            enable_disable_action === 'enable' ? 'Habilitar usuario' :
                'Deshabilitar usuario' }}</span></h4>
                                            <button aria-label="Close" class="close" type="button"
                                                @click="closeModal"><span aria-hidden="true">×</span></button>
                                        </div>
                                        <div class="modal-body pb-1">
                                            <div class="row">
                                                <div class="col-md-11 mx-auto">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend"><span
                                                                    class="input-group-text"
                                                                    id="basic-addon2">Nombre</span></div>
                                                            <input class="form-control" disabled="true" name="UserName"
                                                                :value="getUserData(target_user_id)?.username"
                                                                placeholder="Username" type="text">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button class="btn btn-default waves-effect waves-light" @click="closeModal"
                                                type="button">Cancelar</button>
                                            <button class="btn btn-primary waves-effect waves-light" type="submit"><i
                                                    class="fa fa-save"></i><span>Aceptar</span></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div aria-hidden="true" aria-labelledby="changePasswordModal" bsmodal=""
                            class="modal fade in show" role="dialog" tabindex="-1" id="ModalChangePassword">
                            <div class="modal-dialog modal-md">
                                <div class="modal-content"><!---->
                                    <form v-on:submit.prevent="changePassword">
                                        <div class="modal-header">
                                            <h4 class="modal-title"><span
                                                    style="padding-bottom: 4px;border-bottom: 1px solid #848484;">Cambiar
                                                    clave</span></h4>
                                            <button aria-label="Close" class="close" type="button"
                                                @click="closeModal"><span aria-hidden="true">×</span></button>
                                        </div>
                                        <div class="modal-body pb-1">
                                            <div class="row">
                                                <div class="col-md-11 mx-auto">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend"><span
                                                                    class="input-group-text" id="basic-addon2">Id</span>
                                                            </div>
                                                            <input class="form-control" disabled="true"
                                                                :value="getUserData(target_user_id)?.username"
                                                                id="txt_changepassword_usr_name" name="UserName"
                                                                placeholder="Username" type="text">
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend"><span
                                                                    class="input-group-text"
                                                                    id="basic-addon2">Password</span></div>
                                                            <input
                                                                class="form-control ng-untouched ng-pristine ng-invalid"
                                                                id="txt_changepassword_newPass" name="Password"
                                                                v-model="new_password" placeholder="password"
                                                                required="" type="password">
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center justify-content-between p-2">
                                                        <div class="d-flex align-items-center my-translated-paragraph"
                                                            placement="bottom" type="button"
                                                            aria-describedby="tooltip-1">
                                                            <i class="fa fa-info-circle mr-2"></i><label
                                                                class="mb-0">Requisitos de
                                                                contraseña</label>
                                                        </div>
                                                        <button @click="passwordGenerator(8, 'new_password')"
                                                            class="btn btn-success ml-2 auto-generate-password waves-effect waves-light"
                                                            type="button">GENERADOR DE CONTRASEÑA</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button class="btn btn-default waves-effect waves-light" @click="closeModal"
                                                type="button">Cancelar</button>
                                            <button class="btn btn-primary waves-effect waves-light" type="submit"
                                                id="ModalChangePasswordSubmit"><i
                                                    class="fa fa-save"></i><span>Aceptar</span></button>
                                            <div style="font-size: 10px; color: black;">V. 1.1</div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div aria-hidden="true" aria-labelledby="gamePermissionsModal" bsmodal="" class="modal fade in"
                            id="ModalEditPermissions" role="dialog" tabindex="-1" style="overflow-y: auto;">
                            <div class="modal-dialog modal-md">
                                <div class="modal-content">
                                    <form>
                                        <div class="modal-header">
                                            <h4 class="modal-title"><span
                                                    style="padding-bottom: 4px;border-bottom: 1px solid #848484;">Detalle
                                                    Permisos</span></h4>
                                            <button aria-label="Close" class="close" type="button"
                                                @click="closeModal"><span aria-hidden="true">×</span></button>
                                        </div>
                                        <div class="modal-body pb-1">
                                            <div class="form-group ">
                                                <div class="input-group">
                                                    <div class="input-group-prepend"><span class="input-group-text"
                                                            id="basic-addon2"><i class="flaticon-users"></i></span>
                                                    </div>
                                                    <input class="form-control w-90" disabled="" name="UserName"
                                                        :value="edit_user_data.username" type="text">
                                                </div>
                                            </div>
                                            <div class="accordion" id="accordionCasino">
                                                <div class="">
                                                    <div class="card-title collapsed py-2 ng-star-inserted borderPermission"
                                                        id="headingCasino">
                                                        <div class="kt-widget4 w-100">
                                                            <div class="kt-widget4__item pt-0 mb-0 pb-1">
                                                                <a class="btn la la-angle-double-down col-prov" href="#"
                                                                    style="padding: 0 5px 0 5px;font-size:  170%"
                                                                    id="card-section-Casino--collapse" type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseCasino"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseCasino"></a>
                                                                <div style="width: 100%;margin-left: 20px;"><a
                                                                        class="widget4__username col-prov"
                                                                        style="font-size: 14px;">Casino</a></div>
                                                                <a class="btn la la-toggle-on col-prov ng-star-inserted"
                                                                    style="padding: 0 5px 0 5px;font-size:  200%"></a>
                                                                <input v-model="edit_user_data.proveedores.casinoTotal"
                                                                    class="form-control slider ng-star-inserted"
                                                                    readonly="" style="width: 20%;" type="text"
                                                                    disabled="">
                                                                <a href="javascript:;" @click="setPermission('Casino')"
                                                                    class="ng-star-inserted"><img class="mr-2 img-fluid"
                                                                        src="/ganaencasa/img/Change Permission.svg"></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="collapseCasino" class="accordion-collapse collapse"
                                                        aria-labelledby="headingCasino"
                                                        data-bs-parent="#accordionCasino">
                                                        <div style="overflow:auto">
                                                            <div v-for="(brand, index) in edit_user_data.proveedores.casino"
                                                                :key="index"
                                                                class="prov-style kt-widget4__item ml-2 pt-1 mb-0 pb-1 ng-star-inserted borderPermission">
                                                                <div class="kt-widget4 w-100">
                                                                    <div class="kt-widget4__item pt-0 mb-0 pb-1">
                                                                        <div style="width: 100%;margin-left: 20px;">
                                                                            <a class="widget4__username col-game"
                                                                                style="font-size: 13px;">{{ brand.id
                                                                                }}</a>
                                                                        </div>
                                                                        <a :class="edit_user_data.proveedores.casino[index].status ? 'la-toggle-on' : 'la-toggle-off'"
                                                                            class="btn la col-game ng-star-inserted"
                                                                            style="padding: 0 5px 0 5px;font-size:  200%;"></a>
                                                                        <input :id="'EditUserCommissionId' + index"
                                                                            v-model="edit_user_data.proveedores.casino[index].comision"
                                                                            class="form-control slider ng-star-inserted"
                                                                            readonly="" style="width: 20%;" type="text"
                                                                            disabled="">
                                                                        <a href="javascript:;"
                                                                            @click="setPermission(brand.id, index)"
                                                                            class="ng-star-inserted"><img
                                                                                class="mr-2 img-fluid"
                                                                                src="/ganaencasa/img/Change Permission.svg"></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion" id="accordionCasinoLive">
                                                <div class="">
                                                    <div class="card-title collapsed py-2 ng-star-inserted borderPermission"
                                                        id="headingCasinoLive">
                                                        <div class="kt-widget4 w-100">
                                                            <div class="kt-widget4__item pt-0 mb-0 pb-1">
                                                                <a class="btn la la-angle-double-down col-prov" href="#"
                                                                    style="padding: 0 5px 0 5px;font-size:  170%"
                                                                    id="card-section-CasinoLive--collapse" type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseCasinoLive"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseCasinoLive"></a>
                                                                <div style="width: 100%;margin-left: 20px;"><a
                                                                        class="widget4__username col-prov"
                                                                        style="font-size: 14px;">Casino Vivo</a></div>
                                                                <a :class="edit_user_data.proveedores.casinoLive.status ? 'la-toggle-on' : 'la-toggle-off'"
                                                                    class="btn la col-prov ng-star-inserted"
                                                                    style="padding: 0 5px 0 5px;font-size:  200%"></a>
                                                                <input v-model="edit_user_data.proveedores.casinoTotal"
                                                                    class="form-control slider ng-star-inserted"
                                                                    readonly="" style="width: 20%;" type="text"
                                                                    disabled="">
                                                                <a href="javascript:;"
                                                                    @click="setPermission('Casino Vivo')"
                                                                    class="ng-star-inserted"><img class="mr-2 img-fluid"
                                                                        src="/ganaencasa/img/Change Permission.svg"></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="collapseCasinoLive" class="accordion-collapse collapse"
                                                        aria-labelledby="headingCasinoLive"
                                                        data-bs-parent="#accordionCasinoLive">
                                                        <div style="overflow:auto">
                                                            <div v-for="(brand, index) in edit_user_data.proveedores.casino.filter(item => item.id === 'live_VG' || item.id === 'ezugi_EZ' || item.id === 'pragmatic_PMLC')"
                                                                :key="index"
                                                                class="prov-style kt-widget4__item ml-2 pt-1 mb-0 pb-1 ng-star-inserted borderPermission">
                                                                <div class="kt-widget4 w-100">
                                                                    <div class="kt-widget4__item pt-0 mb-0 pb-1">
                                                                        <div style="width: 100%;margin-left: 20px;">
                                                                            <a class="widget4__username col-game"
                                                                                style="font-size: 13px;">{{ brand.id
                                                                                }}</a>
                                                                        </div>
                                                                        <a :class="edit_user_data.proveedores.casino[index].status ? 'la-toggle-on' : 'la-toggle-off'"
                                                                            class="btn la col-game ng-star-inserted"
                                                                            style="padding: 0 5px 0 5px;font-size:  200%;"></a>
                                                                        <input :id="'EditUserCommissionId' + index"
                                                                            v-model="edit_user_data.proveedores.casino[index].comision"
                                                                            class="form-control slider ng-star-inserted"
                                                                            readonly="" style="width: 20%;" type="text"
                                                                            disabled="">
                                                                        <a href="javascript:;"
                                                                            @click="setPermission(brand.id, index)"
                                                                            class="ng-star-inserted"><img
                                                                                class="mr-2 img-fluid"
                                                                                src="/ganaencasa/img/Change Permission.svg"></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion" id="accordionPoker">
                                                <div class="">
                                                    <div class="card-title collapsed py-2 ng-star-inserted borderPermission"
                                                        id="headingPoker">
                                                        <div class="kt-widget4 w-100">
                                                            <div class="kt-widget4__item pt-0 mb-0 pb-1">
                                                                <a class="btn la la-angle-double-down col-prov" href="#"
                                                                    style="padding: 0 5px 0 5px;font-size:  170%"
                                                                    id="card-section-Poker--collapse" type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapsePoker"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapsePoker"></a>
                                                                <div style="width: 100%;margin-left: 20px;"><a
                                                                        class="widget4__username col-prov"
                                                                        style="font-size: 14px;">Poker</a></div>
                                                                <a :class="edit_user_data.proveedores.poker.status ? 'la-toggle-on' : 'la-toggle-off'"
                                                                    class="btn la col-prov ng-star-inserted"
                                                                    style="padding: 0 5px 0 5px;font-size:  200%"></a>
                                                                <input v-model="edit_user_data.proveedores.pokerTotal"
                                                                    class="form-control slider ng-star-inserted"
                                                                    readonly="" style="width: 20%;" type="text"
                                                                    disabled="">
                                                                <a href="javascript:;" @click="setPermission('Poker')"
                                                                    class="ng-star-inserted"><img class="mr-2 img-fluid"
                                                                        src="/ganaencasa/img/Change Permission.svg"></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="collapsePoker" class="accordion-collapse collapse"
                                                        aria-labelledby="headingPoker" data-bs-parent="#accordionPoker">
                                                        <div style="overflow:auto">
                                                            <div
                                                                class="prov-style kt-widget4__item ml-2 pt-1 mb-0 pb-1 ng-star-inserted borderPermission">
                                                                <div class="kt-widget4 w-100">
                                                                    <div class="kt-widget4__item pt-0 mb-0 pb-1">
                                                                        <div style="width: 100%;margin-left: 20px;">
                                                                            <a class="widget4__username col-game"
                                                                                style="font-size: 13px;">Poker</a>
                                                                        </div>
                                                                        <a :class="edit_user_data.proveedores.poker.status ? 'la-toggle-on' : 'la-toggle-off'"
                                                                            class="btn la col-game ng-star-inserted"
                                                                            style="padding: 0 5px 0 5px;font-size:  200%;"></a>
                                                                        <input
                                                                            v-model="edit_user_data.proveedores.poker.comision"
                                                                            class="form-control slider ng-star-inserted"
                                                                            readonly="" style="width: 20%;" type="text"
                                                                            disabled="">
                                                                        <a href="javascript:;"
                                                                            @click="setPermission('Poker')"
                                                                            class="ng-star-inserted"><img
                                                                                class="mr-2 img-fluid"
                                                                                src="/ganaencasa/img/Change Permission.svg"></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion" id="accordionHipicas">
                                                <div class="">
                                                    <div class="card-title collapsed py-2 ng-star-inserted borderPermission"
                                                        id="headingHipicas">
                                                        <div class="kt-widget4 w-100">
                                                            <div class="kt-widget4__item pt-0 mb-0 pb-1">
                                                                <a class="btn la la-angle-double-down col-prov" href="#"
                                                                    style="padding: 0 5px 0 5px;font-size:  170%"
                                                                    id="card-section-Hipicas--collapse" type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseHipicas"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseHipicas"></a>
                                                                <div style="width: 100%;margin-left: 20px;"><a
                                                                        class="widget4__username col-prov"
                                                                        style="font-size: 14px;">Hipicas</a></div>
                                                                <a :class="edit_user_data.proveedores.hipicas.status ? 'la-toggle-on' : 'la-toggle-off'"
                                                                    class="btn la col-prov ng-star-inserted"
                                                                    style="padding: 0 5px 0 5px;font-size:  200%"></a>
                                                                <input
                                                                    v-model="edit_user_data.proveedores.hipicas.comision"
                                                                    class="form-control slider ng-star-inserted"
                                                                    readonly="" style="width: 20%;" type="text"
                                                                    disabled="">
                                                                <a href="javascript:;" @click="setPermission('Hipicas')"
                                                                    class="ng-star-inserted"><img class="mr-2 img-fluid"
                                                                        src="/ganaencasa/img/Change Permission.svg"></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="collapseHipicas" class="accordion-collapse collapse"
                                                        aria-labelledby="headingHipicas"
                                                        data-bs-parent="#accordionHipicas">
                                                        <div style="overflow:auto">
                                                            <div
                                                                class="prov-style kt-widget4__item ml-2 pt-1 mb-0 pb-1 ng-star-inserted borderPermission">
                                                                <div class="kt-widget4 w-100">
                                                                    <div class="kt-widget4__item pt-0 mb-0 pb-1">
                                                                        <div style="width: 100%;margin-left: 20px;">
                                                                            <a class="widget4__username col-game"
                                                                                style="font-size: 13px;">Hipicas</a>
                                                                        </div>
                                                                        <a :class="edit_user_data.proveedores.hipicas.status ? 'la-toggle-on' : 'la-toggle-off'"
                                                                            class="btn la col-game ng-star-inserted"
                                                                            style="padding: 0 5px 0 5px;font-size:  200%;"></a>
                                                                        <input
                                                                            v-model="edit_user_data.proveedores.hipicas.comision"
                                                                            class="form-control slider ng-star-inserted"
                                                                            readonly="" style="width: 20%;" type="text"
                                                                            disabled="">
                                                                        <a href="javascript:;"
                                                                            @click="setPermission('Hipicas')"
                                                                            class="ng-star-inserted"><img
                                                                                class="mr-2 img-fluid"
                                                                                src="/ganaencasa/img/Change Permission.svg"></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion" id="accordionDeportes">
                                                <div class="">
                                                    <div class="card-title collapsed py-2 ng-star-inserted borderPermission"
                                                        id="headingDeportes">
                                                        <div class="kt-widget4 w-100">
                                                            <div class="kt-widget4__item pt-0 mb-0 pb-1">
                                                                <a class="btn la la-angle-double-down col-prov" href="#"
                                                                    style="padding: 0 5px 0 5px;font-size:  170%"
                                                                    id="card-section-Deportes--collapse" type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseDeportes"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseDeportes"></a>
                                                                <div style="width: 100%;margin-left: 20px;"><a
                                                                        class="widget4__username col-prov"
                                                                        style="font-size: 14px;">Deportes</a></div>
                                                                <a :class="edit_user_data.proveedores.deportes.status ? 'la-toggle-on' : 'la-toggle-off'"
                                                                    class="btn la col-prov ng-star-inserted"
                                                                    style="padding: 0 5px 0 5px;font-size:  200%"></a>
                                                                <input
                                                                    v-model="edit_user_data.proveedores.deportesTotal"
                                                                    class="form-control slider ng-star-inserted"
                                                                    readonly="" style="width: 20%;" type="text"
                                                                    disabled="">
                                                                <a href="javascript:;"
                                                                    @click="setPermission('Deportes')"
                                                                    class="ng-star-inserted"><img class="mr-2 img-fluid"
                                                                        src="/ganaencasa/img/Change Permission.svg"></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="collapseDeportes" class="accordion-collapse collapse"
                                                        aria-labelledby="headingDeportes"
                                                        data-bs-parent="#accordionDeportes">
                                                        <div style="overflow:auto">
                                                            <div
                                                                class="prov-style kt-widget4__item ml-2 pt-1 mb-0 pb-1 ng-star-inserted borderPermission">
                                                                <div class="kt-widget4 w-100">
                                                                    <div class="kt-widget4__item pt-0 mb-0 pb-1">
                                                                        <div style="width: 100%;margin-left: 20px;">
                                                                            <a class="widget4__username col-game"
                                                                                style="font-size: 13px;">Deportes</a>
                                                                        </div>
                                                                        <a :class="edit_user_data.proveedores.deportes.status ? 'la-toggle-on' : 'la-toggle-off'"
                                                                            class="btn la col-game ng-star-inserted"
                                                                            style="padding: 0 5px 0 5px;font-size:  200%;"></a>
                                                                        <input
                                                                            v-model="edit_user_data.proveedores.deportes.comision"
                                                                            class="form-control slider ng-star-inserted"
                                                                            readonly="" style="width: 20%;" type="text"
                                                                            disabled="">
                                                                        <a href="javascript:;"
                                                                            @click="setPermission('Deportes')"
                                                                            class="ng-star-inserted"><img
                                                                                class="mr-2 img-fluid"
                                                                                src="/ganaencasa/img/Change Permission.svg"></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button class="btn btn-default waves-effect waves-light" @click="closeModal"
                                                type="button">Cancelar</button>
                                        </div>
                                        <div class="clear-providers" id="EditUserCommissions"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div id="ModalEditPermissions2" class="modal fade in" role="dialog" tabindex="-1">
                            <div class="modal-dialog modal-md">
                                <div class="modal-content">
                                    <form v-on:submit.prevent="updateUserInfo">
                                        <input type="hidden" name="id">
                                        <input type="hidden" name="service">
                                        <div class="modal-header">
                                            <h4 class="modal-title"><span
                                                    style="padding-bottom: 4px;border-bottom: 1px solid #848484;">Cambiar
                                                    permisos</span></h4>
                                            <button aria-label="Close" class="close" type="button"
                                                @click="closeModalPermisions"><span aria-hidden="true">×</span></button>
                                        </div>
                                        <div class="modal-body pb-1">
                                            <div class="form-group ">
                                                <div class="input-group">
                                                    <div class="input-group-prepend"><span class="input-group-text"
                                                            id="basic-addon2"><i class="flaticon-users"></i></span>
                                                    </div>
                                                    <input class="form-control w-90" disabled="" name="UserName"
                                                        v-model="edit_user_data.username" type="text">
                                                </div>
                                            </div>
                                            <div class="modal-body pb-1">
                                                <div class="row">
                                                    <div class="col-md-11 mx-auto">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend"><span
                                                                        class="input-group-text"
                                                                        id="basic-addon2">Permiso</span>
                                                                </div>
                                                                <input class="form-control" disabled="true"
                                                                    id="txt_permissionName" name="Permiso"
                                                                    v-model="permission.name" placeholder="Permiso"
                                                                    type="text">
                                                            </div>
                                                        </div>
                                                        <div class="form-group ng-star-inserted" style="">
                                                            <div v-if="permission.name == 'Casino'">
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend"><span
                                                                            class="input-group-text"
                                                                            id="basic-addon2">Comision</span>
                                                                    </div>
                                                                    <input class="form-control slider"
                                                                        v-model="edit_user_data.proveedores.casinoTotal"
                                                                        @input="setCasinoComision('edit')"
                                                                        name="Commission" style="width: 20%;"
                                                                        type="number" step="0.01"
                                                                        :disabled="!edit_user_data.proveedores.casinoStatus">
                                                                </div>
                                                            </div>
                                                            <div v-else-if="permission.name == 'Casino Vivo'">
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend"><span
                                                                            class="input-group-text"
                                                                            id="basic-addon2">Comision</span>
                                                                    </div>
                                                                    <input class="form-control slider"
                                                                        v-model="edit_user_data.proveedores.casinoTotal"
                                                                        @input="setCasinoComision('edit')"
                                                                        name="Commission" style="width: 20%;"
                                                                        type="number" step="0.01"
                                                                        :disabled="!edit_user_data.proveedores.casinoStatus">
                                                                </div>
                                                            </div>
                                                            <div v-else-if="permission.name == 'Deportes'">
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend"><span
                                                                            class="input-group-text"
                                                                            id="basic-addon2">Comision</span>
                                                                    </div>
                                                                    <input class="form-control slider"
                                                                        v-model="edit_user_data.proveedores.deportes.comision"
                                                                        @input="setCasinoComision('edit')"
                                                                        name="Commission" style="width: 20%;"
                                                                        type="number" step="0.01"
                                                                        :disabled="!edit_user_data.proveedores.deportes.status">
                                                                </div>
                                                            </div>
                                                            <div v-else-if="permission.name == 'Poker'">
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend"><span
                                                                            class="input-group-text"
                                                                            id="basic-addon2">Comision</span>
                                                                    </div>
                                                                    <input class="form-control slider"
                                                                        v-model="edit_user_data.proveedores.poker.comision"
                                                                        @input="setCasinoComision('edit')"
                                                                        name="Commission" style="width: 20%;"
                                                                        type="number" step="0.01"
                                                                        :disabled="!edit_user_data.proveedores.poker.status">
                                                                </div>
                                                            </div>
                                                            <div v-else-if="permission.name == 'Hipicas'">
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend"><span
                                                                            class="input-group-text"
                                                                            id="basic-addon2">Comision</span>
                                                                    </div>
                                                                    <input class="form-control slider"
                                                                        v-model="edit_user_data.proveedores.hipicas.comision"
                                                                        @input="setCasinoComision('edit')"
                                                                        name="Commission" style="width: 20%;"
                                                                        type="number" step="0.01"
                                                                        :disabled="!edit_user_data.proveedores.poker.status">
                                                                </div>
                                                            </div>
                                                            <div
                                                                v-else-if="edit_user_data.proveedores.casino[permission.index]">
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend"><span
                                                                            class="input-group-text"
                                                                            id="basic-addon2">Comision</span>
                                                                    </div>
                                                                    <input class="form-control slider"
                                                                        v-model="edit_user_data.proveedores.casino[permission.index].comision"
                                                                        @input="setCasinoComision('edit')"
                                                                        name="Commission" style="width: 20%;"
                                                                        type="number" step="0.01" disabled>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend"><span
                                                                        class="input-group-text" id="basic-addon2">{{
            permission.status ? 'Habilitado' :
                'Deshabilitado' }}</span>
                                                                </div>
                                                                <div v-if="permission.name == 'Casino'">
                                                                    <a :class="edit_user_data.proveedores.casinoStatus ? 'la-toggle-on' : 'la-toggle-off'"
                                                                        class="btn la col-prov ng-star-inserted isEnabled waves-effect waves-light"
                                                                        style="padding: 0px 5px; font-size: 200%;"
                                                                        @click="edit_user_data.proveedores.casinoStatus = !edit_user_data.proveedores.casinoStatus"></a>
                                                                </div>
                                                                <div v-else-if="permission.name == 'Deportes'">
                                                                    <a :class="edit_user_data.proveedores.deportes.status ? 'la-toggle-on' : 'la-toggle-off'"
                                                                        class="btn la col-prov ng-star-inserted isEnabled waves-effect waves-light"
                                                                        style="padding: 0px 5px; font-size: 200%;"
                                                                        @click="edit_user_data.proveedores.deportes.status = !edit_user_data.proveedores.deportes.status"></a>
                                                                </div>
                                                                <div v-else-if="permission.name == 'Poker'">
                                                                    <a :class="edit_user_data.proveedores.poker.status ? 'la-toggle-on' : 'la-toggle-off'"
                                                                        class="btn la col-prov ng-star-inserted isEnabled waves-effect waves-light"
                                                                        style="padding: 0px 5px; font-size: 200%;"
                                                                        @click="edit_user_data.proveedores.poker.status = !edit_user_data.proveedores.poker.status"></a>
                                                                </div>
                                                                <div v-else-if="permission.name == 'Casino Vivo'">
                                                                    <a :class="edit_user_data.proveedores.casinoLive.status ? 'la-toggle-on' : 'la-toggle-off'"
                                                                        class="btn la col-prov ng-star-inserted isEnabled waves-effect waves-light"
                                                                        style="padding: 0px 5px; font-size: 200%;"
                                                                        @click="edit_user_data.proveedores.casinoLive.status = !edit_user_data.proveedores.casinoLive.status"></a>
                                                                </div>
                                                                <div v-else-if="permission.name == 'Hipicas'">
                                                                    <a :class="edit_user_data.proveedores.hipicas.status ? 'la-toggle-on' : 'la-toggle-off'"
                                                                        class="btn la col-prov ng-star-inserted isEnabled waves-effect waves-light"
                                                                        style="padding: 0px 5px; font-size: 200%;"
                                                                        @click="edit_user_data.proveedores.hipicas.status = !edit_user_data.proveedores.hipicas.status"></a>
                                                                </div>
                                                                <div
                                                                    v-else-if="edit_user_data.proveedores.casino[permission.index]">
                                                                    <a :class="edit_user_data.proveedores.casino[permission.index].status ? 'la-toggle-on' : 'la-toggle-off'"
                                                                        class="btn la col-prov ng-star-inserted isEnabled waves-effect waves-light"
                                                                        style="padding: 0px 5px; font-size: 200%;"
                                                                        @click="edit_user_data.proveedores.casino[permission.index].status = !edit_user_data.proveedores.casino[permission.index].status"></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!---->
                                            <div class="accordion mt-2 ng-star-inserted" style="">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <div class="kt-widget4 w-100">
                                                            <div class="kt-widget4__item pt-2 xs mb-0 pb-1">
                                                                <div>
                                                                    <a class="kt-widget4__item pl-3 pt-0 mb-0 pb-1"
                                                                        style="font-weight: 500;letter-spacing: 1px;;font-size: 80%;">
                                                                        Copiar permisos a los usuarios hijos
                                                                    </a>
                                                                </div>
                                                                <!-- <a class="btn la la-toggle-off col-game ng-star-inserted waves-effect waves-light"
                                                                    id="copyPerm"
                                                                    style="padding: 0 15px 0 5px;font-size: 150%;"></a> -->
                                                                <a class="btn la la-toggle-on col-game ng-star-inserted"
                                                                    href="#" id="copyPerm"
                                                                    style="padding: 0px 15px 0px 5px; font-size: 150%;"></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button class="btn btn-default waves-effect waves-light"
                                                @click="closeModalPermisions" type="button">Cancelar</button>
                                            <button class="btn btn-primary waves-effect waves-light" type="submit"><i
                                                    class="fa fa-save"></i><span>Guardar</span></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div aria-hidden="true" id="paymentModal" bsmodal="" class="modal fade in" role="dialog"
                            tabindex="-1">
                            <div class="modal-dialog modal-md">
                                <div class="modal-content"><!---->
                                    <form v-on:submit.prevent="dispatchAction">
                                        <div class="modal-header">
                                            <h4 class="modal-title"><span style="padding-bottom: 4px;">Pago</span>
                                            </h4>
                                            <button aria-label="Close" class="close" type="button"
                                                @click="closeModal"><span aria-hidden="true">×</span></button>
                                        </div>
                                        <div class="modal-body pb-1">
                                            <div class="input-group input-group-md mb-3">
                                                <div class="input-group-prepend"><span class="input-group-text px-3"
                                                        style="min-width: 85px;">Usuario</span></div>
                                                <input
                                                    class="form-control px-txt_id mx-0 text-center ng-untouched ng-pristine"
                                                    disabled="" enabled="false" id="label_balance"
                                                    :value="getUserData(target_user_id)?.username" type="text">
                                            </div>
                                            <div class="input-group input-group-md mb-3">
                                                <div class="input-group-prepend"><span class="input-group-text px-3"
                                                        style="min-width: 85px;">Monto</span></div>
                                                <input class="form-control ng-untouched ng-pristine ng-invalid w-70"
                                                    @input="actionsParseNumbers('','text_monto')" id="text_monto"
                                                    mask="separator.0" maxlength="32" name="monto"
                                                    oninput="this.value = this.value.replace(/[^0-9\\.]/g, '')"
                                                    required="" separatorlimit="0" showmask="true" thousandseparator="."
                                                    type="text">
                                            </div>
                                            <div class="input-group input-group-md mb-3">
                                                <div class="input-group-prepend"><span class="input-group-text px-3"
                                                        style="min-width: 85px;">Comentario</span></div>
                                                <input class="form-control ng-untouched ng-pristine ng-valid"
                                                    v-model="actions.comment" id="text_comment" name="comment"
                                                    type="text">
                                            </div>
                                            <fieldset class="form-group">
                                                <div class="ml-2 row">
                                                    <div class="col-form-label col-sm-2 pt-0">Moneda</div>
                                                    <div class="col-sm-10">
                                                        <div class="kt-radio-inline">
                                                            <label class="kt-radio">
                                                                <input checked id="localCurrency" name="currency"
                                                                    type="radio" value="ARS"> ARS
                                                                <span></span>
                                                            </label><!----><!---->
                                                            <label class="kt-radio ng-star-inserted">
                                                                <input name="currency" type="radio" value="USD"
                                                                    disabled> USD
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <fieldset class="form-group">
                                                <div class="ml-2 row">
                                                    <div class="col-form-label col-sm-2 pt-0">Acción</div>
                                                    <div class="col-sm-10">
                                                        <div class="kt-radio-inline">
                                                            <label class="kt-radio">
                                                                <input checked="" id="userPayMe" name="paymentRadio"
                                                                    @click="setAction('Cobro','userPayMe')" type="radio"
                                                                    value="true">{{
                                                                getUserData(target_user_id)?.username + ' ' }} me está
                                                                pagando
                                                                <span></span>
                                                            </label>
                                                            <label class="kt-radio">
                                                                <input id="iPayUser" name="paymentRadio" type="radio"
                                                                    @click="setAction('Pago','iPayUser')" value="false">
                                                                Estoy pagando a {{ ' ' +
                                                                getUserData(target_user_id)?.username }}
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div class="modal-footer">
                                            <button class="btn btn-default waves-effect waves-light" @click="closeModal"
                                                type="button">Cancelar</button>
                                            <button class="btn btn-primary waves-effect waves-light" type="submit">
                                                <i class="fa fa-save"></i><span>Confirmar</span></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div aria-hidden="true" id="manualAdjustmentsModal" bsmodal="" class="modal fade in show"
                            role="dialog" tabindex="-1">
                            <div class="modal-dialog modal-md">
                                <div class="modal-content"><!---->
                                    <form v-on:submit.prevent="dispatchAction">
                                        <div class="modal-header">
                                            <h4 class="modal-title"><span style="padding-bottom: 4px;">Ajustes
                                                    manuales</span>
                                            </h4>
                                            <button aria-label="Close" class="close" type="button"
                                                @click="closeModal"><span aria-hidden="true">×</span></button>
                                        </div>
                                        <div class="modal-body pb-1">
                                            <div class="input-group input-group-md mb-3">
                                                <div class="input-group-prepend"><span class="input-group-text px-3"
                                                        id="lbl_user" style="min-width: 75px;">Usuario</span></div>
                                                <input
                                                    class="form-control px-txt_id mx-0 text-center ng-untouched ng-pristine"
                                                    :value="getUserData(target_user_id)?.username" disabled=""
                                                    enabled="false" type="text">
                                            </div>
                                            <div class="input-group input-group-md mb-3">
                                                <div class="input-group-prepend"><span class="input-group-text px-3"
                                                        style="min-width: 75px; width: 70%;">Monto</span>
                                                </div>
                                                <input class="form-control ng-untouched ng-pristine ng-invalid"
                                                    id="manualActionsValue" mask="separator.0" maxlength="32"
                                                    name="monto" @input="actionsParseNumbers('','manualActionsValue')"
                                                    oninput="this.value = this.value.replace(/[^0-9\\.]/g, '')"
                                                    required="" separatorlimit="0" showmask="true" thousandseparator="."
                                                    type="text">
                                            </div>
                                            <div class="input-group input-group-md mb-3">
                                                <div class="input-group-prepend"><span class="input-group-text px-3"
                                                        style="min-width: 75px;">Comentario</span></div>
                                                <input class="form-control ng-untouched ng-pristine ng-valid"
                                                    v-model="actions.comment" id="txt_comment" name="comment"
                                                    type="text">
                                            </div>
                                            <fieldset class="form-group">
                                                <div class="ml-2 row">
                                                    <div class="col-form-label col-sm-2 pt-0">Moneda</div>
                                                    <div class="col-sm-10">
                                                        <div class="kt-radio-inline">
                                                            <label class="kt-radio">
                                                                <input checked id="mLocalCurrency" name="currencyMA"
                                                                    type="radio" value="ARS"> ARS
                                                                <span></span>
                                                            </label><!----><!---->
                                                            <label class="kt-radio ng-star-inserted">
                                                                <input name="currencyMA" type="radio" value="USD"
                                                                    disabled> USD
                                                                <span></span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <fieldset class="form-group">
                                                <div class="ml-2 row">
                                                    <div class="col-form-label col-sm-2 pt-0">Acción</div>
                                                    <div class="col-sm-10">
                                                        <div class="kt-radio-inline">
                                                            <label class="kt-radio">
                                                                <input checked="" id="increaseDebt"
                                                                    @click="setAction('Aumentar_deuda','increaseDebt')"
                                                                    name="manualTransactionRadio" type="radio"
                                                                    value="false"> Aumentar deuda
                                                                <span></span>
                                                            </label>
                                                            <label class="kt-radio">
                                                                <input id="reduceDebt" name="manualTransactionRadio"
                                                                    @click="setAction('Reducir_deuda','reduceDebt')"
                                                                    type="radio" value="true"> Reducir deuda
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div class="modal-footer">
                                            <button class="btn btn-default waves-effect waves-light" @click="closeModal"
                                                type="button">Cancelar</button>
                                            <button class="btn btn-primary waves-effect waves-light" type="submit"><i
                                                    class="fa fa-save"></i><span>Confirmar</span></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div aria-hidden="true" aria-labelledby="enableWithDrawDepositModal" bsmodal=""
                            class="modal fade in show" role="dialog" tabindex="-1">
                            <div class="modal-dialog modal-md">
                                <div class="modal-content"><!---->
                                    <form novalidate="" class="ng-untouched ng-pristine ng-valid ng-star-inserted"
                                        style="">
                                        <div class="modal-header">
                                            <h4 class="modal-title"><span style="padding-bottom: 4px;">Habilitar Retiro
                                                    &amp;
                                                    Depósito</span></h4>
                                            <button aria-label="Close" class="close" type="button"><span
                                                    aria-hidden="true">×</span></button>
                                        </div>
                                        <div class="modal-body pb-1">
                                            <div class="input-group input-group-md mb-3">
                                                <div class="input-group-prepend"><span class="input-group-text px-3"
                                                        id="lbl_user" style="min-width: 75px;">User</span></div>
                                                <input
                                                    class="form-control px-txt_id mx-0 text-center ng-untouched ng-pristine"
                                                    disabled="" enabled="false" type="text">
                                            </div>
                                            <fieldset class="form-group">
                                                <div class="ml-2 row">
                                                    <div
                                                        class="col-form-label col-sm-7 d-flex align-items-center justify-content-start">
                                                        Habilitar Retiro &amp; Depósito</div>
                                                    <div class="col-sm-5"><!----><!---->
                                                        <a class="btn la la-toggle-on col-prov ng-star-inserted waves-effect waves-light"
                                                            style="padding: 0 5px 0 5px;font-size:  200%"></a><!---->
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <fieldset class="form-group">
                                                <div class="ml-2 row">
                                                    <div
                                                        class="col-form-label col-sm-7 d-flex align-items-center justify-content-start">
                                                        Copy permissions to child users</div>
                                                    <div class="col-sm-5"><!----><!----><!---->
                                                        <a class="btn la la-toggle-off col-prov ng-star-inserted waves-effect waves-light"
                                                            style="padding: 0 5px 0 5px;font-size:  200%"></a>
                                                    </div>
                                                </div>
                                            </fieldset><!---->
                                        </div>
                                        <div class="modal-footer">
                                            <button class="btn btn-default waves-effect waves-light"
                                                type="button">Cancel</button>
                                            <button class="btn btn-primary waves-effect waves-light" type="submit"><i
                                                    class="fa fa-save"></i><span>Confirm</span></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div aria-hidden="true" aria-labelledby="settlementModal" bsmodal="" class="modal fade in show"
                            role="dialog" style="overflow-y: auto !important;" tabindex="-1">
                            <div class="modal-dialog modal-xl">
                                <div class="modal-content" style="box-shadow: 0px 6px 15px !important; ">
                                    <div class="modal-header custom-header">
                                        <h4 class="modal-title custom-title"><span>Settlement Configuration
                                                - </span></h4>
                                        <button aria-label="Close" class="close" type="button"><span
                                                aria-hidden="true">×</span></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="bs-stepper linear" id="stepper1">
                                            <div class="bs-stepper-header justify-content-center"><!---->
                                                <div class="step ng-star-inserted" style="">
                                                    <button class="active step-trigger"><span
                                                            class="bs-stepper-label">1.
                                                            Introduction</span></button>
                                                </div><!----><!---->
                                                <div class="step ng-star-inserted" style="">
                                                    <button class="step-trigger"><span class="bs-stepper-label">2.
                                                            Permission And
                                                            Commission</span></button>
                                                </div><!----><!---->
                                                <div class="step ng-star-inserted" style="">
                                                    <button class="step-trigger"><span class="bs-stepper-label">3.
                                                            Commission
                                                            Type</span></button>
                                                </div><!----><!---->
                                                <div class="ng-star-inserted" style=""><!---->
                                                    <div class="step ng-star-inserted">
                                                        <button class="step-trigger"><span class="bs-stepper-label">4.
                                                                Period</span></button>
                                                    </div><!---->
                                                </div><!---->
                                                <div class="ng-star-inserted" style=""><!---->
                                                    <div class="step ng-star-inserted">
                                                        <button class="step-trigger"><span class="bs-stepper-label">5.
                                                                Type Of
                                                                Bank</span></button>
                                                    </div><!---->
                                                </div><!----><!---->
                                                <div class="step ng-star-inserted" style="">
                                                    <button class="step-trigger"><span class="bs-stepper-label">7.
                                                            Selected
                                                            Details</span></button>
                                                </div><!---->
                                            </div>
                                            <div class="bs-stepper-content" style="padding-bottom: unset !important;">
                                                <form novalidate="" class="ng-untouched ng-pristine ng-valid"><!---->
                                                    <div class="ng-star-inserted" style="">
                                                        <br><br><br>

                                                        <span>This is a step-by-step process to configure
                                                            the periodic settlements of your agents and / or
                                                            sellers, depending on the configuration you
                                                            choose, the form of settlement will be. Read
                                                            each step carefully to make sure you understand
                                                            what you are doing, if you make a mistake, don't
                                                            worry, you can always change this
                                                            setting.</span>
                                                        <div class="modal-footer mt-5">
                                                            <button class="btn btn-primary waves-effect waves-light"
                                                                type="submit">Next</button>
                                                        </div>

                                                    </div>
                                                </form><!----><!----><!----><!----><!----><!---->

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div aria-hidden="true" aria-labelledby="addSkinModal" bsmodal="" class="modal fade in show"
                            role="dialog" tabindex="-1">
                            <div class="modal-dialog modal-md">
                                <div class="modal-content"><!---->
                                    <form novalidate="" class="ng-untouched ng-pristine ng-star-inserted ng-invalid"
                                        style="">
                                        <div class="modal-header">
                                            <h4 class="modal-title"><span
                                                    style="padding-bottom: 4px;border-bottom: 1px solid #848484;">Agregar
                                                    Skin</span></h4>
                                            <button aria-label="Close" class="close" type="button"><span
                                                    aria-hidden="true">×</span></button>
                                        </div>
                                        <div class="modal-body pb-1"><!----><!----></div>
                                        <div class="modal-body pb-1" style="display: flex; justify-content: center;">
                                            <select class="form-control w-90 ng-untouched ng-pristine ng-invalid"
                                                id="selectedSkinId" name="selectedSkinId" placeholder="Skin"
                                                required="">
                                                <option value="-1">Select a skin</option><!---->
                                            </select>
                                        </div>
                                        <div class="modal-footer">
                                            <button class="btn btn-default waves-effect waves-light"
                                                type="button">Cancel</button>
                                            <button class="btn btn-primary waves-effect waves-light" type="submit"><i
                                                    class="fa fa-save"></i><span>Agregar
                                                    Skin</span></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div aria-hidden="true" aria-labelledby="redirectVersionModal" bsmodal=""
                            class="modal fade in show" role="dialog" tabindex="-1">
                            <div class="modal-dialog modal-md">
                                <div class="modal-content"><!---->
                                    <form novalidate="" class="ng-untouched ng-pristine ng-valid ng-star-inserted"
                                        style="">
                                        <div class="modal-header">
                                            <h4 class="modal-title"><span
                                                    style="padding-bottom: 4px;border-bottom: 1px solid #848484;">Nuevo
                                                    Panel de Agentes Disponible</span></h4>
                                            <button aria-label="Close" class="close" type="button"><span
                                                    aria-hidden="true">×</span></button>
                                        </div>
                                        <div class="modal-body pb-1"> Le invitamos a probar el nuevo panel
                                            de agentes. Es más fácil de usar y tiene nuevas herramientas
                                            como un tablero de ventas, acciones rápidas,
                                            chat de agentes, y muchas cosas más. Además continuaremos
                                            entregando mejoras para usted.
                                        </div>
                                        <div class="modal-footer">
                                            <button class="btn btn-default waves-effect waves-light"
                                                type="button">Cancel</button>
                                            <button class="btn btn-primary waves-effect waves-light"
                                                type="submit"><span>IR
                                                    AL NUEVO
                                                    PANEL</span></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div aria-hidden="true" aria-labelledby="reset2FAModal" bsmodal="" class="modal fade in show"
                            role="dialog" tabindex="-1">
                            <div class="modal-dialog modal-md">
                                <div class="modal-content"><!---->
                                    <form novalidate="" class="ng-untouched ng-pristine ng-valid ng-star-inserted"
                                        style="">
                                        <div class="modal-header">
                                            <h4 class="modal-title"><span
                                                    style="padding-bottom: 4px;border-bottom: 1px solid #848484;">Reset
                                                    two factor authentication</span></h4>
                                            <button aria-label="Close" class="close" type="button"><span
                                                    aria-hidden="true">×</span></button>
                                        </div>
                                        <div class="modal-body pb-1">
                                            <div class="row">
                                                <div class="col-md-11 mx-auto">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend"><span
                                                                    class="input-group-text"
                                                                    id="basic-addon2">Name</span></div>
                                                            <input class="form-control" disabled="true" name="UserName"
                                                                placeholder="Username" type="text">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button class="btn btn-default waves-effect waves-light"
                                                type="button">Cancel</button>
                                            <button class="btn btn-primary waves-effect waves-light" type="submit"><i
                                                    class="fa fa-save"></i><span>Accept</span></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div aria-labelledby="createAgentModal" bsmodal="" class="modal fade in" role="dialog"
                            tabindex="-1" id="ModalEditUser">
                            <div class="modal-dialog modal-md">
                                <div class="modal-content"><!---->
                                    <form v-on:submit.prevent="updateUserInfo">
                                        <div class="modal-header">
                                            <h4 class="modal-title">
                                                <span
                                                    style="padding-bottom: 4px;border-bottom: 1px solid #848484;">Modificar
                                                    Agente</span>
                                            </h4>
                                            <button aria-label="Close" class="close" type="button"
                                                @click="closeModal"><span aria-hidden="true">×</span></button>
                                        </div>
                                        <div class="modal-body pb-1"><!----><!---->
                                            <div class="form-group ng-star-inserted">
                                                <div class="input-group">
                                                    <div class="input-group-prepend"><span class="input-group-text"
                                                            id="basic-addon2">Rol</span></div>
                                                    <div btnradiogroup=""
                                                        class="btn-group ng-untouched ng-pristine ng-valid"
                                                        style="padding: 0px !important;">
                                                        <div class="form-control" style="padding: 0px !important;">
                                                            <div class="form-check form-check-inline"
                                                                style="margin-left: 10px;">
                                                                <input btnradio="Vendedor" class="active" disabled
                                                                    id="inlineRadio1-1" name="roleName" type="radio"
                                                                    value="Vendedor" aria-pressed="true">
                                                                <label class="form-check-label"
                                                                    for="inlineRadio1-1">&nbsp;VENDEDOR</label>
                                                            </div>
                                                            <div class="form-check form-check-inline">
                                                                <input btnradio="Agente" class="" checked=""
                                                                    id="inlineRadio12-1" name="roleName" type="radio"
                                                                    value="Agente" aria-pressed="false">
                                                                <label class="form-check-label"
                                                                    for="inlineRadio2-1">&nbsp;AGENTE</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group ">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon2"><i
                                                                class="flaticon-users"></i></span>
                                                    </div>
                                                    <input v-model="edit_user_data.username"
                                                        class="form-control w-90 ng-untouched ng-pristine ng-invalid"
                                                        name="UserName" placeholder="Nombre de usuario" required=""
                                                        type="text" disabled="disabled">
                                                </div>
                                            </div><!---->
                                            <div class="form-group ng-star-inserted">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon2"><i
                                                                class="flaticon-users"></i></span>
                                                    </div>
                                                    <input v-model="edit_user_data.email"
                                                        aria-describedby="basic-addon2"
                                                        class="form-control w-90 ng-untouched ng-pristine ng-invalid"
                                                        id="txt_create_email-1" name="Email" placeholder="Email"
                                                        required="" type="text">
                                                </div>
                                            </div>
                                            <div class="form-group ng-star-inserted">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon2"><i
                                                                class="flaticon-users"></i></span>
                                                    </div>
                                                    <input v-model="edit_user_data.firstname"
                                                        aria-describedby="basic-addon2"
                                                        class="form-control w-90 ng-untouched ng-pristine ng-valid"
                                                        id="txt_first_name-1" name="FirsName" placeholder="Nombre"
                                                        type="text">
                                                </div>
                                            </div>
                                            <!-- <div class="form-group ng-star-inserted">
                                                <div class="input-group">
                                                    <div class="input-group-prepend"><span class="input-group-text" id="basic-addon2"><i class="flaticon-users"></i></span>
                                                    </div>
                                                    <input v-model="edit_user_data.lastname" aria-describedby="basic-addon2" class="form-control w-90 ng-untouched ng-pristine ng-valid" id="txt_first_surname" maxlength="32" name="SurName" placeholder="Apellido" type="text"></div>
                                            </div> -->
                                            <div class="form-group ng-star-inserted">
                                                <div class="input-group">
                                                    <div class="input-group-prepend"><span class="input-group-text"
                                                            id="basic-addon2"><i class="flaticon-users"></i></span>
                                                    </div>
                                                    <select
                                                        class="form-control w-90 ng-untouched ng-pristine ng-valid d-block"
                                                        id="languageSelected-1" name="languageSelected"
                                                        placeholder="Lenguaje" required=""><!---->
                                                        <option value="es" class="ng-star-inserted">es</option>
                                                    </select>
                                                </div>
                                            </div><!----><!---->
                                            <div class="form-group ng-star-inserted">
                                                <div class="input-group">
                                                    <div
                                                        class="col-form-label col-sm-6 d-flex align-items-center justify-content-start">
                                                        Puede realizar retiros</div>
                                                    <div class="col-sm-6"><!----><!---->
                                                        <div class="form-check form-switch" style="font-size: larger;">
                                                            <input class="form-check-input" type="checkbox"
                                                                id="flexSwitchCheckChecked2" checked
                                                                style="height: 1em !important;">
                                                            <label class="form-check-label"
                                                                for="flexSwitchCheckChecked2"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!----><!----><!---->
                                        </div>
                                        <div class="modal-footer">
                                            <button class="btn btn-default waves-effect waves-light" type="button"
                                                @click="closeModal">Cancelar</button>
                                            <button class="btn btn-primary waves-effect waves-light" id="btn_submit"
                                                type="submit"><i class="fa fa-save"></i><span>Guardar</span></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from 'vue-pagination-2';
// import treeNode from './treeNode.vue';
import axios from 'axios'
export default {
    name: 'CuentasCorrientes',
    components: {
        Pagination,
        // treeNode
    },
    data() {
        return {
            user: {},
            filter: "todos",
            search: "",
            searchModel: "",
            search_type: "local",
            last_search: "local",
            actual_page: 1,
            registers_per_page: 10,
            target_user_info: {},
            target_user_id: 0,
            new_password: "",
            new_password2: "",
            showPassword: false,
            showPassword2: false,
            show_hide_action: "",
            enable_disable_action: "",
            changePasswordMessage: { type: "", message: "" },
            blocked_users: {},
            block_reason: "",
            casino_comision_all: "",
            poker_comision_all: "",
            deportes_comision_all: "",
            credit_modal: {
                action: "",
                value: "0,00",
                username: "",
                balance: 0,
                role: "",
                id: 0,
                loading: false
            },
            edit_loading: false,
            edit_user_data: {
                username: "",
                firstname: "",
                dni: "",
                email: "",
                phone: "",
                proveedores: {
                    poker: { status: true, comision: 0 }, casino: [], casinoStatus: true, deportes: { status: true, comision: 0 }, bingo: { status: true, comision: 0 },
                    casinoLive: { status: true, comision: "0%" }, hipicas: { status: true, comision: "0%" },
                    casinoTotal: 0, pokerTotal: 0, deportesTotal: 0, bingoTotal: 0
                },
                liquidacion: ""
            },
            new_user_data: {
                type: "",
                username: "",
                email: "",
                firstname: "",
                dni: "",
                password: "",
                phone: "",
                login_Id: 0,
                poker: 0,
                casino: 0,
                deportes: 0,
                proveedores: {
                    poker: { status: true, comision: "0%" }, casino: [], casinoStatus: true, deportes: { status: true, comision: "0%" }, bingo: { status: true, comision: "0%" },
                    casinoLive: { status: true, comision: "0%" }, hipicas: { status: true, comision: "0%" },
                    casinoTotal: "", pokerTotal: "", deportesTotal: "", bingoTotal: "", casinoLiveTotal: "", hipicasTotal: ""
                },
                error: "",
                liquidacion: "Mensual"
            },
            permission: { name: '', status: false, comision: 0, index: 0 },
            pages: [],
            records: 0,
            loading: false,
            actions: {
                type: "",
                user: {},
                capital: 0,
                comment: ""
            },
        }
    },
    computed: {
        all_casino_brands() {
            return this.$store.getters["getAllCasinoBrands"];
        },
        casino_brands() {
            return this.$store.getters["getCasinoBrands"];
        },
        main_tree() {
            return this.$store.getters["getMainTree"];
        },
        users_capital() {
            let MainData = this.$store.getters["getUsersCapital"];
            function ordered(a, b) {
                return a.username.localeCompare(b.username)
            }
            let agentesArray = MainData.filter(user => user.role == "ROLE_PROMOTOR").sort((a, b) => ordered(a, b));
            let jugadoresArray = MainData.filter(user => user.role == "ROLE_APOSTADOR").sort((a, b) => ordered(a, b));
            let all = agentesArray.concat(jugadoresArray);
            if (this.filter == "agentes") {
                return agentesArray;
            }
            if (this.filter == "jugadores") {
                return jugadoresArray;
            }
            if (this.filter == "ocultos") {
                return all.filter(user => user.show == false).sort((a, b) => ordered(a, b));
            }
            if (this.search_type === 'local') {
                if (this.search !== '') {
                    return all.filter(user => user.username.toLowerCase().includes(this.search.toLowerCase())).sort((a, b) => ordered(a, b));
                }
                return all.filter(user => user.show == true);
            }
            return all.filter(user => user.show == true);
        },
        loadingCapital() {
            return this.$store.getters["getLoadingCapital"];
        },
        targetUser() {
            return this.$store.getters["getTargetUser"];
        },
        userInfo() {
            return this.$store.getters["getUserData"];
        }
    },
    methods: {
        setCuentasUser(user){
            this.$store.dispatch('setCuentaCorrienteTarget', user);
            this.$store.dispatch('setDashboardView', 'mi_cuenta_corriente');
        },
        setAction(action, source) {
            let enabled = document.getElementById(source).checked;
            if (enabled) {
                this.actions.type = action
            }
        },
        async dispatchAction() {
            this.loading = true;
            try {
                let endpoint = "";
                if (this.actions.type == "Cobro") {
                    endpoint = "cobro";
                }
                else if (this.actions.type == "Pago") {
                    endpoint = "pago"
                }
                else if (this.actions.type == "Aumentar_deuda") {
                    endpoint = "cobro"
                }
                else if (this.actions.type == "Reducir_deuda") {
                    endpoint = "pago"
                }
                let response = await axios.post(process.env.VUE_APP_DATA_SERVER_URL + "/" + endpoint, {
                    "capital": this.actions.capital,
                    "motivo": this.actions.type,
                    "user": this.actions.user,
                    "comentario": this.actions.comment,
                    "login_Id": this.main_tree.id
                });
                if (response.data) {
                    if (response.data.status == 'success') {
                        this.showAlert("alert-success", "Proceso correcto", `Acción: ${this.actions.type}`)
                        this.closeModal()
                    }
                    else {
                        this.showAlert("alert-danger", "Error", response.data)
                    }
                }
                else {
                    this.showAlert("alert-danger", "Error", "Error en el proceso")
                }
                this.loading = false;
            }
            catch (error) {
                console.log(error);
                this.showAlert("alert-danger", "Error", error.response?.data?.error)
                this.loading = false;
            }
        },
        setPermission(permission, index) {
            this.permission.name = permission;
            this.permission.index = index ?? 0;
            this.closeModal('noClearData');
            this.openModal('ModalEditPermissions2', this.target_user_id)
        },
        closeModalPermisions() {
            this.permission.name = '';
            this.permission.status = false;
            this.permission.comision = 0;
            this.closeModal('noClearData');
            this.openModal('ModalEditPermissions', this.target_user_id)
        },
        passwordGenerator(length, target) {
            const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#*-_';
            let contraseña = '';
            for (let i = 0; i < length; i++) {
                const caracterAleatorio = caracteres.charAt(Math.floor(Math.random() * caracteres.length));
                contraseña += caracterAleatorio;
            }
            if (target == 'new_password') this.new_password = contraseña
            else if (target == 'new_user_data') this.new_user_data.password = contraseña
        },
        showUsersCapital(id, name) {
            this.$store.dispatch("getChildrenTree", { id: id, name: name });
            this.closeModal()
        },
        setLiquidacion(type, target) {
            if (target == "create") {
                this.new_user_data.liquidacion = type;
            }
            else {
                this.edit_user_data.liquidacion = type;
            }
            document.getElementById("selectTargets").style.display = "none";
        },
        showTargetOptions() {
            document.getElementById("selectTargets").style.display = "block";
        },
        paginate(page) {
            this.actual_page = page;
        },
        checkAll(targetClass, mainTarget) {
            let type = targetClass.split("-")[0];
            let main = document.getElementById(mainTarget);
            if (type == "poker") {
                this.new_user_data.proveedores.poker.status = !this.new_user_data.proveedores.poker.status;
            }
            else if (type == "deportes") {
                this.new_user_data.proveedores.deportes.status = !this.new_user_data.proveedores.deportes.status;
            }
            else if (type == "casinolive") {
                this.new_user_data.proveedores.casinoLive.status = !this.new_user_data.proveedores.casinoLive.status;
            }
            else if (type == "hipicas") {
                this.new_user_data.proveedores.hipicas.status = !this.new_user_data.proveedores.hipicas.status;
            }
            else if (type == "casino") {
                this.new_user_data.proveedores.casino.forEach(brand => {
                    brand.status = main.checked
                })
            }
            // let targets = document.getElementsByClassName(targetClass);
            // [...targets].forEach(target => {
            //     target.checked = main.checked;
            // })
        },
        parseRole(role) {
            if (role) {
                let role_name = role.split('"')[1].split("_")[1];
                if (role_name == "PROMOTOR") {
                    return "Agente";
                }
                else if (role_name == "APOSTADOR") {
                    return "Jugador";
                }
                else {
                    return role_name
                }
            }
        },
        setSearchType() {
            const deepSearch = document.getElementById('deepSearch').checked;
            if (deepSearch) {
                this.search_type = 'deep';
            }
            else {
                this.search_type = 'local';
            }
        },
        searchText() {
            this.actual_page = 1;
            this.search = this.searchModel;
            if (this.search_type === 'deep' || (this.lastSearch === 'deep' && this.search === '')) {
                this.last_search = 'deep';
                this.$store.dispatch("getChildrenByName", this.search)
            }
            else {
                this.last_search = 'local';
            }
        },
        changeFilter(filter) {
            const all = document.getElementById('tgAll');
            const agents = document.getElementById('tgAgent');
            const players = document.getElementById('tgPlayer');
            all.classList.remove('group-active')
            agents.classList.remove('group-active')
            players.classList.remove('group-active')
            if (filter === 'todos') {
                all.classList.add('group-active')
            }
            else if (filter === 'agentes') {
                agents.classList.add('group-active')
            }
            else if (filter === 'jugadores') {
                players.classList.add('group-active')
            }
            this.filter = filter;
        },
        hideChildren(id) {
            this.closeModal()
            this.$store.dispatch("hideUser", id);
        },
        showChildren(id) {
            this.closeModal()
            this.$store.dispatch("showUser", id);
        },
        paginarArray(array, elementosPorPagina) {
            const paginas = [];
            let paginaActual = [];

            for (let i = 0; i < array.length; i++) {
                if (paginaActual.length === elementosPorPagina) {
                    paginas.push(paginaActual);
                    paginaActual = [];
                }
                paginaActual.push(array[i]);
            }

            if (paginaActual.length > 0) {
                paginas.push(paginaActual);
            }
            this.records = array.length;
            this.pages = paginas;
            if (this.records < this.registers_per_page) this.actual_page = 1
            return paginas;
        },
        showMaxRegisterSelection() {
            document.getElementById("selectPage").style.display = "block";
        },
        setRegistersPerPage() {
            const pages = parseInt(document.getElementById('registersPerPageSelect').value)
            this.registers_per_page = pages;
        },
        changePage(page) {
            this.actual_page = page
        },
        prevPage() {
            this.actual_page -= 1;
        },
        nextPage() {
            this.actual_page += 1;
        },
        showHiddenTree() {
            let checked = document.getElementById("TreeShowHidden").checked;
            this.$store.dispatch("showHiddenTree", checked);
        },
        async getUserServerData(id) {
            try {
                let response = await axios.post(process.env.VUE_APP_DATA_SERVER_URL + "/informacion", {
                    "users": id,
                    "login_id": this.main_tree.id
                })
                if (response.data) {
                    response.data[0].estructura = response.data[1].estructura
                    return response.data[0]
                }
                return null
            } catch (error) {
                return null
            }
        },
        async openUserInfoModal(id) {
            try {
                this.target_user_id = id;
                this.target_user_info = {};
                this.backdrop(true)
                let modal = document.getElementById("ModalViewInfo");
                this.target_user_info = await this.getUserServerData(id);
                modal.classList.add("show");
                modal.style.display = "block"
            } catch (error) {
                console.log(error);
            }
        },
        getUserData(id) {
            return this.users_capital.filter(user => user.user == id)[0];
        },
        openModal(target, id, title) {
            try {
                this.backdrop(true);
                this.target_user_id = id;
                let modal = document.getElementById(target);
                modal.classList.add("show");
                modal.style.setProperty('display', 'block', 'important');
                if (target == 'ModalCredit') {
                    let data = this.getUserData(id);
                    this.credit_modal.action = title;
                    this.credit_modal.username = data.username;
                    this.credit_modal.balance = this.parseNumbers(data.capital_total);
                    this.credit_modal.role = data.role;
                    this.credit_modal.id = data.user;
                }
                else if (target == "ModalNewUser" || target == "ModalNewUser2") {
                    this.new_user_data.type = title;
                    let brandsCopy = JSON.parse(JSON.stringify(this.casino_brands));
                    this.new_user_data.proveedores.casino = brandsCopy.map(brand => {
                        return {
                            id: brand.id,
                            name: brand.name,
                            comision: "0%",
                            status: brand.status
                        }
                    });
                }
                else if (target == 'showHideModal') {
                    this.show_hide_action = title
                }
                else if (target == 'enableDisableModal') {
                    this.enable_disable_action = title
                }
                else if (target == 'paymentModal') {
                    this.actions.user = id;
                    document.getElementById('userPayMe').checked = true
                    this.setAction('Cobro', 'userPayMe')
                }
                else if (target == 'manualAdjustmentsModal') {
                    this.actions.user = id;
                    document.getElementById('increaseDebt').checked = true
                    this.setAction('Aumentar_deuda', 'increaseDebt')
                }
            } catch (error) {
                console.log(error);
            }
        },
        async openModalEditUser(id, target) {
            try {
                this.backdrop(true);
                this.target_user_id = id;
                let userData = await this.getUserServerData(id);
                this.edit_user_data.firstname = userData.firstname.split("-")[0];
                this.edit_user_data.dni = userData.firstname.split("-")[1];
                this.edit_user_data.username = userData.username;
                this.edit_user_data.email = userData.email;
                this.edit_user_data.phone = userData.phone;
                if (Object.keys(userData.proveedores).length < 1) {
                    userData.proveedores = {
                        "poker": 0,
                        "casino": 0,
                        "deportes": 0
                    };
                }
                this.edit_user_data.proveedores.casinoStatus = userData.proveedores.casinoStatus !== undefined ? userData.proveedores.casinoStatus : true;
                this.edit_user_data.proveedores.poker = userData.proveedores.poker?.comision !== undefined ? { comision: (userData.proveedores.poker.comision), status: userData.proveedores.poker.status } : { comision: (userData.proveedores.poker), status: true };
                this.edit_user_data.proveedores.hipicas = userData.proveedores.hipicas?.comision !== undefined ? { comision: (userData.proveedores.hipicas.comision), status: userData.proveedores.hipicas.status } : { comision: (userData.proveedores.hipicas), status: true };
                this.edit_user_data.proveedores.casinoLive = userData.proveedores.casinolive?.comision !== undefined ? { comision: (userData.proveedores.casinolive.comision), status: userData.proveedores.casinolive.status } : { comision: (userData.proveedores.casinolive), status: true };
                this.edit_user_data.proveedores.deportes = userData.proveedores.deportes?.comision !== undefined ? { comision: (userData.proveedores.deportes.comision), status: userData.proveedores.deportes.status } : { comision: (userData.proveedores.deportes), status: true };
                let casino_brands = JSON.parse(JSON.stringify(this.casino_brands));
                if (!Array.isArray(userData.proveedores.casino)) {
                    casino_brands.forEach(brand => {
                        brand.comision = (userData.proveedores.casino);
                    })
                }
                this.edit_user_data.proveedores.casino = !Array.isArray(userData.proveedores.casino) ? casino_brands : userData.proveedores.casino.map(brand => {
                    return {
                        id: brand.id,
                        name: brand.name,
                        comision: (brand.comision),
                        status: brand.status
                    }
                }).filter(brand => casino_brands.filter(Ubrand => Ubrand.id === brand.id)[0]?.status);
                this.edit_user_data.proveedores.casinoTotal = !Array.isArray(userData.proveedores.casino) ? (userData.proveedores.casino) : (userData.proveedores.casino[0].comision);
                this.edit_user_data.proveedores.pokerTotal = userData.proveedores.poker.comision !== undefined ? (userData.proveedores.poker.comision) : (userData.proveedores.poker);
                this.edit_user_data.proveedores.deportesTotal = userData.proveedores.deportes.comision !== undefined ? (userData.proveedores.deportes.comision) : (userData.proveedores.deportes);
                this.edit_user_data.liquidacion = userData.tipo_cierre ?? "Mensual";
                this.target_user_info = userData
                let modal = document.getElementById(target);
                modal.classList.add("show");
                modal.style.display = "block";
            } catch (error) {
                console.log(error);
            }
        },
        async updateUserInfo() {
            try {
                this.loading = true;
                let allCasinoBrandsCopy = JSON.parse(JSON.stringify(this.all_casino_brands));
                let parsedCasino = this.edit_user_data.proveedores.casino.map(brand => {
                    return {
                        id: brand.id,
                        name: brand.name,
                        comision: parseFloat(brand.comision),
                        status: brand.status
                    }
                })
                let casinoBrandsConfig = allCasinoBrandsCopy.map(brand => {
                    return {
                        id: brand.id,
                        name: brand.name,
                        comision: parsedCasino.find(item => item.id == brand.id && item.name == brand.name)?.comision ?? parsedCasino[0].comision,
                        status: parsedCasino.find(item => item.id == brand.id && item.name == brand.name)?.status ?? false
                    }
                })
                let response = await axios.post(process.env.VUE_APP_DATA_SERVER_URL + "/actualizarinfo", {
                    "username": this.edit_user_data.username,
                    "email": this.edit_user_data.email,
                    "firstname": this.edit_user_data.firstname + "-" + this.edit_user_data.dni,
                    "phone": this.edit_user_data.phone,
                    "login_Id": this.target_user_id,
                    "casino": parseFloat(this.edit_user_data.proveedores.casino[0].comision),
                    "poker": parseFloat(this.edit_user_data.proveedores.poker.comision),
                    "deportes": parseFloat(this.edit_user_data.proveedores.deportes.comision),
                    "bingo": 0,
                    "tipo_cierre": this.edit_user_data.liquidacion,
                    "proveedores": {
                        "poker": {
                            "comision": parseFloat(this.edit_user_data.proveedores.poker.comision),
                            "status": this.edit_user_data.proveedores.poker.status
                        },
                        "casinolive": {
                            "comision": parseFloat(this.edit_user_data.proveedores.casinoLive.comision),
                            "status": this.edit_user_data.proveedores.casinoLive.status
                        },
                        "hipicas": {
                            "comision": parseFloat(this.edit_user_data.proveedores.hipicas.comision),
                            "status": this.edit_user_data.proveedores.hipicas.status
                        },
                        "deportes": {
                            "comision": parseFloat(this.edit_user_data.proveedores.deportes.comision),
                            "status": this.edit_user_data.proveedores.deportes.status
                        },
                        "casinoStatus": this.edit_user_data.proveedores.casinoStatus,
                        "casino": casinoBrandsConfig
                    }
                });
                if (response.data.message) {
                    // this.$store.dispatch("getChildrenCapital",this.$store.getters["getTargetUser"]);
                    this.showAlert("alert-success", "Proceso correcto", " Usuario actualizado.")
                }
                this.loading = false;
                this.closeModal();
            } catch (error) {
                this.loading = false;
                this.showAlert("alert-danger", "Error", error.response?.data?.error)
            }
        },
        async blockUser() {
            this.loading = true
            let data = {
                id: this.target_user_id,
                reason: this.block_reason
            }
            let ids = await axios.post(process.env.VUE_APP_DATA_SERVER_URL + "/arbol_ids", {
                login_Id: this.target_user_id
            })
            if (ids.data) {
                await axios.post(process.env.VUE_APP_DATA_SERVER_URL + "/bloquear_usuario", {
                    "users": ids.data.data_total
                })
            }
            this.loading = false
            this.closeModal()
            this.blocked_users[this.target_user_id] = data
            localStorage.setItem("blocked_users", JSON.stringify(this.blocked_users));
            this.$store.dispatch("getChildrenCapital", { id: this.main_tree.id, name: this.main_tree.user_name });
        },
        async unBlockUser() {
            this.loading = true
            let data = this.getUserData(this.target_user_id);
            if (data.enabled == 0) {
                delete this.blocked_users[this.target_user_id];
                let ids = await axios.post(process.env.VUE_APP_DATA_SERVER_URL + "/arbol_ids", {
                    login_Id: this.target_user_id
                })
                if (ids.data) {
                    await axios.post(process.env.VUE_APP_DATA_SERVER_URL + "/bloquear_usuario", {
                        "users": ids.data.data_total
                    })
                }
                this.loading = false
                this.$store.dispatch("getChildrenCapital", { id: this.main_tree.id, name: this.main_tree.user_name });
                localStorage.setItem("blocked_users", JSON.stringify(this.blocked_users));
            }
            this.closeModal()
        },
        async changePassword() {
            try {
                this.loading = true;
                document.getElementById("ModalChangePasswordSubmit").disabled = true;
                let response = await axios.post(process.env.VUE_APP_DATA_SERVER_URL + "/cambiar_password", {
                    "users": [this.target_user_id],
                    "password": this.new_password,
                    "password1": this.new_password,
                    "token": this.userInfo.token,
                    "login_id": this.main_tree.id
                }
                )
                if (response.data) {
                    if (response.data.message) {
                        this.closeModal();
                        this.showAlert("alert-success", "Proceso correcto", "Contraseña actualizada.");
                        document.getElementById("ChangePasswordLoading").style.display = "none";
                        document.getElementById("ModalChangePasswordSubmit").disabled = false;
                    }
                    else {
                        this.changePasswordMessage = {
                            message: response.data.error,
                            type: 'error'
                        };
                        this.loading = false;
                        document.getElementById("ModalChangePasswordSubmit").disabled = false;
                    }
                }
            } catch (error) {
                this.changePasswordMessage = {
                    message: error.response?.data?.error ?? "Error en el proceso",
                    type: 'error'
                };
                this.loading = false;
                document.getElementById("ModalChangePasswordSubmit").disabled = false;
            }
        },
        backdrop(action) {
            if (action) {
                document.getElementById("modalBackdrop").style.display = "block";
                document.getElementById("modalBackdrop").classList.add("show");
            }
            else {
                document.getElementById("modalBackdrop").style.display = "none";
                document.getElementById("modalBackdrop").classList.remove("show");
            }
        },
        closeModal(action) {
            let modals = document.getElementsByClassName("modal");
            [...modals].forEach(modal => {
                modal.classList.remove("show");
                modal.style.display = "none";
            })
            document.getElementById("modalBackdrop").style.display = "none";
            document.getElementById("modalBackdrop").classList.remove("show");
            if (action ? (action == 'noClearData' ? true : false) : false) {
                return
            }
            this.target_user_id = 0;
            this.new_password = "";
            this.new_password2 = "";
            this.target_user_id = {};
            this.permission = { name: '', status: false, comision: 0, index: 0 };
            this.changePasswordMessage = { type: "", message: "" }
            this.credit_modal = {
                action: "",
                value: "0,00",
                username: "",
                balance: 0,
                role: "",
                id: 0,
                loading: false
            }
            this.actions = {
                type: "",
                user: {},
                capital: 0,
                comment: ""
            }
            this.edit_user_data = {
                username: "",
                firstname: "",
                dni: "",
                email: "",
                phone: "",
                proveedores: {
                    poker: { status: true, comision: 0 }, casino: [], casinoStatus: true, deportes: { status: true, comision: 0 }, bingo: { status: true, comision: 0 },
                    casinoLive: { status: true, comision: "0%" }, hipicas: { status: true, comision: "0%" },
                    casinoTotal: 0, pokerTotal: 0, deportesTotal: 0, bingoTotal: 0
                },
                liquidacion: ""
            }
            const modalCreditInput = document.getElementById('ModalCreditAmount')
            if (modalCreditInput) { modalCreditInput.value = '0.00' }
            this.new_user_data = {
                type: "jugador",
                username: "",
                email: "",
                firstname: "",
                dni: "",
                password: "",
                phone: "",
                login_Id: 0,
                poker: 0,
                casino: 0,
                deportes: 0,
                proveedores: {
                    poker: { status: true, comision: "0%" }, casino: [], casinoStatus: true, deportes: { status: true, comision: "0%" }, bingo: { status: true, comision: "0%" },
                    casinoLive: { status: true, comision: "0%" }, hipicas: { status: true, comision: "0%" },
                    casinoTotal: "", pokerTotal: "", deportesTotal: "", bingoTotal: "", casinoLiveTotal: "", hipicasTotal: ""
                },
                error: "",
                liquidacion: "Mensual"
            }
        },
        formatearFecha(fechaString) {
            const fecha = new Date(fechaString);
            const anio = fecha.getFullYear();
            const mes = this.agregarCeroDelante(fecha.getMonth() + 1);
            const dia = this.agregarCeroDelante(fecha.getDate());
            return `${anio}/${mes}/${dia}`;
        },
        agregarCeroDelante(numero) {
            return numero < 10 ? '0' + numero : numero;
        },
        showHidePassword(target, icon, flag) {
            if (flag == 1 && !this.showPassword) {
                document.getElementById(target).type = "text";
                document.getElementById(icon).classList.remove("fa-eye");
                document.getElementById(icon).classList.add("fa-eye-slash");
                this.showPassword = true
            }
            else if (flag == 1 && this.showPassword) {
                document.getElementById(target).type = "password";
                document.getElementById(icon).classList.remove("fa-eye-slash");
                document.getElementById(icon).classList.add("fa-eye");
                this.showPassword = false
            }
            if (flag == 2 && !this.showPassword2) {
                document.getElementById(target).type = "text";
                document.getElementById(icon).classList.remove("fa-eye");
                document.getElementById(icon).classList.add("fa-eye-slash");
                this.showPassword2 = true
            }
            else if (flag == 2 && this.showPassword2) {
                document.getElementById(target).type = "password";
                document.getElementById(icon).classList.remove("fa-eye-slash");
                document.getElementById(icon).classList.add("fa-eye");
                this.showPassword2 = false
            }
        },
        actionsParseNumbers(number, target) {
            if (Number.isNaN(number)) {
                return 0;
            }
            if (target) {
                let element = document.getElementById(target);
                let selection1 = element.selectionStart
                let digits = element.value.includes(".") ? (element.value.split(".")[1].length < 1 ? 0 : element.value.split(".")[1].length) : 0;
                number = element.value == "" ? 0 : this.textToFloat(element.value);
                element.value = digits > 0 ? this.floatToText(number, digits) : (element.value.includes(".") ? this.floatToText(number, digits) + "." : this.floatToText(number, digits));
                element.setSelectionRange(element.value.includes(" ") ? selection1 + 1 : selection1, element.value.includes(" ") ? selection1 + 1 : selection1);
                this.actions.capital = number;
                return
            }
            return new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number)
        },
        parseNumbers(number, target) {
            if (Number.isNaN(number)) {
                return 0;
            }
            if (target) {
                const actualBalance = parseFloat(this.credit_modal.balance.replaceAll('.', '').replace(',', '.'));
                let element = document.getElementById(target);
                let selection1 = element.selectionStart
                let digits = element.value.includes(".") ? (element.value.split(".")[1].length < 1 ? 0 : element.value.split(".")[1].length) : 0;
                number = element.value == "" ? 0 : this.textToFloat(element.value);
                if (number > actualBalance && this.credit_modal.action === 'Descargar Fichas') { number = actualBalance }
                element.value = digits > 0 ? this.floatToText(number, digits) : (element.value.includes(".") ? this.floatToText(number, digits) + "." : this.floatToText(number, digits));
                element.setSelectionRange(element.value.includes(" ") ? selection1 + 1 : selection1, element.value.includes(" ") ? selection1 + 1 : selection1);
                this.credit_modal.value = number;
                return
            }
            return new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number)
        },
        floatToText(number, digits) {
            let text1 = new Intl.NumberFormat("de-DE", { minimumFractionDigits: digits, maximumFractionDigits: digits }).format(number);
            let parsed = text1.replaceAll(".", " ").replace(",", ".");
            return parsed
        },
        textToFloat(text) {
            let dot_qty = 0;
            let parsed = "";
            for (let index = 0; index < text.length; index++) {
                const char = text[index];
                if (char == " ") {
                    dot_qty++;
                }
            }
            if (dot_qty >= 1) {
                parsed = text.replaceAll(" ", "").replace(",", ".");
            }
            else {
                parsed = text.replace(",", ".");
            }
            return parseFloat(parsed);
        },
        addValue(target, val) {
            const actualBalance = parseFloat(this.credit_modal.balance.replaceAll('.', '').replace(',', '.'));
            let element = document.getElementById(target);
            let floatNumber = this.textToFloat(element.value);
            let newVal = floatNumber + val > actualBalance && this.credit_modal.action === 'Descargar Fichas' ? actualBalance : floatNumber + val;
            element.value = this.floatToText(newVal);
            this.parseNumbers('', target);
        },
        removeValue(target, val) {
            let element = document.getElementById(target);
            let floatNumber = this.textToFloat(element.value);
            let newVal = floatNumber - val < 0 ? 0 : floatNumber - val;
            element.value = this.floatToText(newVal);
            this.parseNumbers('', target);
        },
        makeOneCheck(target1, target2) {
            if (document.getElementById(target1).checked) {
                document.getElementById(target2).checked = false
            }
        },
        showAlert(type, title, message) {
            let alert = '<div id="mainAlert" class="alert ' + type + ' alert-dismissible fade show" role="alert" style="position:absolute;bottom:5px;right:5px;max-width:50%;">' +
                '<strong id="alertTitle">' + title + '</strong> <span id="alertMessage">' + message + '</span>' +
                '<button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' +
                '</div>';
            document.getElementById("mainAlert").innerHTML = alert;
        },
        async cargar_descargar_Fichas() {
            try {
                this.credit_modal.loading = true;
                if (this.credit_modal.action != "") {
                    document.getElementById("ModalCreditSubmit").disabled = true;
                    if (this.credit_modal.action == "Cargar Fichas") {
                        let reason = document.getElementById("ModalCreditRegisterFinanceCollect")?.checked ? "Cobrado" :
                            (document.getElementById("ModalCreditRegisterFinanceBonification")?.checked ? "Bonificacion" : "");
                        let response = await axios.post(process.env.VUE_APP_DATA_SERVER_URL + "/addcapitaljugador", {
                            "capital": this.credit_modal.value,
                            "user": this.credit_modal.id, // al que se le ejecuta la acción
                            "login_Id": this.main_tree.id, // el que esta logueado
                            "motivo": reason,
                            "token": this.userInfo.token
                        });
                        if (response.data.status == "success") {
                            this.$store.dispatch("getChildrenCapital2", this.$store.getters["getTargetUser"]);
                            this.showAlert("alert-success", "Proceso correcto", "Carga de fichas exitosa")
                        }
                        else {
                            this.showAlert("alert-danger", "Error", "en el proceso de carga de fichas")
                        }
                    }
                    else {
                        let reason = document.getElementById("ModalCreditRegisterFinancePaid")?.checked ? "Pagado" : "";
                        let response = await axios.post(process.env.VUE_APP_DATA_SERVER_URL + "/retirarcapitaljugador", {
                            "capital": this.credit_modal.value,
                            "user": this.credit_modal.id, // al que se le ejecuta la acción
                            "login_Id": this.main_tree.id, // el que esta logueado
                            "motivo": reason,
                            "token": this.userInfo.token
                        });
                        if (response.data.status == "success") {
                            this.$store.dispatch("getChildrenCapital2", this.$store.getters["getTargetUser"]);
                            this.showAlert("alert-success", "Proceso correcto", "Descarga de fichas exitosa")
                        }
                        else {
                            this.showAlert("alert-danger", "Error", "en el proceso de descarga de fichas")
                        }
                    }
                    this.closeModal();
                    document.getElementById("ModalCreditSubmit").disabled = false;
                    this.credit_modal.loading = false
                    //refrescar saldo
                    let userdata = {
                        token: this.userInfo.token,
                        site_id: this.userInfo.site_id,
                        prefix: this.userInfo.prefix,
                        site: this.userInfo.site,
                        origin: "dashboard"
                    }
                    this.$store.dispatch("getUserData", userdata)
                }
            } catch (error) {
                console.log(error)
                this.showAlert("alert-danger", "Error", error.response?.data?.error ?? "Error en el proceso");
                this.closeModal();
                this.credit_modal.loading = false;
                document.getElementById("ModalCreditSubmit").disabled = false;
            }
        },
        async create_user() {
            let createBtn = this.new_user_data.type == "agente" ? document.getElementById("ModalNewUserAffiliateSubmit") : document.getElementById("ModalNewUserPlayerSubmit");
            createBtn.innerHTML = createBtn.dataset.loading;
            createBtn.disabled = true;
            try {
                let loginInfo = this.new_user_data.username != "" && this.new_user_data.password != "" ? true : false;
                //let userInfo = this.new_user_data.firstname!=""&&this.new_user_data.dni!=""&&this.new_user_data.email!=""&&this.new_user_data.phone!=""?true:false;
                // let comisions = this.new_user_data.proveedores.pokerTotal == "" && this.new_user_data.proveedores.casinoTotal == "" && this.new_user_data.proveedores.deportesTotal == "" ? false : true;
                if (!loginInfo) {
                    this.new_user_data.error = "Debes completar los datos de Ingreso"
                    createBtn.innerHTML = "Aceptar";
                    createBtn.disabled = false;
                }
                // else if (this.new_user_data.type == "agente" && !comisions) {
                //     document.getElementById("NewUserTabAffiliate4Link").click()
                //     //this.new_user_data.error = "Debes indicar las comisiones para todos los juegos"
                //     createBtn.innerHTML = "Aceptar";
                //     createBtn.disabled = false;
                // }
                else {
                    let data = {};
                    let allCasinoBrandsCopy = JSON.parse(JSON.stringify(this.all_casino_brands));
                    let parsedCasino = this.new_user_data.proveedores.casino.map(brand => {
                        return {
                            id: brand.id,
                            name: brand.name,
                            comision: parseFloat(brand.comision),
                            status: brand.status
                        }
                    })
                    let casinoBrandsConfig = allCasinoBrandsCopy.map(brand => {
                        return {
                            id: brand.id,
                            name: brand.name,
                            comision: parsedCasino.find(item => item.id == brand.id && item.name == brand.name)?.comision ?? parsedCasino[0].comision,
                            status: parsedCasino.find(item => item.id == brand.id && item.name == brand.name)?.status ?? false
                        }
                    })
                    data.username = this.new_user_data.username;
                    data.email = this.new_user_data.email;
                    data.firstname = this.new_user_data.firstname + "-" + this.new_user_data.dni;
                    data.password = this.new_user_data.password;
                    data.phone = this.new_user_data.phone;
                    data.login_Id = this.main_tree.id;
                    data.token = this.userInfo.token;
                    if (this.new_user_data.type == "agente") {
                        data.poker = parseFloat(this.new_user_data.proveedores.poker.comision);
                        data.casino = parseFloat(this.new_user_data.proveedores.casino[0].comision);
                        data.deportes = parseFloat(this.new_user_data.proveedores.deportes.comision);
                        data.tipo_cierre = this.new_user_data.liquidacion;
                        data.proveedores = {
                            "poker": {
                                "comision": parseFloat(this.new_user_data.proveedores.poker.comision),
                                "status": this.new_user_data.proveedores.poker.status
                            },
                            "casinolive": {
                                "comision": 0,
                                "status": this.new_user_data.proveedores.casinoLive.status
                            },
                            "hipicas": {
                                "comision": 0,
                                "status": this.new_user_data.proveedores.hipicas.status
                            },
                            "deportes": {
                                "comision": parseFloat(this.new_user_data.proveedores.deportes.comision),
                                "status": this.new_user_data.proveedores.deportes.status
                            },
                            "casino": casinoBrandsConfig
                        }
                    }
                    else {
                        data.proveedores = {
                            "poker": {
                                "comision": 0,
                                "status": this.new_user_data.proveedores.poker.status
                            },
                            "casinolive": {
                                "comision": 0,
                                "status": this.new_user_data.proveedores.casinoLive.status
                            },
                            "hipicas": {
                                "comision": 0,
                                "status": this.new_user_data.proveedores.hipicas.status
                            },
                            "deportes": {
                                "comision": 0,
                                "status": this.new_user_data.proveedores.deportes.status
                            },
                            "casino": parsedCasino
                        }
                    }
                    let endpoint = this.new_user_data.type == "agente" ? "/crear_agente" : "/crear_jugador"
                    let response = await axios.post(process.env.VUE_APP_DATA_SERVER_URL + endpoint, data);
                    if (response.data.error) {
                        this.new_user_data.error = response.data.error;
                        createBtn.innerHTML = "Aceptar";
                        createBtn.disabled = false;
                    }
                    else {
                        this.closeModal();
                        this.$store.dispatch("getChildrenCapital2", this.$store.getters["getTargetUser"]);
                        this.showAlert("alert-success", "Proceso correcto", "Usuario creado con éxito");
                        createBtn.innerHTML = "Aceptar";
                        createBtn.disabled = false;
                    }
                }
            } catch (error) {
                console.log(error)
                this.new_user_data.error = error.response?.data?.error ?? "Error en el proceso";
                createBtn.innerHTML = "Aceptar";
                createBtn.disabled = false;
            }
        },
        setCasinoComision(target) {
            if (target == "edit") {
                let number = parseFloat(this.edit_user_data.proveedores.casinoTotal || 0);
                if (number > 100) number = 100;
                this.edit_user_data.proveedores.casino.forEach(brand => {
                    brand.comision = number
                })
            }
            else {
                let number = parseFloat(this.new_user_data.proveedores.casinoTotal || 0);
                if (number > 100) number = 100;
                this.new_user_data.proveedores.casino.forEach(brand => {
                    brand.comision = number
                })
            }
        },
        setPokerComision(target) {
            if (target == "edit") {
                console.log(this.edit_user_data.proveedores.pokerTotal);
                let number = parseFloat(this.edit_user_data.proveedores.pokerTotal || 0);
                if (number > 100) number = 100;
                this.edit_user_data.proveedores.poker.comision = number
            }
            else {
                let number = parseFloat(this.new_user_data.proveedores.pokerTotal || 0);
                if (number > 100) number = 100;
                this.new_user_data.proveedores.poker.comision = number
            }
        },
        setDeportesComision(target) {
            if (target == "edit") {
                let number = parseFloat(this.edit_user_data.proveedores.deportesTotal || 0);
                if (number > 100) number = 100;
                this.edit_user_data.proveedores.deportes.comision = number
            }
            else {
                let number = parseFloat(this.new_user_data.proveedores.deportesTotal || 0);
                if (number > 100) number = 100;
                this.new_user_data.proveedores.deportes.comision = number
            }
        }
    },
    mounted() {
        let restoreBlockedUsers = JSON.parse(localStorage.getItem("blocked_users"));
        if (restoreBlockedUsers) {
            this.blocked_users = restoreBlockedUsers;
        }
        this.$root.$on('changeUserFilter', (filter) => {
            this.changeFilter(filter)
        });
    },
    created() {
        //this.$store.dispatch("getChildrenCapital",{id:this.main_tree.id,name:this.main_tree.user_name});
    },
}
</script>

<style scoped>
.btn.btn-label-white.group-active,
.btn.btn-label-white:hover {
    background-color: #fff !important;
    color: #000 !important;
    border-color: #fff !important;
}

.kt-content px-1,
.kt-portlet__head,
.tree,
.ui-tree-selectable {
    background-color: #15172b !important;
}

.nav-tabs li .btn {
    width: 100%;
    border-radius: 7px 7px 0 0;
    border: 1px solid #45484b !important;
    color: #fff;
}

.dataTables_wrapper .table thead {
    background: #24284b;
}

.dataTables_wrapper .table thead tr th {
    color: #dce7f9;
    font-family: 'Times New Roman', sans-serif;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    padding: 10px !important;
    font-weight: 600 !important;
}

.input-icon .m-input {
    padding-left: calc(1.5em + 1.3rem + 2px);
    background-color: #15172b;
    border: 1px solid #45484b !important;
}

.btn-active {
    background-color: rgb(255, 197, 41) !important;
    color: rgb(36, 40, 75) !important;
    border-color: rgb(255, 197, 41) !important;
}

.borderPermission {
    border-bottom: 1px dashed #ebedf2;
    border-bottom-width: 1px;
    border-bottom-style: dashed;
}

.dataTables_paginate {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
</style>