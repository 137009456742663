<template>
    <div>
        <div class="kt-grid kt-grid--ver kt-grid--root" style="text-align: left;">
            <div id="kt_login" class="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1">
                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile"><!---->
                    <div class="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper d-block">
                        <div class="kt-login__head d-block mt-3 mr-auto ml-auto text-right" style="height: 19.5px;">
                            <div>
                                <div class="language-switch-area">
                                    <a>
                                        <span class="language-icon-current" title="English (United Kingdom)">
                                            <i class="famfamfam-flags gb" aria-label="English (United Kingdom)"></i>
                                        </span>
                                        <span title="Español (Spanish)">
                                            <i class="famfamfam-flags es" aria-label="Español (Spanish)"></i>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="kt-login__body d-block mt-3 mr-auto ml-auto" style="max-width: 450px;"><!---->
                            <div class="kt-login__form" style="margin-top: 13px; opacity: 1;box-shadow: 3px 3px 25px rgba(0, 0, 0, 0.55);">
                                <div class="kt-login__title text-center">
                                    <img class="logo" alt="" src="../assets/icon-login.svg">
                                    Agent Platform - {{ name }}
                                </div>
                                <form class="kt-form ng-dirty ng-valid ng-touched" @submit.prevent="login()" id="login-form" novalidate="novalidate">
                                
                                    <div class="form-group">
                                        <label for="username">Nombre de usuario</label>
                                        <input v-model="email" class="form-control m-input ng-dirty ng-valid ng-touched" autofocus="" autocomplete="new-password" id="username" name="username" required="" style="font-size:18px;" type="text" placeholder="Nombre de usuario o email*" aria-required="true"><!---->
                                        <div v-if="email==''" _ngcontent-vgq-c1="" class="ng-tns-c1-0 ng-star-inserted" style=""><!----><div class="has-danger ng-star-inserted"><!----><div class="ng-star-inserted"><!----><div class="form-control-feedback ng-star-inserted"> * Este campo es obligatorio </div></div><div class="ng-star-inserted"><!----></div><div class="ng-star-inserted"><!----></div><div class="ng-star-inserted"><!----></div><div class="ng-star-inserted"><!----></div></div></div>
                                    </div>
                                    <div class="form-group">
                                        <label for="password">Contraseña</label>
                                        <input v-model="password" class="form-control m-input ng-dirty ng-valid ng-touched" autocomplete="new-password" maxlength="32" id="password" name="password" required="" style="font-size:18px;" type="password" placeholder="Contraseña *" aria-required="true"><!---->
                                        <div v-if="password==''" _ngcontent-vgq-c1="" class="ng-tns-c1-0 ng-star-inserted" style=""><!----><div class="has-danger ng-star-inserted"><!----><div class="ng-star-inserted"><!----><div class="form-control-feedback ng-star-inserted"> * Este campo es obligatorio </div></div><div class="ng-star-inserted"><!----></div><div class="ng-star-inserted"><!----></div><div class="ng-star-inserted"><!----></div><div class="ng-star-inserted"><!----></div></div></div>
                                    </div>
                                    <div class="kt-login__actions">
                                        <button class="btn btn-primary btn-elevate kt-login__btn-primary" type="submit" id="dologin" data-loading="<i class='fa fa-spinner fa-spin '></i> Ingresando...">Iniciar sesión</button>
                                    </div>
                                </form>
                                <div>
                                    <span style="color:red" v-if="alert!=''">{{ alert }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  <script>
  import router from '../router/index';
  export default {
    name: 'LoginPage',
    components: {
    },
    data() {
        return {
            email:"",
            password:"",
            alert:"",
            name: process.env.VUE_APP_DATA_PLATFORM_NAME
        }
    },
    methods: {
        async login(){
            let loginBtn = document.getElementById("dologin");
            loginBtn.innerHTML = loginBtn.dataset.loading;
            loginBtn.disabled = true; 
            let loginResponse = await this.$store.dispatch("login",{username:this.email,password:this.password});
            if(loginResponse == "success"){
                router.push({ name: 'dashboard' })
            }
            else{
                this.alert = loginResponse;
                loginBtn.innerHTML = "Ingresar";
                loginBtn.disabled = false; 
            }
        }
    },
    created() {
        
    },
  }
  </script>
  
  <style scoped>
  .form-control{
    border: none !important;
    color: white !important;
  }
  ::placeholder {
  color: rgb(178, 180, 186) !important;
  opacity: 1 !important; /* Firefox */
}
  </style>
  