import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import _get from 'lodash.get';
import router from '../router/index';

Vue.use(Vuex)

export default new Vuex.Store({
    state:{
        months:{
            "01":"Jan",
            "02":"Feb",
            "03":"Mar",
            "04":"Apr",
            "05":"May",
            "06":"Jun",
            "07":"Jul",
            "08":"Aug",
            "09":"Sep",
            "10":"Oct",
            "11":"Nov",
            "12":"Dec",
        },
        cuentaCorrienteTarget:"",
        renderStats:false,
        chartDataNetwinMonth:{casino:[],deportes:[]},
        chartDataNetwinDay:{casino:[],deportes:[]},
        chartDataTopAgentes:[],
        chartDataNetwinTotal:{},
        arbolJerarquia:{},
        dashboard_view:"users",
        main_tree:{},
        target_user:0,
        users_capital:[],
        casinoBrands:[],
        allCasinoBrands:[],
        loading_capital:false,
        show_hidden_tree:false,
        loadingReport:false,
        reportData:{casino:{},deportes:{},poker:{},bingo:{},fichas:{},paneles:{},totales:{casino:0,poker:0,deportes:0,bingo:0}},
        reportDataCargas:{},
        reportDeportes:[],
        reportCasino:[],
        reportPoker:[],
        showChangePassword:false,
        user:{
            token:"",
            id:0,
            name:"",
            balance:0,
            currency:"",
            site_id:0,
            site:"",
            prefix:"",
            providers:{}
        }
    },
    mutations:{
        setShowChangePassword(state,payload){
            state.showChangePassword = payload
          },
        setRenderStats(state,payload){
            state.renderStats = payload
        },
        logout(state){
            state.user = {
                token:"",
                id:0,
                name:"",
                balance:0,
                currency:"",
                site_id:0,
                site:"",
                prefix:"",
            }
            state.chartDataNetwinMonth = {casino:[],deportes:[]}
            state.chartDataNetwinDay = {casino:[],deportes:[]}
            state.chartDataTopAgentes = []
            state.chartDataNetwinTotal = {}
            state.target_user = 0
            state.arbolJerarquia = {}
            state.main_tree = {}
            state.users_capital = []
            localStorage.removeItem("loginToken")
            if(router.currentRoute.name!="login"){
                router.push({ name: 'login' })
            }
        },
        setchartDataNetwinMonth(state,payload){
            state.chartDataNetwinMonth = payload
        },
        setchartDataNetwinDay(state,payload){
            state.chartDataNetwinDay = payload
        },
        setchartDataNetwinTotal(state,payload){
            state.chartDataNetwinTotal = payload
        },
        setchartDataTopAgentes(state,payload){
            state.chartDataTopAgentes = payload
        },
        setReportDataCargas(state,payload){
            state.reportDataCargas = payload
        },
        setReportDeportes(state,payload){
            state.reportDeportes = payload
        },
        setReportCasino(state,payload){
            state.reportCasino = payload
        },
        setReportPoker(state,payload){
            state.reportPoker = payload
        },
        setMainTree(state,payload){
            state.main_tree = payload;
        },
        setLoadingReport(state,payload){
            state.loadingReport = payload;
        },
        setUsersCapital(state,payload){
            state.users_capital = payload
        },
        setDashboardView(state,payload){
            state.dashboard_view = payload
        },
        setToken(state,payload){
            state.user.token = payload.token;
            state.user.site_id = payload.site_id;
            state.user.site = payload.site;
            state.user.prefix = payload.prefix;
        },
        setBalance(state,payload){
            state.user.name = payload.data.username;
            state.user.id = payload.data.id;
            state.user.balance = payload.data.balance;
            state.user.currency = payload.data.currency;
            state.user.providers = payload.data.proveedores;
            state.user.secondaryRole = payload.data.secondaryRole || 'agente'
        },
        setLoadingCapital(state,payload){
            state.loading_capital = payload;
        },
        setTargetUser(state,payload){
            state.target_user = payload;
        },
        hideUser(state,payload){
            state.main_tree.hijos.forEach(children=>{
                if(children.id == payload){
                    children.show = false;
                }
            })
            state.users_capital.forEach(children=>{
                if(children.user == payload){
                    children.show = false;
                }
            })
            let hiddenIds = state.users_capital.filter(children=>!children.show).map(user=>user.user);
            localStorage.setItem("hiddenUsers",JSON.stringify(hiddenIds))
        },
        showUser(state,payload){
            state.main_tree.hijos.forEach(children=>{
                if(children.id == payload){
                    children.show = true;
                }
            })
            state.users_capital.forEach(children=>{
                if(children.user == payload){
                    children.show = true;
                }
            })
            let hiddenIds = state.users_capital.filter(children=>!children.show).map(user=>user.user);
            localStorage.setItem("hiddenUsers",JSON.stringify(hiddenIds))
        },
        showHiddenTree(state,payload){
            state.show_hidden_tree = payload
        },
        refreshReport(state){
            state.reportData = {casino:{},deportes:{},poker:{},bingo:{},fichas:{},paneles:{},totales:{casino:0,poker:0,deportes:0,bingo:0}}
        },
        setReportData(state,payload){
            state.reportData.casino = payload.casino;
            state.reportData.deportes = payload.main.filter(item=>item.tipo_apuesta.toLowerCase()=="deportes")[0]??{};
            state.reportData.poker = payload.main.filter(item=>item.tipo_apuesta.toLowerCase()=="poker")[0]??{};
            state.reportData.bingo = payload.main.filter(item=>item.tipo_apuesta.toLowerCase()=="bingo")[0]??{};
            state.reportData.fichas = payload.fichas;
            state.reportData.paneles = payload.paneles;
            state.reportData.totales.deportes = state.reportData.deportes.netwin||0 - state.reportData.deportes.comision||0;
            state.reportData.totales.casino = state.reportData.casino.netwin||0 - state.reportData.casino.comision||0;
            state.reportData.totales.poker = state.reportData.poker.netwin||0 - state.reportData.poker.comision||0;
            state.reportData.totales.bingo = state.reportData.bingo.netwin||0 - state.reportData.bingo.comision||0;
        },
        setCasinoBrands(state,payload){
            state.allCasinoBrands = payload;
            state.casinoBrands = payload.filter(brand => brand.status);
        },
        setArbolJerarquia(state,payload){
            state.arbolJerarquia = payload;
        },
        setCuentaCorrienteTarget(state, payload){
            state.cuentaCorrienteTarget = payload
        }
    },
    actions:{
        idleLogout(state){
            var t;
            window.onload = resetTimer;
            window.onmousemove = resetTimer;
            window.onmousedown = resetTimer;    // catches touchscreen presses as well
            window.ontouchstart = resetTimer;   // catches touchscreen swipes as well
            window.ontouchmove = resetTimer;    // required by some devices
            window.onclick = resetTimer;        // catches touchpad clicks as well
            window.onkeydown = resetTimer;
            window.addEventListener('scroll', resetTimer, true); // improved; see comments

            function logOut() {
                state.commit("logout");
            }

            function resetTimer() {
                clearTimeout(t);
                t = setTimeout(logOut, 600000*3);  // time is in milliseconds 30min
            }
        },
        setDashboardView(state,payload){
            state.commit("setDashboardView",payload);
        },
        showHiddenTree(state,payload){
            state.commit("showHiddenTree",payload);
        },
        async getMainTree(state,payload){
            state.commit("setLoadingCapital",true);
            let hiddenUsers = localStorage.getItem("hiddenUsers") ? JSON.parse(localStorage.getItem("hiddenUsers")) : [];
            try {
                let response = await axios.post(
                    process.env.VUE_APP_DATA_SERVER_URL+"/new_jerarquia",{
                        "user_id": payload.data.id,
                        "token": state.state.user.token
                    }
                )
                if(response.data.status=="success"){
                    response.data.data = response.data.data[0];
                    if (!payload.data.flag){
                        state.commit("setArbolJerarquia",response.data)
                    }
                    let main_tree = {}
                    main_tree = {
                        id:response.data.data.id,
                        user_name:response.data.data.name,
                        rol:response.data.data.roles.split('"')[1],
                        show:true,
                        enabled: response.data.data.enabled,
                        hijos:[]
                    }
                    let address = "hijos";
                    const getHijos = (obj,addr)=>{
                        try {
                            let role = obj.roles?.split('"')[1];
                            if(role!="ROLE_PROMOTOR"){
                                return false
                            }
                            else{
                                if(obj.id != response.data.data.id){
                                    let target = _get(main_tree,addr);
                                    target.sort((a,b)=>a.user_name.localeCompare(b.user_name));
                                    let idx = target.push({
                                        id:obj.id,
                                        user_name:obj.name,
                                        role:role,
                                        // capital: obj.capital,
                                        show:hiddenUsers.includes(obj.id)?false:true,
                                        enabled: obj.enabled,
                                        hijos:[]
                                    })-1;
                                    addr+="["+idx+"].hijos"
                                }
                                if (obj.hijos !== undefined) {
                                    obj.hijos.forEach(hijo=>{
                                        getHijos(hijo,addr);
                                    })
                                }
                            }
                        } catch (error) {
                            console.log(error,addr);
                        }  
                    }
                    getHijos(response.data.data,address);
                    main_tree.hijos.sort((a,b)=>a.user_name.localeCompare(b.user_name))
                    state.commit("setTargetUser",{id:payload.data.id,name:payload.data.username??payload.data.name});
                    // main_tree['total_users'] = response.data.users_total;
                    state.dispatch("getUsersCapital",response.data.data);
                    if (!payload.data.flag || payload.data.id === state.state.main_tree.id){
                        state.commit("setMainTree",main_tree);
                    }
                }
                state.commit("setLoadingCapital",false);
            } catch (error) {
                console.log(error);
                state.commit("logout");
                state.commit("setLoadingCapital",false);
            }
        },
        async setCuentaCorrienteTarget(state,payload){
            state.commit("setCuentaCorrienteTarget",payload);
        },
        async getChildrenCapital(state,payload){
            try {
                state.commit("setLoadingCapital",true);
                state.commit("setTargetUser",payload);
                if(!state.state.arbolJerarquia.status){
                    state.dispatch("getMainTree", {data:{id: payload.id, username: state.state.target_user.name, flag: false}})
                }
                if(state.state.arbolJerarquia.status=="success"){
                    state.dispatch("getMainTree", {data:{id: payload.id, username: state.state.target_user.name, flag: true}})
                }
                else{
                    state.commit("setUsersCapital",[])
                    state.commit("setLoadingCapital",false);
                }
            } catch (error) {
                console.log(error);
            }
        },
        async getChildrenCapital2(state,payload){
            try {
                state.commit("setLoadingCapital",true);
                state.commit("setTargetUser",payload);
                state.dispatch("getMainTree", {data:{id: payload.id, username: state.state.target_user.name, flag: true}})
            } catch (error) {
                console.log(error);
            }
        },
        async getChildrenTree(state, payload){
            state.commit("setLoadingCapital",true);
            state.commit("setTargetUser",payload);
            const mainTree = state.state.arbolJerarquia.data;
            let childrenTree = {};
            const getTree = (obj)=>{
                try {
                    let role = obj.roles?.split('"')[1];
                    if(role!="ROLE_PROMOTOR"){
                        return false
                    }
                    else{
                        if(obj.id === payload.id){
                            childrenTree = obj
                            return false
                        }
                        if (obj.hijos !== undefined) {
                            obj.hijos.forEach(hijo=>{
                                getTree(hijo);
                            })
                        }
                    }
                } catch (error) {
                    console.log(error);
                }  
            }
            getTree(mainTree);
            state.dispatch("getUsersCapital",childrenTree);
            state.commit("setLoadingCapital",false);
        },
        async getChildrenByName(state, payload){
            state.commit("setLoadingCapital",true);
            if(payload !== "") {
                let result = await axios.post(process.env.VUE_APP_DATA_SERVER_URL+"/buscar_capital", {
                    "username": payload,
                    "login_Id": state.state.main_tree.id,
                    "token": state.state.user.token
                })
                let children = result.data.map(user=>{
                    return {
                        user: user.user,
                        username: user.username,
                        role: user.roles.split('"')[1],
                        capital_total: user.capital_total,
                        enabled: user.enabled,
                        show: true
                    }
                })
                state.commit("setUsersCapital",children)
                state.commit("setLoadingCapital",false);
            }
            else {
                const mainTree = state.state.arbolJerarquia.data;
                state.dispatch("getUsersCapital",mainTree);
            }
        },
        async login(state,payload){
            try {
                if(!payload.token){
                    const response = await axios.get(
                    process.env.VUE_APP_MAIN_SERVER_URL+'/oauth/v2/token?username='+payload.username+'&password='+payload.password+'&client_id=1_5i50wo24kpcscc0okw0ww4gsc8kwg0k8gs0ok44skooww4swcg&client_secret=18qxs6584gw08scg8wsk8gow44oc4gcw40c4o8w44880g0gkcg&grant_type=password&source=pn');
                    let token = response.data.access_token;
                    let user_data = {
                        token:token,
                        site_id:process.env.VUE_APP_SITE_ID,
                        prefix:process.env.VUE_APP_PREFIX,
                        site:process.env.VUE_APP_SITE
                    }
                    localStorage.setItem("loginToken",token);
                    state.commit("setToken",user_data);
                    state.dispatch("getUserData",user_data);
                    state.commit("setDashboardView","users");
                    return "success";
                }
                else{
                    let user_data = {
                        token:payload.token,
                        site_id:process.env.VUE_APP_SITE_ID,
                        prefix:process.env.VUE_APP_PREFIX,
                        site:process.env.VUE_APP_SITE
                    }
                    localStorage.setItem("loginToken",payload.token);
                    state.commit("setToken",user_data);
                    state.dispatch("idleLogout");
                    state.dispatch("getUserData",user_data);
                    return "success";
                }
            } catch (error) {
            console.log(error);
                localStorage.removeItem("loginToken")
                return 'Usuario o clave invalidos! Intenta nuevamente.';
            }
        },
        async getAuditoria(state,payload){
            try {
                let data = await axios.post(process.env.VUE_APP_DATA_SERVER_URL+"/auditoria", {
                    "login_Id": payload.id
                })
                if(data.data[0].descripcion == "Agente cambio su clave"){
                  state.commit("setShowChangePassword",true);
                }
                return data.data
            } catch (error) {
                return []
            }
        },
        async getUserData(state,payload){
            try {
                state.commit("setLoadingCapital",true);
                const response2 = await axios.post(
                process.env.VUE_APP_DATA_SERVER_URL+'/balance',
                {"token":payload.token});
                if(response2.data.code){
                  state.commit("setToken",{
                    token:"",
                    site_id:payload.site_id,
                    site:payload.site,
                    prefix:payload.prefix
                  });
                  console.log("getUserData_error");
                  state.commit("logout");
                }
                else{
                    state.commit("setBalance",{data:response2.data});
                    state.dispatch("getAuditoria",response2.data);
                    state.commit("setCasinoBrands",response2.data.proveedores.casino)
                    if(!payload.origin){
                        // state.dispatch("getChartsData",response2.data.id) 
                        state.dispatch("getMainTree",{data:response2.data});
                    }
                }
            } catch (error) {
                localStorage.removeItem("loginToken")
                state.commit("setToken",{
                    token:"",
                    site_id:payload.site_id,
                    site:payload.site,
                    prefix:payload.prefix
                });
            }
        },
        async getUsersCapital(state,payload){
            try {
                let capitales = await axios.post(process.env.VUE_APP_DATA_SERVER_URL+"/capitales", {
                    "user_id": payload.id,
                    "token": state.state.user.token
                })
                let hiddenUsers = localStorage.getItem("hiddenUsers") ? JSON.parse(localStorage.getItem("hiddenUsers")) : [];
                let hijosArray = []
                if(payload.hijos){
                    payload.hijos.forEach( hijo => {
                        try {
                            if(hijo.roles.includes("ROLE_PROMOTOR")) {
                                const userCapital = capitales.data.find(user => user.user === hijo.id)
                                hijosArray.push({
                                    user:hijo.id,
                                    username:hijo.name,
                                    role:hijo.roles.split('"')[1],
                                    capital_total: userCapital.capital_total,
                                    enabled: hijo.enabled,
                                    secondaryRole: userCapital.secondaryRole,
                                    show: true
                                })
                            }
                        } catch (error) {
                            console.log(error)
                            console.log(hijo)
                        }
                    })
                }
                capitales.data.forEach(user=>{
                    if(user.role === "ROLE_APOSTADOR"){
                        hijosArray.push({
                            user:user.user,
                            username:user.username,
                            role:user.role,
                            capital_total: user.capital_total,
                            enabled: user.enabled,
                            show: true
                        })
                    }
                })
                let filtered = hijosArray.filter(user=>(user.role == "ROLE_PROMOTOR" || user.role == "ROLE_APOSTADOR")&&user.user!=state.state.target_user.id);
                filtered = filtered.map(function(user){
                    if(hiddenUsers.includes(user.user)){
                        user.show = false;
                    }
                    else{
                        user.show = true;
                    }
                    return user
                })
                state.commit("setUsersCapital",filtered)
                state.commit("setLoadingCapital",false);
            } catch (error) {
                console.log(error);
            }
        },
        async getReport(state,payload){
            try {
                state.commit("setTargetUser",payload.id)
                state.commit("setLoadingReport",true);
                state.commit("refreshReport");
                const timeZoneOffset = new Date().getTimezoneOffset() / 60;
                const timezoneFormated = ((Math.abs(timeZoneOffset)<9 ?((-1*(timeZoneOffset)) < 0 ? '-0'+timeZoneOffset: '0'+timeZoneOffset):(-1*(timeZoneOffset))))+":00";
                let report = await axios.post(process.env.VUE_APP_DATA_SERVER_URL + "/casino_reporte_provider",{
                    "startDate": payload.startDate+" "+payload.startHour,
                    "endDate": payload.endDate+" "+payload.endHour,
                    "login_Id": payload.id.id,
                    "timezone": timezoneFormated,
                    "dateType": payload.dateType || null
                })
                if(report.data){
                let casino = report.data.casinoYDeportes.filter(item=>item.tipo_apuesta=="Casino"&&!item.provider)[0];
                casino.providers= {};
                let mainProviders = report.data.casinoYDeportes.filter(item=>item.tipo_apuesta=="Casino"&&item.provider).map(item=>item.provider);
                mainProviders = mainProviders.filter((item,index) => mainProviders.indexOf(item) === index);
                mainProviders.forEach(provider=>{
                    let brands = report.data.casinoYDeportes.filter(item=>item.tipo_apuesta=="Casino"&&item.provider&&item.provider==provider);
                    let provObj = {
                        "bet_amount": brands.map(brand=>brand.bet_amount).reduce((a, b) => a + b, 0),
                        "total_bets": brands.map(brand=>brand.total_bets).reduce((a, b) => a + b, 0),
                        "win_amount": brands.map(brand=>brand.win_amount).reduce((a, b) => a + b, 0),
                        "total_win": brands.map(brand=>brand.total_win).reduce((a, b) => a + b, 0),
                        "netwin": brands.map(brand=>brand.netwin).reduce((a, b) => a + b, 0),
                        "comision": brands.map(brand=>brand.comision).reduce((a, b) => a + b, 0),
                        "brands":{}
                    }
                    brands.forEach(brand=>{
                        try {
                            provObj.brands[brand.casa] = brand
                        } catch (error) {
                            console.log(brand.provider.toUpperCase());
                        }
                        })
                    casino.providers[provider.toUpperCase()] = provObj;
                })
                let panelesData = {}
                Object.keys(report.data.fichas_paneles ?? {}).forEach(panel=>{
                    let role = panel.split('"')[1];
                    panelesData[role] = report.data.fichas_paneles[panel];
                })
                state.commit("setReportData",{
                    main:report.data.casinoYDeportes,
                    casino:casino,
                    fichas:report.data.movimientoFichas,
                    paneles:panelesData
                })
                }
                state.commit("setLoadingReport",false);
            } catch (error) {
                console.log(error);
                state.commit("logout");
            }
        },
        async getReportCargas(state,payload){
            try {
                state.commit("setTargetUser",payload.id)
                state.commit("setLoadingReport",true);
                const timeZoneOffset = new Date().getTimezoneOffset() / 60;
                const timezoneFormated = ((Math.abs(timeZoneOffset)<9 ?((-1*(timeZoneOffset)) < 0 ? '-0'+timeZoneOffset: '0'+timeZoneOffset):(-1*(timeZoneOffset))))+":00";
                let report = await axios.post(process.env.VUE_APP_DATA_SERVER_URL + "/movimiento_fichas",{
                    "login_Id": payload.id.id,
                    "startDate": payload.startDate+" "+payload.startHour,
                    "endDate": payload.endDate+" "+payload.endHour,
                    "timezone": timezoneFormated
                })
                if(report.data){
                    state.commit("setReportDataCargas",report.data)
                }
                state.commit("setLoadingReport",false);
            } catch (error) {
                console.log(error);
                state.commit("setLoadingReport",false);
            }
        },
        async getReportDeportes(state,payload){
            try {
                state.commit("setTargetUser",payload.id)
                state.commit("setLoadingReport",true);
                const timeZoneOffset = new Date().getTimezoneOffset() / 60;
                const timezoneFormated = ((Math.abs(timeZoneOffset)<9 ?((-1*(timeZoneOffset)) < 0 ? '-0'+timeZoneOffset: '0'+timeZoneOffset):(-1*(timeZoneOffset))))+":00";
                let bets = await axios.post(process.env.VUE_APP_DATA_SERVER_URL + "/apuestas",{
                    "users": [payload.id.id],
                    "startDate": payload.startDate,
                    "endDate": payload.endDate,
                    "timezone": timezoneFormated
                })
                if(bets.data){
                    state.commit("setReportDeportes",bets.data);
                }
                state.commit("setLoadingReport",false);
            } catch (error) {
                console.log(error);
                state.commit("setLoadingReport",false);
            }
        },
        async getReportCasino(state,payload){
            try {
                state.commit("setTargetUser",payload.id)
                state.commit("setLoadingReport",true);
                const timeZoneOffset = new Date().getTimezoneOffset() / 60;
                const timezoneFormated = ((Math.abs(timeZoneOffset)<9 ?((-1*(timeZoneOffset)) < 0 ? '-0'+timeZoneOffset: '0'+timeZoneOffset):(-1*(timeZoneOffset))))+":00";
                let bets = await axios.post(process.env.VUE_APP_DATA_SERVER_URL + "/casino_casa_jugador",{
                    "users": [payload.id.id],
                    "startDate": payload.startDate,
                    "endDate": payload.endDate,
                    "provider": payload.provider,
                    "timezone": timezoneFormated
                })
                if(bets.data){
                    state.commit("setReportCasino",bets.data);
                }
                state.commit("setLoadingReport",false);
            } catch (error) {
                console.log(error);
                state.commit("setLoadingReport",false);
            }
        },
        async getReportPoker(state,payload){
            try {
                state.commit("setTargetUser",payload.id)
                state.commit("setLoadingReport",true);
                const timeZoneOffset = new Date().getTimezoneOffset() / 60;
                const timezoneFormated = ((Math.abs(timeZoneOffset)<9 ?((-1*(timeZoneOffset)) < 0 ? '-0'+timeZoneOffset: '0'+timeZoneOffset):(-1*(timeZoneOffset))))+":00";
                let bets = await axios.post(process.env.VUE_APP_DATA_SERVER_URL + "/poker_jugador",{
                    "users": [payload.id.id],
                    "startDate": payload.startDate,
                    "endDate": payload.endDate,
                    "timezone": timezoneFormated
                })
                if(bets.data){
                    state.commit("setReportPoker",bets.data);
                }
                state.commit("setLoadingReport",false);
            } catch (error) {
                console.log(error);
                state.commit("setLoadingReport",false);
            }
        },
        async getChartsData(state,payload){
            try {
                let response = await axios.post(process.env.VUE_APP_DATA_SERVER_URL+"/resumen_mensual",{
                    "login_Id": payload
                })
                if(response.data){
                    let data = response.data.netwin_mensual.map(item=>{
                        return {
                            "month":state.state.months[item.month.split("-")[1]] + "-" + item.month.split("-")[0].slice(-2),
                            "netwin":item.netwin.toFixed(2),
                            "type":item.tipo_apuesta
                        }
                    });
                    state.commit("setchartDataNetwinMonth",{
                        "casino":data.filter(item=>item.type=="Casino"),
                        "deportes":data.filter(item=>item.type=="Deportes")
                    })
                    let data2 = response.data.netwin_diario.map(item=>{
                        return {
                            "day":item.day.split("-")[2],
                            "netwin":item.netwin.toFixed(2),
                            "type":item.tipo_apuesta
                        }
                    }
                    );
                    state.commit("setchartDataNetwinDay",{
                        "casino":data2.filter(item=>item.type=="Casino"),
                        "deportes":data2.filter(item=>item.type=="Deportes")
                    })
                    let data3 = response.data.top_agentes.map(item=>{
                        return {
                            username:item.username,
                            valor:Math.abs(item.total_valor)
                        }
                    })
                    state.commit("setchartDataTopAgentes",data3)
                    state.commit("setchartDataNetwinTotal",response.data.ganancia_neta)
                }
            } catch (error) {
                console.log(error);
            }
            state.commit("setRenderStats",true);
            state.commit("setDashboardView","stats");
        },
        logOut(state){
            state.commit("setRenderStats",false);
            state.commit("logout");
        },
        hideUser(state,payload){
            state.commit("hideUser",payload);
        },
        showUser(state,payload){
            state.commit("showUser",payload);
        }
    },
    getters:{
        getCuentaCorrienteTarget: state => state.cuentaCorrienteTarget,
        getShowChangePassword: state => state.showChangePassword,
        getDashboardView: state => state.dashboard_view,
        getUserData: state => state.user,
        getMainTree: state => state.main_tree,
        getUsersCapital: state => state.users_capital,
        getLoadingCapital: state => state.loading_capital,
        getTargetUser: state => state.target_user,
        getShowHiddenTree: state => state.show_hidden_tree,
        getReportData: state => state.reportData,
        getReportDataCargas: state => state.reportDataCargas,
        getLoadingReport: state => state.loadingReport,
        getReportDeportes: state => state.reportDeportes,
        getReportCasino: state => state.reportCasino,
        getReportPoker: state => state.reportPoker,
        getChartDataNetwinMonth: state=>state.chartDataNetwinMonth,
        getChartDataNetwinDay: state=>state.chartDataNetwinDay,
        getChartTopAgentes: state=>state.chartDataTopAgentes,
        getChartDataNetwinTotal: state=>state.chartDataNetwinTotal,
        getRenderStats: state=>state.renderStats,
        getCasinoBrands: state=>state.casinoBrands,
        getAllCasinoBrands: state=>state.allCasinoBrands
    }
})