<template>
    <div style="font-family: 'Rubik', sans-serif;">
        <div v-if="loading" class="freeze-ui" data-text=" " style="position: absolute;z-index:999999 !important"></div>
        <div class="kt-grid__item kt-grid__item--fluid kt-grid w-100"
            style="margin: 0px; padding: 0px; flex: 0 1 0%;">
            <div _ngcontent-lul-c1="" class="w-100 pl-0">
                <div _ngcontent-lul-c1="" class="ng-tns-c1-0"></div>
                <div class="ng-tns-c30-18 ng-star-inserted" style="">
                    <div class="kt-login__form ng-trigger ng-trigger-routerTransition"
                        style="padding-top: 0px; opacity: 1;">
                        <div class="kt-subheader kt-grid__item">
                            <div class="kt-subheader__main">
                                <h3 class="kt-subheader__title"><span class="ng-tns-c30-18" style="color:white">Agentes
                                    </span></h3><span
                                    class="kt-subheader__separator kt-subheader__separator--v"></span><span
                                    class="kt-subheader__desc"> Saldos en usuarios </span>
                            </div>
                        </div>
                    </div>
                </div><!---->
            </div>
            <!-- Reporte ---------------------------------------->
            <div class="row w-100 m-0 p-0">
                <div class="kt-content col-lg-9 col-sm-12 pr-0"
                    style="padding-right: unset !important;overflow:hidden;padding:0">
                    <div class="row resize" style="flex-direction: row;">
                        <div class="col-lg-12">
                            <div class="kt-portlet" style="opacity: 0.9;">
                                <div class="kt-portlet__head row">
                                    <div class="row align-itms-center" style="padding-top: 2%;">
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label class="text-white ml-2" for="ngSelectDirectOptions">Buscar
                                                    en</label>
                                                <div class="custom-w3-select">
                                                    <select id="ngSelectDirectOptions" class="form-select"
                                                        style="border-radius: 5px;" v-model="searchIn">
                                                        <option value="todos" selected="selected">Todos</option>
                                                        <option value="jugadores">Solo Jugadores</option>
                                                        <option value="agentes">Solo Agentes</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group"><label class="text-white ml-2"
                                                    for="ngSelectUsers">Usuario</label><input v-model="userName"
                                                    class="form-control m-input ng-pristine ng-valid ng-touched"
                                                    autofocus="" id="selectedUserId" maxlength="20" name="filterText"
                                                    type="text" placeholder="Buscar..."></div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label class="text-white ml-2"
                                                    for="ngSelectDirectOptions">Mostrar</label>
                                                <div class="custom-w3-select">
                                                    <select id="ngSelectDirectOptions" class="form-select"
                                                        style="border-radius: 5px;" v-model="show">
                                                        <option value="todos" selected="selected">Todos</option>
                                                        <option value="directos">Solo directos</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label class="text-white ml-2" for="ngSelectDirectOptions">Ordenar
                                                    por</label>
                                                <div class="custom-w3-select">
                                                    <select id="ngSelectDirectOptions" class="form-select"
                                                        style="border-radius: 5px;" v-model="orderBy">
                                                        <option value="desc" selected="selected">Con más saldo</option>
                                                        <option value="asc">Con menos saldo</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-n3 pt-1 col-12" style="padding:0 0.5rem;"><span class="ng-tns-c30-18"
                                            :class="showLess ? 'show-less' : ''"
                                            style="font-size: 11.7px !important;color: #FFC529;width:80% !important">
                                            La zona horaria local toma en consideración la fecha y hora que tiene
                                            configurada en su dispositivo. La zona horaria UTC 00:00 es un estandar
                                            de hora universal.
                                            Si selecciona la opción "Local" será tomado en cuenta el horario de su
                                            pais, y si selecciona la opción "UTC" será tomado en cuenta el horario
                                            estandar universal. </span><a class="ng-tns-c30-18" href="javascript:;"
                                            @click="showLess = !showLess"
                                            style="font-size: 11.7px !important;color: #FFC529 !important;text-decoration: underline; display:inline;float:right">
                                            {{ showLess ? 'Mostrar más' : 'Mostrar menos' }} </a><br
                                            class="ng-tns-c30-18">
                                    </div>
                                    <div class="col-md-4 col-sm-6 pl-0 mb-3"><button class="col-sm-12 btn btn-primary"
                                            @click="getReport" id="btn_search" type="button"><i class="la la-search"
                                                style="width: 20px;"></i> Buscar</button></div>
                                </div>
                                <div class="kt-portlet__body">
                                    <div class="row align-items-center">
                                        <div class="primeng-datatable-container"><div class="ng-tns-c28-17"
                                                scrollwidth="100%" rows="10">
                                                <div class="ui-table ui-widget ui-table-responsive">
                                                    <!----><!----><!----><!----><!----><!---->
                                                    <div class="ui-table-scrollable-wrapper ng-star-inserted"><!---->
                                                        <div class="ui-table-scrollable-view">
                                                            <div class="ui-table-scrollable-header ui-widget-header">
                                                                <div class="ui-table-scrollable-header-box"
                                                                    style="margin-right: 0px;">
                                                                    <table class="ui-table-scrollable-header-table">
                                                                        <!---->
                                                                        <thead class="ui-table-thead"><!---->
                                                                            <tr class="ng-tns-c28-17 ng-star-inserted">
                                                                                <th class="ui-sortable md-1"
                                                                                    style="text-align: center;"> Usuario
                                                                                </th>
                                                                                <th class="ui-sortable md-1"
                                                                                    style="text-align: center;"> Saldo
                                                                                </th>
                                                                                <th class="ui-sortable md-1"
                                                                                    style="text-align: center;"> Usuario
                                                                                    Padre </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody class="ui-table-tbody"><!----></tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div class="ui-table-scrollable-body">
                                                                <table class="ui-table-scrollable-body-table"><!---->
                                                                    <tbody class="ui-table-tbody">
                                                                        <!----><!----><!----><!---->
                                                                        <tr class="ng-tns-c28-17 ng-star-inserted" v-for="(user, index) in paginarArray(report, registers_per_page)[actual_page - 1]" :key="index"
                                                                            style="">
                                                                            <td class="ng-tns-c28-17"><span class="me-2" v-if="screenWidth<1000">Usuario:</span> {{ user.username }} </td>
                                                                            <td class="ng-tns-c28-17"><span class="me-2" v-if="screenWidth<1000">Saldo:</span> {{ parseNumbers(user.capital_total) }}
                                                                            </td>
                                                                            <td class="ng-tns-c28-17"><span class="me-2" v-if="screenWidth<1000">Padre:</span> {{ user.padre_username }} </td>
                                                                        </tr><!----><!---->
                                                                    </tbody>
                                                                </table><!----><!---->
                                                            </div><!---->
                                                        </div>
                                                    </div><!----><!----><!----><!----><!---->
                                                </div>
                                            </div><div class="ng-tns-c28-17">
                                                <div class="ui-table ui-widget"><!----><!----><!----><!----><!---->
                                                    <div class="ui-table-wrapper ng-star-inserted">
                                                        <table><!---->
                                                            <thead class="ui-table-thead"><!----></thead><!---->
                                                            <tfoot class="ui-table-tfoot ng-star-inserted">
                                                                <!----><!---->
                                                                <tr class="ng-tns-c23-7 ng-star-inserted ng-tns-c28-17"
                                                                    style="font-size: 11px !important;background: #80808038;color: white;">
                                                                    <td class="ng-tns-c23-7"><span
                                                                            class="ui-column-title">Balance Total</span>
                                                                    </td>
                                                                    <td class="ng-tns-c23-7">{{ parseNumbers(totalizar(report.map(item=>item.saldo))) }}</td>
                                                                    <td class="ng-tns-c23-7"></td>
                                                                </tr>
                                                            </tfoot>
                                                            <tbody class="ui-table-tbody">
                                                                <!----><!----><!----><!----><!----><!----><!----><!----><!---->
                                                            </tbody>
                                                        </table>
                                                    </div><!----><!----><!----><!----><!----><!---->
                                                </div>
                                            </div><!---->
                                            <div class="bottom row">
                                                <div class="col-12 col-md-6">

                                                </div>
                                                <div
                                                    class="dataTables_paginate paging_simple_numbers col-12 col-md-6 d-flex">
                                                    <div class="me-1 w-100">
                                                        <pagination :options="{ texts: { count: '' } }"
                                                            style="float: inline-end;" v-model="actual_page"
                                                            :records="records" :per-page="registers_per_page"
                                                            @paginate="paginate" />
                                                    </div>
                                                    <select id="registersPerPageSelect" name="users_length"
                                                        aria-controls="users" @change="setRegistersPerPage()"
                                                        class="form-select" style="max-width:60px;">
                                                        <option value="10">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ARBOL ---------------------------------------->
                <div class="col-lg-3 col-sm-12 pr-0 pl-0" v-if="screenWidth > 1000">
                    <div class="w-100">
                        <div id="treeNode" class="px-2">
                            <div class="kt-portlet"
                                style="opacity: 0.9;border: 1px solid #494141;margin-bottom: unset !important;">
                                <div class="kt-portlet__head" style="background-color: #15172B !important;">
                                    <div class="kt-portlet__head-label">
                                        <h3 class="kt-font-light kt-font-md kt-portlet__head-title text-uppercase">
                                            <span>Agentes</span>
                                        </h3>
                                    </div>
                                </div>
                                <div class="tree kt-content px-1">
                                    <treeNode :node="main_tree" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="offcanvas offcanvas-start p-0" tabindex="-1" id="offcanvasTree" aria-labelledby="offcanvasTreeLabel" style="max-width: 70%;background-color: #15172B !important;">
                    <div class="offcanvas-header p-2">
                        <h5 class="offcanvas-title" id="offcanvasTreeLabel" style="color: white;">Agentes</h5>
                        <button style="background-color: rgba(255, 255, 255, 0.2);color:white" class="close btn btn-sm px-2" type="button" data-bs-dismiss="offcanvas" aria-label="Close"><span aria-hidden="true">×</span></button>
                    </div>
                    <div class="offcanvas-body p-0">
                        <treeNode :node="main_tree" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import axios from 'axios'
import treeNode from './treeNode.vue';
import Pagination from 'vue-pagination-2';
export default {
    name: 'reporteSaldos',
    components: { treeNode, Pagination },
    data() {
        return {
            searchIn: "todos",
            userName: "",
            show: "todos",
            orderBy: "desc",
            showLess: true,
            showAddData: false,
            reportData: [],
            detalles: false,
            loading: false,
            actual_page: 1,
            registers_per_page: 10,
            pages: [],
        }
    },
    props: ["view_type","screenWidth"],
    computed: {
        main_tree() {
            return this.$store.getters["getMainTree"];
        },
        loadingReport() {
            return this.$store.getters["getLoadingReport"];
        },
        targetUser() {
            return this.$store.getters["getTargetUser"];
        },
        report() {
            let maindata = this.reportData;
            if (this.searchIn == 'jugadores') {
                maindata = maindata.filter(user => user.roles.includes('APOSTADOR'));
            }
            if (this.searchIn == 'agentes') {
                maindata = maindata.filter(user => user.roles.includes('Agente'));
            }
            if (this.userName !== '') {
                maindata = maindata.filter(user => user.username.toLowerCase().includes(this.userName.toLowerCase()));
            }
            if (this.orderBy === 'asc') {
                return maindata.sort((a, b) => a.capital_total - b.capital_total);
            }
            return maindata.sort((a, b) => b.capital_total - a.capital_total);
        }
    },
    methods: {
        paginarArray(array, elementosPorPagina) {
            const paginas = [];
            let paginaActual = [];

            for (let i = 0; i < array.length; i++) {
                if (paginaActual.length === elementosPorPagina) {
                    paginas.push(paginaActual);
                    paginaActual = [];
                }
                paginaActual.push(array[i]);
            }

            if (paginaActual.length > 0) {
                paginas.push(paginaActual);
            }
            this.records = array.length;
            this.pages = paginas;
            return paginas;
        },
        setRegistersPerPage() {
            const pages = parseInt(document.getElementById('registersPerPageSelect').value)
            this.registers_per_page = pages;
        },
        changePage(page) {
            this.actual_page = page
        },
        paginate(page) {
            this.actual_page = page;
        },
        openModal(target) {
            try {
                this.backdrop(true);
                let modal = document.getElementById(target);
                modal.classList.add("show");
                modal.style.display = "block";
            } catch (error) {
                console.log(error);
            }
        },
        backdrop(action) {
            if (action) {
                document.getElementById("modalBackdrop").style.display = "block";
                document.getElementById("modalBackdrop").classList.add("show");
            }
            else {
                document.getElementById("modalBackdrop").style.display = "none";
                document.getElementById("modalBackdrop").classList.remove("show");
            }
        },
        closeModal() {
            let modals = document.getElementsByClassName("modal");
            [...modals].forEach(modal => {
                modal.classList.remove("show");
                modal.style.display = "none";
            })
            document.getElementById("modalBackdrop").style.display = "none";
            document.getElementById("modalBackdrop").classList.remove("show");
        },
        totalizar(numbers) {
            return numbers.reduce((a, b) => (a ?? 0) + (b ?? 0), 0);
        },
        parseNumbers(number) {
            return new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number)
        },
        showHiddenTree() {
            let checked = document.getElementById("TreeShowHidden").checked;
            this.$store.dispatch("showHiddenTree", checked);
        },
        async getReport() {
            try {
                this.loading = true;
                const timeZoneOffset = new Date().getTimezoneOffset() / 60;
                const timezoneFormated = ((Math.abs(timeZoneOffset) < 9 ? ((-1 * (timeZoneOffset)) < 0 ? '-0' + timeZoneOffset : '0' + timeZoneOffset) : (-1 * (timeZoneOffset)))) + ":00";
                let response = await axios.post(process.env.VUE_APP_DATA_SERVER_URL + "/saldos_usuarios", {
                    "login_Id": this.main_tree.id,
                }
                )
                if (response.data) {
                    this.reportData = response.data;
                }
                this.loading = false;
            } catch (error) {
                console.log(error);
                this.reportData = {}
                this.loading = false;
            }
        }
    },
    watch: {
        targetUser(newVal) {
            if (newVal.id && !this.firstLoadReport) {
                this.getReport()
                this.firstLoadReport = true
            }
        },
        userName(){
            this.actual_page = 1
        }
    },
    mounted() {

    },
}
</script>
<style scoped>

.ui-table .ui-table-thead>tr>th {
    color: white !important;
    font-family: 'Times New Roman', Times, serif;
}
</style>