/* eslint-disable */
<template>
    <div style="font-family: 'Rubik', sans-serif;">
        <div v-if="loadingReport" class="freeze-ui" data-text=" " style="position: absolute;z-index:999999 !important"></div>
        <div class="kt-grid__item kt-grid__item--fluid kt-grid w-100"
            style="margin: 0px; padding: 0px; flex: 0 1 0%;">
            <div _ngcontent-lul-c1="" class="w-100 pl-0">
                <div _ngcontent-lul-c1="" class="ng-tns-c1-0"></div>
                <div class="ng-tns-c30-18 ng-star-inserted" style="">
                    <div class="kt-login__form ng-trigger ng-trigger-routerTransition"
                        style="padding-top: 0px; opacity: 1;">
                        <div class="kt-subheader kt-grid__item">
                            <div class="kt-subheader__main">
                                <h3 class="kt-subheader__title"><span class="ng-tns-c30-18" style="color:white">Agentes
                                    </span></h3><span
                                    class="kt-subheader__separator kt-subheader__separator--v"></span>
                                    <span class="kt-subheader__desc" v-if="cuentaCorrienteTarget === main_tree.user_name"> Mi Cuenta Corriente</span>
                                    <span class="kt-subheader__desc" v-else> Detalles de la Cuenta -  {{ cuentaCorrienteTarget }}</span>
                            </div>
                        </div>
                    </div>
                </div><!---->
            </div>
            <!-- Reporte ---------------------------------------->
            <div class="row w-100 m-0 p-0">
                <div class="kt-content col-lg-12 col-sm-12 pr-0"
                    style="padding-right: unset !important;overflow:hidden;padding:0">
                    <div class="row resize" style="flex-direction: row;">
                        <div class="col-lg-12">
                            <div class="kt-portlet" style="opacity: 0.9;">
                                <div class="kt-portlet__head row">
                                    <div class="row align-itms-center pl-0 col-sm-12 mb-4 mt-4">
                                        <div class="col-lg-3 col-md-3 col-sm-12">
                                            <div class="form-group"><label class="text-white" for="DateFrom">Fecha
                                                    Desde</label>
                                                <div class="" style="margin-left:0">
                                                    <div class="px-1">
                                                        <DatePicker v-model="startDate" :language="es"
                                                            :format="'dd/MM/yyyy'"></DatePicker>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-12">
                                            <div class="form-group"><label class="text-white" for="DateTo">Fecha
                                                    Hasta</label>
                                                <div class="" style="margin-left:0">
                                                    <div class="px-1">
                                                        <DatePicker v-model="endDate" :language="es"
                                                            :format="'dd/MM/yyyy'">
                                                        </DatePicker>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-12">
                                            <div class="form-group">
                                                <label class="text-white ml-2"
                                                    for="ngSelectDirectOptions">Moneda</label>
                                                <div class="custom-w3-select">
                                                    <select id="ngSelectDirectOptions" class="form-select"
                                                        v-model="selectedTarget" style="border-radius: 5px;">
                                                        <option value="Todos">Todas</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-2 col-sm-12" style="color:#FFC529; font-size: medium;">
                                            <div class="ng-tns-c28-36">
                                                <table class="balance-table" style="width:100%">
                                                    <tr class="ng-tns-c28-36">
                                                        <td class="header">Saldo en USD :</td>
                                                        <td class="data">0,00</td>
                                                    </tr>
                                                    <tr class="ng-tns-c28-36">
                                                        <td class="header">Saldo :</td>
                                                        <td class="data">0,00</td>
                                                    </tr>
                                                    <tr class="ng-tns-c28-36">
                                                        <td class="header">Memoria :</td>
                                                        <td class="data">0,00</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="kt-portlet__body">
                                    <div class="row align-items-center ng-tns-c28-16 ng-star-inserted" style="">
                                        <div class="primeng-datatable-container">
                                            <div class="ng-tns-c29-22" scrollwidth="100%" rows="10">
                                                <div class="ui-table ui-widget ui-table-responsive">
                                                    <!----><!----><!----><!----><!----><!---->
                                                    <div class="ui-table-scrollable-wrapper ng-star-inserted"><!---->
                                                        <div class="ui-table-scrollable-view">
                                                            <div class="ui-table-scrollable-header ui-widget-header">
                                                                <div class="ui-table-scrollable-header-box"
                                                                    style="margin-right: 0px;">
                                                                    <div class="row p-1">
                                                                        <div class="col-10 mb-4"><span @click="setDateRanges('Hoy')"
                                                                            :class="selectedDate === 'Hoy' ? 'group-active' : ''"
                                                                            class="col-sm-3 d-inline btn btn-bold btn-font-sm btn-label-disabled"
                                                                            id="tgToday"> Hoy </span><span @click="setDateRanges('Ayer')"
                                                                            :class="selectedDate === 'Ayer' ? 'group-active' : ''"
                                                                            class="col-sm-3 d-inline  btn btn-bold btn-font-sm btn-label-disabled"
                                                                            id="tgYesterday"> Ayer </span><span
                                                                            @click="setDateRanges('Semana previa')"
                                                                            :class="selectedDate === 'Semana previa' ? 'group-active' : ''"
                                                                            class="col-sm-3 d-inline  btn btn-bold btn-font-sm btn-label-disabled"
                                                                            id="tgLastWeek"> Última Semana </span><span
                                                                            @click="setDateRanges('Mes previo')"
                                                                            :class="selectedDate === 'Mes previo' ? 'group-active' : ''"
                                                                            class="col-sm-3 d-inline  btn btn-bold btn-font-sm btn-label-disabled"
                                                                            id="tgLastMonth"> Mes Anterior </span>
                                                                        </div>
                                                                        <div class="col-md-2 col-sm-6 pl-0 mb-3"><button class="col-sm-12 btn btn-primary"
                                                                            @click="getReport" id="btn_search" type="button"><i class="la la-search"
                                                                                style="width: 20px;"></i> Buscar</button>
                                                                        </div>
                                                                    </div>
                                                                    <table class="ui-table-scrollable-header-table">
                                                                        <!---->
                                                                        <thead class="ui-table-thead"><!---->
                                                                            <tr class="ng-tns-c29-22 ng-star-inserted">
                                                                                <th class="ui-sortable md-1" id=""
                                                                                    style="text-align: center;font-size: 14px !important;">FECHA</th>
                                                                                <th class="ui-sortable md-1" id="thDate"
                                                                                    style="text-align: center;font-size: 14px !important;"> TIPO DE TRANSACCION
                                                                                </th>
                                                                                <th class="ui-sortable md-1" id="thUser"
                                                                                    style="text-align: center;font-size: 14px !important;"> MONEDA
                                                                                </th>
                                                                                <th class="ui-sortable" id="thGame"
                                                                                    style="text-align: center;font-size: 14px !important;"> MONTO
                                                                                </th>
                                                                                <th class="ui-sortable"
                                                                                    style="text-align: center;font-size: 14px !important;"> SALDO ACUMULADO
                                                                                </th>
                                                                                <th class="ui-sortable"
                                                                                    style="text-align: center;font-size: 14px !important;"> COMENTARIO
                                                                                </th>
                                                                                <th class="ui-sortable"
                                                                                    style="text-align: center;font-size: 14px !important;"> ACCION
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody class="ui-table-tbody"><!---->
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div class="ui-table-scrollable-body">
                                                                <table class="ui-table-scrollable-body-table"><!---->
                                                                    <tbody class="ui-table-tbody">
                                                                        <tr class="ng-tns-c24-5 ng-star-inserted" style="" v-for="(register, index) in paginarArray(reportData??[], registers_per_page)[actual_page - 1]" :key="index">
                                                                            <td class="ng-tns-c24-5">
                                                                            <span class=""><span class="ui-column-title">Fecha</span>{{ formatearFecha(register.fecha) }}</span></td>
                                                                            <td class="ng-tns-c24-5">
                                                                                <span class=""><span class="ui-column-title">Tipo Transacción</span>{{ register.operacion }}</span>
                                                                            </td>
                                                                            <td class="ng-tns-c24-5">
                                                                                <span class=""><span class="ui-column-title">Moneda</span>ARS</span>
                                                                            </td>
                                                                            <td class="ng-tns-c24-5">
                                                                                <span class=""><span class="ui-column-title">Monto</span>{{ parseNumbers(register.importe) }}</span>
                                                                            </td>
                                                                            <td class="ng-tns-c24-5" style="text-align: right;">
                                                                                <span class=""><span class="ui-column-title">Saldo Acumulado</span>{{ register.type !== 'WIN' ? parseNumbers(register.amount) : '0,00'  }}</span>
                                                                            </td>
                                                                            <td class="ng-tns-c24-5" style="text-align: right;">
                                                                                <span class=""><span class="ui-column-title">Comentario</span></span>
                                                                            </td><td class="ng-tns-c24-5" style="text-align: right;">
                                                                                <span class=""><span class="ui-column-title">Acción</span></span>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table><!----><!---->
                                                            </div><!---->
                                                        </div>
                                                    </div><!----><!----><!----><!----><!---->
                                                </div>
                                            </div>
                                            <div class="primeng-no-data ng-tns-c28-36 ng-star-inserted" style=""> No hay data </div>
                                            <div class="bottom row">
                                                <div class="col-12 col-md-6">

                                                </div>
                                                <div
                                                    class="dataTables_paginate paging_simple_numbers col-12 col-md-6 d-flex">
                                                    <div class="me-1 w-100">
                                                        <pagination :options="{ texts: { count: '' } }"
                                                            style="float: inline-end;" v-model="actual_page"
                                                            :records="records" :per-page="registers_per_page"
                                                            @paginate="paginate" />
                                                    </div>
                                                    <select id="registersPerPageSelect" name="users_length"
                                                        aria-controls="users" @change="setRegistersPerPage()"
                                                        class="form-select" style="max-width:60px;">
                                                        <option value="10">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import Pagination from 'vue-pagination-2';
import DatePicker from '../datePicker/components/Datepicker'
import { es } from '@sum.cumo/vue-datepicker/dist/locale/index.esm'
export default {
    name: 'miCuentaCorriente',
    components: { DatePicker, Pagination },
    data() {
        return {
            reportData:[],
            selectedTarget: "Todos",
            idApuesta: "",
            betData:{},
            pendings: false,
            actual_page: 1,
            registers_per_page: 10,
            startDate: "",
            startHour: "",
            selectedDate: "Hoy",
            endDate: "",
            endHour: "",
            username: "",
            refId: "",
            pages: [],
            records: 0,
            dateType: 'local',
            es: es,
            showLess: true,
            loading: false
        }
    },
    props: ["view_type","screenWidth"],
    computed: {
        cuentaCorrienteTarget(){
            return this.$store.getters["getCuentaCorrienteTarget"]
        },
        main_tree() {
            return this.$store.getters["getMainTree"];
        },
        targetUser() {
            return this.$store.getters["getTargetUser"];
        },
        loadingReport() {
            return this.$store.getters["getLoadingReport"];
        },
    },
    methods: {
        totalizar(numbers) {
            return numbers.reduce((a, b) => (a ?? 0) + (b ?? 0), 0);
        },
        setTargetMovement(item) {
            this.targetMovement = item
            this.openModal('balancesModal')
        },
        paginate(page) {
            this.actual_page = page;
        },
        parseRole(role) {
            if (role) {
                let role_name = role.split('"')[1].split("_")[1];
                if (role_name == "PROMOTOR") {
                    return "Agente";
                }
                else if (role_name == "APOSTADOR") {
                    return "Jugador";
                }
                else {
                    return role_name
                }
            }
        },
        openModal(target) {
            try {
                this.backdrop(true);
                let modal = document.getElementById(target);
                modal.classList.add("show");
                modal.style.display = "block";
            } catch (error) {
                console.log(error);
            }
        },
        backdrop(action) {
            if (action) {
                document.getElementById("modalBackdrop").style.display = "block";
                document.getElementById("modalBackdrop").classList.add("show");
            }
            else {
                document.getElementById("modalBackdrop").style.display = "none";
                document.getElementById("modalBackdrop").classList.remove("show");
            }
        },
        closeModal() {
            let modals = document.getElementsByClassName("modal");
            [...modals].forEach(modal => {
                modal.classList.remove("show");
                modal.style.display = "none";
            })
            this.targetMovement = {}
            document.getElementById("modalBackdrop").style.display = "none";
            document.getElementById("modalBackdrop").classList.remove("show");
        },
        parseNumbers(number) {
            return new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number)
        },
        showHiddenTree() {
            let checked = document.getElementById("TreeShowHidden").checked;
            this.$store.dispatch("showHiddenTree", checked);
        },
        formatearFecha(fechaString) {
            const fecha = new Date(fechaString);
            const anio = fecha.getFullYear();
            const mes = this.agregarCeroDelante(fecha.getMonth() + 1);
            const dia = this.agregarCeroDelante(fecha.getDate());
            const Hora = this.agregarCeroDelante(fecha.getHours());
            const Minutos = this.agregarCeroDelante(fecha.getMinutes());
            const Segundos = this.agregarCeroDelante(fecha.getSeconds());
            return `${anio}/${mes}/${dia} ${Hora}:${Minutos}:${Segundos}`;
        },
        agregarCeroDelante(numero) {
            return numero < 10 ? '0' + numero : numero;
        },
        getFechaFormated(fecha) {
            fecha = new Date(fecha);
            var año = fecha.getFullYear();
            var mes = ('0' + (fecha.getMonth() + 1)).slice(-2);
            var dia = ('0' + fecha.getDate()).slice(-2);
            var cadenaFecha = año + '-' + mes + '-' + dia;
            return cadenaFecha
        },
        getFecha(date) {
            return date
        },
        setDateRanges(range) {
            let hoy = new Date()
            this.selectedDate = range;
            if (range == "Hoy") {
                const tomorrow = new Date(hoy);
                tomorrow.setDate(tomorrow.getDate() + 1);
                this.startDate = this.getFecha(hoy);
                this.startHour = "00:00:00"
                this.endDate = this.getFecha(tomorrow);
                this.endHour = "00:00:00"
            }
            else if (range == "Ayer") {
                let ayer = new Date(hoy);
                ayer.setDate(ayer.getDate() - 1);
                this.startDate = this.getFecha(ayer);
                this.endDate = this.getFecha(hoy);
                this.startHour = "00:00:00"
                this.endHour = "00:00:00"
            }
            else if (range == "Semana previa") {
                const diaDeLaSemana = hoy.getDay();
                const diasParaRetroceder = diaDeLaSemana === 0 ? 6 : diaDeLaSemana - 1;
                const lunesSemanaAnterior = new Date(hoy);
                lunesSemanaAnterior.setDate(hoy.getDate() - (diasParaRetroceder + 7));
                const fechasSemanaPrevia = [];
                for (let i = 0; i < 8; i++) {
                    const fecha = new Date(lunesSemanaAnterior);
                    fecha.setDate(lunesSemanaAnterior.getDate() + i);
                    fechasSemanaPrevia.push(fecha);
                }
                this.startDate = this.getFecha(fechasSemanaPrevia[0]);
                this.endDate = this.getFecha(fechasSemanaPrevia[7]);
                this.startHour = "00:00:00"
                this.endHour = "00:00:00"
            }
            else if (range == "Semana actual") {
                const primerDiaSemana = new Date(hoy);
                primerDiaSemana.setDate(hoy.getDate() - (hoy.getDay() === 0 ? 7 : hoy.getDay()));
                // Paso 3: Obtener el rango de fechas de la semana
                const rangoSemana = [];
                for (let i = 0; i < 9; i++) {
                    const fecha = new Date(primerDiaSemana);
                    fecha.setDate(primerDiaSemana.getDate() + i);
                    rangoSemana.push(fecha);
                }
                this.startDate = this.getFecha(rangoSemana[1]);
                this.endDate = this.getFecha(rangoSemana[8]);
                this.startHour = "00:00:00"
                this.endHour = "00:00:00"
            }
            else if (range == "Mes actual") {
                const primerDiaMes = new Date(hoy.getFullYear(), hoy.getMonth(), 1);
                const tomorrow = new Date(hoy);
                tomorrow.setDate(tomorrow.getDate() + 1);
                this.startDate = this.getFecha(primerDiaMes);
                this.endDate = this.getFecha(tomorrow);
                this.startHour = "00:00:00"
                this.endHour = "00:00:00"
            }
            else if (range == "Mes previo") {
                const primerDiaMesAnterior = new Date(hoy);
                const primerdiaMesActual = new Date(hoy);
                primerDiaMesAnterior.setMonth(hoy.getMonth() - 1);
                primerDiaMesAnterior.setDate(1);
                primerdiaMesActual.setDate(1);
                this.startDate = this.getFecha(primerDiaMesAnterior);
                this.endDate = this.getFecha(primerdiaMesActual);
                this.startHour = "00:00:00"
                this.endHour = "00:00:00"
            }
            // this.getReport();
        },
        async getReport() {
            try {
                this.showOperaciones = false;
                this.showSelectAgentes = false;
                const timeZoneOffset = new Date().getTimezoneOffset() / 60;
                const timezoneFormated = ((Math.abs(timeZoneOffset)<9 ?((-1*(timeZoneOffset)) < 0 ? '-0'+timeZoneOffset: '0'+timeZoneOffset):(-1*(timeZoneOffset))))+":00";
                let response = await axios.post(process.env.VUE_APP_DATA_SERVER_URL + "/cuentascorrientes", {
                    "login_Id": this.main_tree.id,
                    "startDate": this.getFechaFormated(this.startDate) + " " + this.startHour,
                    "endDate": this.getFechaFormated(this.endDate) + " " + this.endHour,
                    "timezone": timezoneFormated
                })
                if (response.data) {
                    this.reportData = response.data.filter(register=>register.username==this.cuentaCorrienteTarget).reverse()
                }
            } catch (error) {
                console.log(error);
            }
        },
        paginarArray(array, elementosPorPagina) {
            const paginas = [];
            let paginaActual = [];

            for (let i = 0; i < array.length; i++) {
                if (paginaActual.length === elementosPorPagina) {
                    paginas.push(paginaActual);
                    paginaActual = [];
                }
                paginaActual.push(array[i]);
            }

            if (paginaActual.length > 0) {
                paginas.push(paginaActual);
            }
            this.records = array.length;
            this.pages = paginas;
            return paginas;
        },
        showMaxRegisterSelection() {
            document.getElementById("selectPage").style.display = "block";
        },
        setRegistersPerPage() {
            const pages = parseInt(document.getElementById('registersPerPageSelect').value)
            this.registers_per_page = pages;
        },
        changePage(page) {
            this.actual_page = page
        }
    },
    watch: {
        targetUser(newVal) {
            if (newVal.id && !this.firstLoadReport) {
                this.getReport()
                this.firstLoadReport = true
            }
        }
    },
    mounted() {
        if (this.selectedDate == "Hoy") {
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            this.startDate = this.getFecha(new Date());
            this.startHour = "00:00:00"
            this.endDate = this.getFecha(tomorrow);
            this.endHour = "00:00:00"
        }
    },
}
</script>
<style scoped>

.ui-table .ui-table-thead>tr>th {
    color: #dce7f9;
    background-color: #24284b;
}
</style>